import { Button, Col } from 'react-bootstrap'
import { ADD_NEW_TYPE } from '../../constants/editType'
import { EDIT_SCHOOL_COURSE, SHOW_ADD_SCHOOL_COURSE } from '../../store/types'
import SchoolCourseList from './SchoolCourseList'

const AddProgrom = ({ school, dispatch }) => {
    return (
        <Col>
            <h3>
                <strong>Programs</strong>
            </h3>
            <Col>
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => {
                        dispatch({
                            type: SHOW_ADD_SCHOOL_COURSE,
                            payload: true,
                        })
                        dispatch({
                            type: EDIT_SCHOOL_COURSE,
                            payload: {
                                schoolCourse: null,
                                editType: ADD_NEW_TYPE,
                            },
                        })
                    }}
                >
                    Add New Program
                </Button>
            </Col>
            <Col className="my-3">
                {school?.schoolcourses &&
                    school?.schoolcourses?.map((schoolCourse) => (
                        <SchoolCourseList
                            key={schoolCourse.id}
                            schoolCourse={schoolCourse}
                            schoolId={school.id}
                        />
                    ))}
            </Col>
        </Col>
    )
}

export default AddProgrom
