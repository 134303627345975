import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FormContainer from '../components/FormContainer'
import { confirmEmail } from '../store/actions/userActions'

function VerifyEmail({ match }) {
    const dispatch = useDispatch()
    const token = match.params.token
    useEffect(() => {
        dispatch(confirmEmail(token))
    }, [dispatch, token])

    return (
        <FormContainer>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <h3>We are verify your email...</h3>
            </div>
        </FormContainer>
    )
}

export default VerifyEmail
