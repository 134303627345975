export const errorsReturn = (e, dispatch, SHOW_LOADING, getErrors, logout) => {
    const errors = {
        msg: e?.response?.data?.msg,
        status: e?.response?.data?.code,
    }
    dispatch({
        type: SHOW_LOADING,
        payload: false,
    })
    dispatch(getErrors(errors))
    if (errors?.msg === 'JWT Failed') {
        dispatch(logout())
    }
}
