import {
    CHANGE_ASSOCIATE_PAGE,
    GET_ALL_ASSOCIATES,
    GET_ONE_ASSOCIATE,
    SEARCH_ASSOCIATE,
} from '../types'

const initialState = {
    associates: null,
    associate: null,
    keyword: '',
    pages: null,
    page: null,
    count: 0,
}

const associateReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ASSOCIATES:
            return {
                ...state,
                associates: payload.associates,
                keyword: payload.keyword,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case GET_ONE_ASSOCIATE:
            return {
                ...state,
                associate: payload,
            }
        case SEARCH_ASSOCIATE:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_ASSOCIATE_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default associateReducer
