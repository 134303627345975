import request from '../../config/request'
import {
    USER_LOGOIN_SUCCESS,
    USER_LOGOUT,
    SHOW_USER_SIGN,
    RENEW_USER_TOKEN,
    GET_ALL_USERS,
    GET_ONE_USER,
} from '../types'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import { createMessage } from './messageActions'
import { SHOW_LOADING } from '../types'
import history from '../../history'
import { newToken } from '../../admin/newtoken'

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const { data } = await request.post(
            'auth/login',
            { email, password },
            config
        )
        localStorage.setItem('qiecUserInfo', JSON.stringify(data.data.user))
        localStorage.setItem('qiecUserToken', JSON.stringify(data.data.token))
        // dispatch(
        //     createMessage({
        //         loginOK: 'Login successful',
        //     })
        // )
        dispatch({
            type: USER_LOGOIN_SUCCESS,
            payload: {
                userInfo: data.data.user,
                userToken: data.data.token,
            },
        })

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const register = (registerData) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const res = await request.post('/user', registerData, config)
        if (res.data.code === 201) {
            dispatch(
                createMessage({
                    registerOK: 'Register successful, please verify your email',
                })
            )
            history.push('/login')
            // window.location.href = '/login'
        } else {
            const errors = {
                msg: res.data.msg,
                status: 401,
            }
            getErrors(errors)
        }
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (error) {
        errorsReturn(error, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('qiecUserInfo')
    localStorage.removeItem('qiecUserToken')

    dispatch({ type: USER_LOGOUT })

    // dispatch({ type: USER_DETAILS_RESET })
    // dispatch({ type: ORDER_LIST_MY_RESET })
    // dispatch({ type: USER_LIST_RESET })
}

export const showUserSign = (value) => (dispatch) => {
    // console.log(value)
    dispatch({
        type: SHOW_USER_SIGN,
        payload: value,
    })
}

export const userUpdateProfile = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        // const uToken = JSON.parse(localStorage.getItem('userToken'))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`user/profileupdate/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        localStorage.setItem('qiecUserInfo', JSON.stringify(res.data.data))
        dispatch({
            type: USER_LOGOIN_SUCCESS,
            payload: {
                userInfo: res.data.data,
                userToken: res.headers.authorization,
            },
        })
        dispatch({
            type: SHOW_USER_SIGN,
            payload: false,
        })
        dispatch(
            createMessage({ updateConfig: 'User Profile updated successful' })
        )
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const renewUserToken = (values) => (dispatch) => {
    const newTokenValue = values.headers.authorization
    dispatch({
        type: RENEW_USER_TOKEN,
        payload: newTokenValue,
    })
}

export const getAllUsers =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `user/?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_USERS,
                payload: {
                    users: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const getOneUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`user/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_USER,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const resendEmail = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`user/resendemail/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(createMessage({ emailSentOk: 'Email sent' }))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateOneUser = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`user/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllUsers())
        history.push('/users')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteOneUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`user/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllUsers())
        history.push('/users')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const confirmEmail = (token) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })

        await request.get(`user/confirmation/${token}`)
        dispatch(
            createMessage({ verifyOk: 'Email verified, waiting for active' })
        )
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
