import { GET_ALL_STUDENTS, GET_ONE_STUDENT, SHOW_LOADING } from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import history from '../../history'
import { controlLoading } from './loadingActions'

export const getAllStudents =
    (searchStudent = '', searchUser = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `student/listall?searchUser=${searchUser}&searchStudent=${searchStudent}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_STUDENTS,
                payload: {
                    students: res.data.data.students,
                    users: res.data.data.users,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    searchUser,
                    searchStudent,
                },
            })
            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const getOneStudent = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`student/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: GET_ONE_STUDENT,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createStudent = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`student/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllStudents())
        history.push(`/studentupload/${res.data.data.id}`)
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateStudent = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`student/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({ type: GET_ONE_STUDENT, payload: null })
        dispatch(getAllStudents())
        history.push('/students')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteStudent = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`student/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllStudents())
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createStudentUpload = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`student/createupload`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneStudent(values.studentId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteStudentUpload =
    (id, studentId, applyId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.delete(`student/deleteupload/${id}`, {
                headers: { Authorization: `Bearer ${userToken}` },
                params: { applyId },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneStudent(studentId))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const updateAgent = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
            student: { searchStudent, searchUser, page },
        } = getState()
        let res = await request.put(
            `student/changeagent/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllStudents(searchStudent, searchUser, page))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateStudentEditableStatus =
    (values) => async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.put(
                `student/updateeditable/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
            dispatch(getAllStudents())
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }
