import { Button, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'

import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
// import { renderInput } from '../inputForm'
import { createApply, updateApply } from '../../store/actions/applyActions'
// import { INPUT_STARTCOURSEDATE } from '../../constants/studentInput'
import { getErrors } from '../../store/actions/errorActions'
// import moment from 'moment'

const FormApply = (props) => {
    const dispatch = useDispatch()

    const { handleSubmit, editType } = props
    const applyState = useSelector((state) => state.apply)
    const { selectedStudent, semester } = applyState

    const submitForm = (formValue) => {
        if (selectedStudent) {
            formValue['studentId'] = selectedStudent.id
            if (editType === EDIT_TYPE) {
                dispatch(updateApply(formValue))
            } else if (editType === ADD_NEW_TYPE) {
                formValue['schoolcoursesemesterId'] = semester.id
                formValue['schoolcourseId'] = semester.schoolcourse.id
                formValue['amount'] = semester.schoolcourse.applyFee
                formValue['courseStartDate'] = semester.semester
                dispatch(createApply(formValue))
            }
        } else {
            dispatch(getErrors({ msg: 'NEED Selected Sutdent', state: 404 }))
            return false
        }
    }

    return (
        <Form className="form-signin" onSubmit={handleSubmit(submitForm)}>
            {/* <Col xs={6} lg={3}>
                <Field
                    name="courseStartDate"
                    type="date"
                    label={INPUT_STARTCOURSEDATE}
                    max="20"
                    component={renderInput}
                ></Field>
            </Col> */}

            <div className="py-2">
                <Button type="submit" variant="info" className="btn-block mr-3">
                    {editType === EDIT_TYPE ? 'Update' : 'Submit'}
                </Button>
            </div>
            <Col className="my-5"></Col>
        </Form>
    )
}

const validate = (values) => {
    const errors = {}
    // const requiredFields = ['courseStartDate']
    // requiredFields.forEach((field) => {
    //     if (!values[field]) {
    //         errors[field] = 'Required'
    //     }
    // })

    // if (!moment(values['courseStartDate'], 'YYYY-MM-DD', true).isValid()) {
    //     errors['courseStartDate'] = 'Wrong date'
    // }

    return errors
}

export default reduxForm({
    form: 'applyForm',
    validate,
    enableReinitialize: true,
})(FormApply)
