import { useState, useEffect } from 'react'
import { Container, Button, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { reduxForm } from 'redux-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import history from '../../history'
import { GET_ONE_SCHOOL } from '../../store/types'
import { InputCourseBase } from '../InputCourseBase'
import { createSchool, updateSchool } from '../../store/actions/schoolActions'
import FormSchoolPictures from './FormSchoolPictures'

const FormSchool = (props) => {
    const dispatch = useDispatch()

    const {
        handleSubmit,
        editType,
        stateDescription,
        statePictures,
        schoolId,
    } = props

    const [description, setDescription] = useState(() => stateDescription)
    const [pictures, setPictures] = useState(() => statePictures)

    useEffect(() => {
        setPictures(statePictures)
    }, [statePictures])

    const submitForm = (formValue) => {
        formValue['description'] = description
        if (editType === EDIT_TYPE) {
            dispatch(updateSchool(formValue))
        } else if (editType === ADD_NEW_TYPE) {
            formValue['pictures'] = pictures
            dispatch(createSchool(formValue))
        }
    }

    return (
        <Container>
            <form className="form-signin" onSubmit={handleSubmit(submitForm)}>
                <Col>
                    <h3 className="text-center">
                        School {editType === EDIT_TYPE ? 'Edit' : 'Add New'}
                    </h3>
                </Col>
                <FormSchoolPictures
                    pictures={pictures}
                    setPictures={setPictures}
                    editType={editType}
                    schoolId={schoolId}
                    dispatch={dispatch}
                />
                <InputCourseBase school={true} />

                <Col className="my-3">
                    <label>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={stateDescription}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                        }}
                    />
                </Col>

                <Col className="py-2">
                    <Button type="submit" variant="info" className="mr-3">
                        {editType === EDIT_TYPE ? 'Update' : 'Submit'}
                    </Button>
                    <Button
                        variant="warning"
                        onClick={() => {
                            dispatch({
                                type: GET_ONE_SCHOOL,
                                payload: null,
                            })
                            history.goBack()
                        }}
                    >
                        Back
                    </Button>
                </Col>
                <Col className="my-5"></Col>
            </form>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'logo', 'info']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'schoolForm',
    validate,
    enableReinitialize: true,
})(FormSchool)
