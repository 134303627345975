import React from 'react'

const FormContainer = ({ children }) => {
    return (
        <div className="outer">
            <div className="inner">{children}</div>
        </div>
    )
}

export default FormContainer
