import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import { useDispatch } from 'react-redux'
import { SEARCH_ASSOCIATE } from '../../store/types'

const AssociateSearch = ({ displayKeyword }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
    }, [displayKeyword])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_ASSOCIATE,
            payload: {
                keyword,
            },
        })
    }

    useEffect(() => {
        setKeyword(displayKeyword)
    }, [displayKeyword])

    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={8}>
                        <Form.Control
                            type="text"
                            name="keyword"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Search Associate..."
                        ></Form.Control>
                    </Col>
                    <Col xs={4}>{frenchSearchButtonSm}</Col>
                </Row>
            </Form>
        </Col>
    )
}

export default AssociateSearch
