import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row, Form } from 'react-bootstrap'
import history from '../history'
import Loader from '../components/Loader'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
import { CHANGE_APPLICATION_PAGE, SEARCH_ORDER_BY } from '../store/types'
// import ReduxPaginate from '../components/ReduxPaginate'
import NewReduxPaginate from '../components/NewReduxPaginate'
import ApplySearch from '../components/apply/ApplySearch'
import { getAllApplies } from '../store/actions/applyActions'
import { getAllCourseLevels } from '../store/actions/courseBaseActions'
import ApplyList from '../components/apply/ApplyList'
import { searchOrderByList } from '../constants/apply'
import { DISPLAY_SEARCH_ORDERBY } from '../constants/studentInput'
import FinishedPopUp from '../components/apply/FinishedPopUp'

const Students = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseLevels } = courseBaseState

    const applyState = useSelector((state) => state.apply)
    const {
        applies,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStudent,
        searchUser,
        searchPaid,
        searchFinished,
        searchOrderBy,
        users,
        pages,
        page,
        count,
        finishedPopUp,
    } = applyState

    const pageNumber = page || 1
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(
                getAllApplies(
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchStudent,
                    searchUser,
                    searchPaid,
                    searchFinished,
                    searchOrderBy,
                    pageNumber
                )
            )
            dispatch(getAllCourseLevels())
        }
    }, [
        dispatch,
        userInfo,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStudent,
        searchUser,
        searchPaid,
        searchFinished,
        searchOrderBy,
        pageNumber,
    ])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_APPLICATION_PAGE, payload: value })
    }

    return applies ? (
        <Container>
            {finishedPopUp && <FinishedPopUp />}
            <ApplySearch
                displaySearchSchool={searchSchool}
                displaySearchCourse={searchCourse}
                displaySearchLevel={searchLevel}
                displaySearchLanguage={searchLanguage}
                displaySearchStudent={searchStudent}
                displaySearchUser={searchUser}
                displaySearchPaid={searchPaid}
                displaySearchFinished={searchFinished}
                users={users}
                userInfo={userInfo}
                courseLevels={courseLevels}
            />
            <Col className="py-3">
                <Row>
                    <Col>
                        {' '}
                        {DISPLAY_TOTAL}: {count}
                    </Col>
                    <Col>
                        <Form.Control
                            as="select"
                            name="searchOrderBy"
                            // defaultValue={displayStatus}
                            value={searchOrderBy}
                            onChange={(e) => {
                                dispatch({
                                    type: SEARCH_ORDER_BY,
                                    payload: e.target.value,
                                })
                            }}
                        >
                            <option value="">{DISPLAY_SEARCH_ORDERBY}</option>
                            {searchOrderByList.map((orderBy) => (
                                <option
                                    value={orderBy.value}
                                    key={orderBy.value}
                                >
                                    {orderBy.key}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>
            </Col>
            <Col>
                {applies.map((apply) => (
                    <ApplyList
                        apply={apply}
                        dispatch={dispatch}
                        key={apply.id}
                        userInfo={userInfo}
                    />
                ))}
            </Col>
            {/* <ReduxPaginate pages={pages} page={page} pageFunc={pageFunc} /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default Students
