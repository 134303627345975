import {
    GET_ALL_PROGRAMMENAMES,
    GET_ALL_PROGRAMMES,
    SHOW_LOADING,
} from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'

export const getAllProgrammes =
    (
        searchName = '',
        searchCourse = '',
        searchLevel = '',
        searchLanguage = '',
        pageNumber = ''
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `programme/listall?searchName=${searchName}&searchCourse=${searchCourse}&searchLevel=${searchLevel}&searchLanguage=${searchLanguage}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_PROGRAMMES,
                payload: {
                    programmes: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    searchName,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                },
            })
            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const getAllProgramNames = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`programme/coursenames`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_PROGRAMMENAMES,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
