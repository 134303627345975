import { Button, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { EDIT_TYPE } from '../../constants/editType'
import {
    BUTTON_DELETE,
    BUTTON_EDIT,
    BUTTON_INFO,
    // DISPLAY_APPLY_PAID,
    // DISPLAY_APPLY_UNPAID,
    // DISPLAY_APPLY_FINISHED,
    // DISPLAY_APPLY_UNFINISHED,
} from '../../constants/studentInput'
import {
    // applyStatusUpdate,
    deleteApply,
} from '../../store/actions/applyActions'
import DisplaySemester from './DisplaySemester'
import DisplayStudent from '../student/DisplayStudent'
import {
    // FINISHED_POP_UP,
    GET_ONE_SEMESTER,
} from '../../store/types'
import history from '../../history'
import DisplayStatus from './DisplayStatus'
import DisplayAgent from './DisplayAgent'
import { USER_ADMIN, USER_EMPLOYEE } from '../../constants/userRole'
// import {
//     APPLY_STATUS_PAID,
//     APPLY_STATUS_UNFINISHED,
//     APPLY_STATUS_UNPAID,
// } from '../../constants/apply'
import NewApplyNote from './NewApplyNote'
import ApplyButtons from './ApplyButtons'
import NewProgrammesList from '../programme/NewProgrammesList'

const ApplyList = ({ apply, dispatch, userInfo }) => {
    return (
        <Col className="shadow my-5 rounded border">
            {apply?.applynotes?.length > 0 && (
                <NewApplyNote applynotes={apply.applynotes} id={apply.id} />
            )}
            <DisplayStatus apply={apply} />
            <NewProgrammesList
                apply={apply}
                schoolCourse={apply.schoolcoursesemester.schoolcourse}
            />

            <DisplaySemester semester={apply.schoolcoursesemester} />
            <div className="my-3">
                <hr />
                <DisplayStudent student={apply.student} apply={true} />
                <hr />
            </div>
            <DisplayAgent apply={apply} />
            <Col className="mr-3 text-center p-3">
                <Link to={`/application/${apply.id}`}>
                    <Button variant="outline-info" size="sm" className="mr-3">
                        {BUTTON_INFO}
                    </Button>
                </Link>
                {apply?.applyStatus === 1 && !apply?.paid && (
                    <>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            className="mr-3"
                            onClick={() => {
                                dispatch({
                                    type: GET_ONE_SEMESTER,
                                    payload: null,
                                })
                                history.push(
                                    `/editapply/${EDIT_TYPE}/${apply.id}`
                                )
                            }}
                        >
                            {BUTTON_EDIT}
                        </Button>

                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="mr-3"
                            onClick={() => {
                                if (window.confirm('Are you sure?')) {
                                    dispatch(deleteApply(apply.id))
                                }
                            }}
                        >
                            {BUTTON_DELETE}
                        </Button>
                    </>
                )}
                {(userInfo?.role === USER_ADMIN ||
                    userInfo?.role === USER_EMPLOYEE) && (
                    <ApplyButtons apply={apply} />
                )}
            </Col>
        </Col>
    )
}

export default ApplyList
