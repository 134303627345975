import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { searchFinishedList, searchPaidList } from '../../constants/apply'
import { languageList } from '../../constants/language'
import {
    SEARCH_BOX,
    SEARCH_STUDENT_BOX,
    SEARCH_USER_SELECT,
    SEARCH_COURSE,
    SEARCH_LEVEL,
    SEARCH_LANGUAGE,
} from '../../constants/schoolCourse'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import {
    DISPLAY_APPLY_PAYEMNT_STATUS,
    DISPLAY_APPLY_STATUS,
} from '../../constants/studentInput'
import {
    AGENT_ADMIN,
    USER_ADMIN,
    USER_EMPLOYEE,
} from '../../constants/userRole'
import { getAllApplies } from '../../store/actions/applyActions'
import { SEARCH_APPLY } from '../../store/types'

const ApplySearch = ({
    displaySearchSchool,
    displaySearchCourse,
    displaySearchLevel,
    displaySearchLanguage,
    displaySearchStudent,
    displaySearchUser,
    displaySearchPaid,
    displaySearchFinished,
    users,
    userInfo,
    courseLevels,
}) => {
    const dispatch = useDispatch()
    const [searchSchool, setSearchSchool] = useState('')
    const [searchCourse, setSearchCourse] = useState('')
    const [searchLevel, setSearchLevel] = useState('')
    const [searchLanguage, setSearchLanguage] = useState('')
    const [searchStudent, setSearchStudent] = useState('')
    const [searchUser, setSearchUser] = useState('')
    const [searchPaid, setSearchPaid] = useState('')
    const [searchFinished, setSearchFinished] = useState('')

    const options = users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }))

    const [selectedOption, setSelectedOption] = useState(null)

    const handleSearchUserChange = (selectedOption) => {
        setSearchUser(selectedOption?.value ?? '')
        setSelectedOption(selectedOption)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_APPLY,
            payload: {
                searchSchool,
                searchCourse,
                searchLevel,
                searchLanguage,
                searchStudent,
                searchUser,
                searchPaid,
                searchFinished,
            },
        })
    }

    useEffect(() => {
        setSearchSchool(displaySearchSchool ?? '')
        setSearchCourse(displaySearchCourse ?? '')
        setSearchLevel(displaySearchLevel ?? '')
        setSearchLanguage(displaySearchLanguage ?? '')
        setSearchStudent(displaySearchStudent ?? '')
        setSearchUser(displaySearchUser ?? '')
        setSearchPaid(displaySearchPaid ?? '')
        setSearchFinished(displaySearchFinished ?? '')
    }, [
        displaySearchSchool,
        displaySearchCourse,
        displaySearchLevel,
        displaySearchLanguage,
        displaySearchStudent,
        displaySearchUser,
        displaySearchPaid,
        displaySearchFinished,
    ])
    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={6} lg={4} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchSchool"
                            value={searchSchool}
                            onChange={(e) => setSearchSchool(e.target.value)}
                            placeholder={SEARCH_BOX}
                        ></Form.Control>
                    </Col>
                    <Col xs={6} lg={4} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchCourse"
                            value={searchCourse}
                            onChange={(e) => setSearchCourse(e.target.value)}
                            placeholder={SEARCH_COURSE}
                        ></Form.Control>
                    </Col>
                    <Col xs={6} lg={4} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchStudent"
                            value={searchStudent}
                            onChange={(e) => setSearchStudent(e.target.value)}
                            placeholder={SEARCH_STUDENT_BOX}
                        ></Form.Control>
                    </Col>
                    <Col xs={12} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLevel"
                            // defaultValue={displayStatus}
                            value={searchLevel}
                            onChange={(e) => setSearchLevel(e.target.value)}
                        >
                            <option value="">{SEARCH_LEVEL}</option>
                            {courseLevels.map((level) => (
                                <option value={level.level} key={level.level}>
                                    {level.level}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLanguage"
                            // defaultValue={displayStatus}
                            value={searchLanguage}
                            onChange={(e) => setSearchLanguage(e.target.value)}
                        >
                            <option value="">{SEARCH_LANGUAGE}</option>
                            {languageList.map((language) => (
                                <option value={language} key={language}>
                                    {language}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>

                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchPaid"
                            // defaultValue={displayStatus}
                            value={searchPaid}
                            onChange={(e) => setSearchPaid(e.target.value)}
                        >
                            <option value="">
                                {DISPLAY_APPLY_PAYEMNT_STATUS}
                            </option>
                            {searchPaidList.map((paid) => (
                                <option value={paid.value} key={paid.value}>
                                    {paid.key}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>

                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchFinished"
                            // defaultValue={displayStatus}
                            value={searchFinished}
                            onChange={(e) => setSearchFinished(e.target.value)}
                        >
                            <option value="">{DISPLAY_APPLY_STATUS}</option>
                            {searchFinishedList.map((finished) => (
                                <option
                                    value={finished.value}
                                    key={finished.value}
                                >
                                    {finished.key}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>

                    {(userInfo?.role === USER_ADMIN ||
                        userInfo?.role === USER_EMPLOYEE ||
                        userInfo?.role === AGENT_ADMIN) && (
                        <Col xs={6} lg={3} className="my-2">
                            {/* <Form.Control
                                as="select"
                                name="searchUser"
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value)}
                            >
                                <option value="">{SEARCH_USER_SELECT}</option>
                                {users.map((user) => (
                                    <option value={user.id} key={user.id}>
                                        {user.firstName} {user.lastName}
                                    </option>
                                ))}
                            </Form.Control> */}
                            <Select
                                name="searchUser"
                                value={selectedOption}
                                onChange={handleSearchUserChange}
                                options={options}
                                isClearable
                                placeholder={SEARCH_USER_SELECT}
                                isSearchable
                            />
                        </Col>
                    )}
                    <Col xs={6} lg={3} className="my-2">
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            {frenchSearchButtonSm}{' '}
                            <Button
                                size="sm"
                                variant="outline-warning"
                                className="p-2"
                                style={{
                                    borderRadius: '10px',
                                    width: '100px',
                                    marginLeft: '20px',
                                }}
                                onClick={() => {
                                    dispatch(getAllApplies())
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export default ApplySearch
