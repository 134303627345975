import React from 'react'
import { Link } from 'react-router-dom'
import {
    CheckCircleFill,
    XCircleFill,
    PencilSquare,
} from 'react-bootstrap-icons'
import moment from 'moment'

const UserList = ({ user }) => {
    return (
        <tr>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>

            <td>{user.email}</td>
            <td>
                {user.verify === true ? (
                    <CheckCircleFill size={25} color="green" />
                ) : (
                    <XCircleFill size={25} color="red" />
                )}
            </td>
            <td>
                {user.isActive === true ? (
                    <CheckCircleFill size={25} color="green" />
                ) : (
                    <XCircleFill size={25} color="red" />
                )}
            </td>
            <td>{user.role}</td>
            <td>{user?.associate?.name}</td>
            <td>
                {user?.lastLoginTime &&
                    moment(user?.lastLoginTime).format('lll')}
            </td>

            <td>
                <div className="d-flex justify-content-between">
                    <Link to={`/adminuseredit/${user.id}`}>
                        {/* <img
                            src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615915229/samples/edit_avjmbi.png"
                            alt="edit"
                            width="30px"
                        /> */}
                        <PencilSquare size={25} color="blue" />
                    </Link>
                </div>
            </td>
        </tr>
    )
}

export default UserList
