import { Col, Row } from 'react-bootstrap'
import { ApplySchoolCourseInfo } from '../apply/ApplySchoolCourseInfo'
import { SchoolCourseInfo } from '../base/SchoolCourseInfo'

const NewProgrammesList = ({ schoolCourse, apply = '' }) => {
    return (
        <Col className={apply ? '' : 'shadow my-5 rounded border'}>
            <Col>
                <Row>
                    <Col xs={12} lg={4} className="header-picture">
                        <img
                            src={schoolCourse.coursebase.logo}
                            alt="logo"
                            width="200px"
                            style={{
                                objectFit: 'cover',
                            }}
                            className="img-fluid"
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <div
                            style={{
                                padding: '10px 0',
                                fontWeight: '600',
                                fontSize: '20px',
                            }}
                        >
                            {schoolCourse.coursebase.name}
                        </div>
                    </Col>
                    <Col xs={12} lg={4}>
                        <img
                            src={schoolCourse.school.logo}
                            width="100px"
                            alt="school_logo"
                        />
                        <div style={{ padding: '10px 0', fontWeight: '600' }}>
                            {schoolCourse.school.name}
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col className="mt-5 mb-5">
                {apply?.applyCommission ? (
                    <ApplySchoolCourseInfo apply={apply} />
                ) : (
                    <SchoolCourseInfo schoolProgram={schoolCourse} />
                )}
            </Col>
        </Col>
    )
}

export default NewProgrammesList
