import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container } from 'react-bootstrap'
import history from '../history'
// import Paginate from '../components/Paginate'
// import BootstrapPagination from '../components/BootstrapPagination'
import NewReduxPaginate from '../components/NewReduxPaginate'
import Loader from '../components/Loader'
import { getAllProgrammes } from '../store/actions/programmeActions'
import ProgrammesList from '../components/programme/ProgrammesList'
import ProgrammeSearch from '../components/programme/ProgrammeSearch'
import { getAllCourseLevels } from '../store/actions/courseBaseActions'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
import { CHANGE_PROGRAMME_PAGE } from '../store/types'

const Programmes = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseLevels } = courseBaseState

    const programmeState = useSelector((state) => state.programme)
    const {
        programmes,
        searchName,
        searchCourse,
        searchLevel,
        searchLanguage,
        pages,
        page,
        count,
    } = programmeState
    const pageNumber = page || 1
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(
                getAllProgrammes(
                    searchName,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    pageNumber
                )
            )
            dispatch(getAllCourseLevels())
        }
    }, [
        dispatch,
        userInfo,
        searchName,
        searchCourse,
        searchLevel,
        searchLanguage,
        pageNumber,
    ])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_PROGRAMME_PAGE, payload: value })
    }
    return programmes ? (
        <Container>
            <ProgrammeSearch
                displaySearchName={searchName}
                displaySearchCourse={searchCourse}
                displaySearchLevel={searchLevel}
                displaySearchLanguage={searchLanguage}
                courseLevels={courseLevels}
            />
            <Col>
                {DISPLAY_TOTAL}: {count}
            </Col>
            <Col>
                {programmes.map((schoolCourse) => (
                    <ProgrammesList
                        schoolCourse={schoolCourse}
                        key={schoolCourse.id}
                    />
                ))}
            </Col>
            {/* <Paginate area="programme" pages={pages} page={page} /> */}
            {/* <BootstrapPagination area="programme" pages={pages} page={page} /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default Programmes
