import { useState, useEffect } from 'react'
import FormContainer from '../components/FormContainer'
import { reduxForm, Field, change } from 'redux-form'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col } from 'react-bootstrap'
import { renderInput, renderRedayOnlyInput } from '../components/inputForm'
import uploadImg from '../config/uploadImg'
import { controlLoading } from '../store/actions/loadingActions'
import { associateRegister } from '../store/actions/associateActions'

const AssociateRegister = (props) => {
    const dispatch = useDispatch()

    const [logo, setLogo] = useState(null)
    const [certificate, setCertificate] = useState(null)

    const formState = useSelector((state) => state?.form?.associateForm)
    const inCanadaValue = formState?.values?.inCanada

    useEffect(() => {
        if (inCanadaValue === false) {
            dispatch(change('associateForm', 'gst', ''))
            dispatch(change('associateForm', 'qst', ''))
        }
    }, [dispatch, inCanadaValue])

    const uploadImage = async (e) => {
        const files = e.target.files
        if (!files) return false
        const data = new FormData()
        data.append('file', files[0])
        data.append('upload_preset', 'associate_uploads')
        try {
            dispatch(controlLoading(true))
            let res = await uploadImg.post('', data)
            setLogo(res.data.secure_url)
            dispatch(controlLoading(false))
        } catch (e) {
            console.log(e)
            dispatch(controlLoading(false))
        }
    }

    const uploadCertificate = async (e) => {
        const files = e.target.files
        if (!files) return false
        const data = new FormData()
        data.append('file', files[0])
        data.append('upload_preset', 'associate_uploads')
        try {
            dispatch(controlLoading(true))
            let res = await uploadImg.post('', data)
            setCertificate(res.data.secure_url)
            dispatch(controlLoading(false))
        } catch (e) {
            console.log(e)
            dispatch(controlLoading(false))
        }
    }

    const submitForm = (formValues) => {
        if (logo) formValues['logo'] = logo
        if (certificate) formValues['certificate'] = certificate
        // console.log(formValues)
        dispatch(associateRegister(formValues))
    }

    const { handleSubmit } = props

    return (
        <div>
            <FormContainer>
                <h3>Associate Register</h3>
                <form
                    className="form-signin"
                    onSubmit={handleSubmit(submitForm)}
                >
                    <div>
                        <b>Company Information/Informations de l’entreprise:</b>
                    </div>
                    <Field
                        name="name"
                        type="text"
                        label="Company Name/Nom de l'entreprise"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="email"
                        type="text"
                        label="Email/Courriel"
                        component={renderInput}
                    ></Field>
                    <div className="py-3">
                        <label htmlFor="files">
                            Company Logo/Logo d'entreprise
                        </label>
                        <input
                            type="file"
                            id="files"
                            name="logo"
                            placeholder="Upload an image"
                            className="form-control"
                            onChange={uploadImage}
                        />
                        {logo && (
                            <div className="py-3">
                                <img
                                    src={logo}
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        objectFit: 'cover',
                                    }}
                                    alt="logo"
                                />
                            </div>
                        )}
                    </div>
                    <div className="py-3">
                        <label htmlFor="certificate">
                            Business Certificate/Certificat d'entreprise
                        </label>
                        <input
                            type="file"
                            id="certificate"
                            name="certificate"
                            placeholder="Upload an image"
                            className="form-control"
                            onChange={uploadCertificate}
                        />
                        {certificate && (
                            <div className="py-3">
                                <img
                                    src={certificate}
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        objectFit: 'cover',
                                    }}
                                    alt="certificate"
                                />
                            </div>
                        )}
                    </div>
                    <div className="py-3">
                        <label htmlFor="inCanada">
                            Are you a Canadian company, If yes
                        </label>
                        <label htmlFor="inCanada">
                            Êtes-vous une entreprise canadienne, Si oui
                        </label>
                        <div>
                            <Field
                                name="inCanada"
                                id="inCanada"
                                component="input"
                                // className="form-control"
                                type="checkbox"
                            />
                        </div>
                    </div>

                    <Field
                        name="gst"
                        type="text"
                        label="GST number/Numéro de TPS"
                        component={
                            inCanadaValue ? renderInput : renderRedayOnlyInput
                        }
                    ></Field>
                    <Field
                        name="qst"
                        type="text"
                        label="QST number/Numéro de TVQ"
                        component={
                            inCanadaValue ? renderInput : renderRedayOnlyInput
                        }
                    ></Field>

                    <div className="my-3">
                        <b>Contact Information/Coordonnées:</b>
                    </div>
                    <Field
                        name="firstName"
                        type="text"
                        label="Owner’s First Name/Prénom du propriétaire"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="lastName"
                        type="text"
                        label="Owner’s Last Name/Nom de famille du propriétaire"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="address"
                        type="text"
                        label="Street Address/Adresse de la rue"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="city"
                        type="text"
                        label="City/Ville"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="province"
                        type="text"
                        label="Province/Province"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="country"
                        type="text"
                        label="Country/Pays"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="postCode"
                        type="text"
                        label="Postal Code/Code postal"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="phone"
                        type="text"
                        label="Phone/Téléphone"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="cellphone"
                        type="text"
                        label="Cellphone/Téléphone cellulaire"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="skype"
                        type="text"
                        label="Skype ID/identifiant Skype"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="whatsapp"
                        type="text"
                        label="WhatsApp ID/Identifiant Whatsapp"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="referred"
                        type="text"
                        label="Has anyone from QIEC helped or referred you? If yes, who?/Est-ce que quelqu'un de QIEC vous a aidé ou référé? Si oui, qui ?"
                        component={renderInput}
                    ></Field>
                    <div className="py-3">
                        <b>Recruitment Details/Détails de recrutement：</b>
                    </div>
                    <Field
                        name="beginDate"
                        type="text"
                        label="When did you begin recruiting students/Quand avez-vous commencé à recruter des étudiants ?"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="recruitFrom"
                        type="text"
                        label="Where do you recruit from/D’où sont des étudiants recrutés?"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="countStudent"
                        type="text"
                        label="Approximately how many students do you send abroad per year/Combien d'étudiants à peu près envoyez-vous à l'étranger par an ?"
                        component={renderInput}
                    ></Field>
                    <div className="py-3">
                        I declare that the information contained in this
                        application and all supporting documentation is true and
                        correct.
                        <br />
                        Je déclare que les informations contenues dans ce
                        formulaire de demande et tous les documents
                        justificatifs sont véridiques et exacts.
                    </div>
                    <Button
                        type="submit"
                        variant="primary"
                        className="btn-block py-2 mt-4"
                    >
                        Submit
                    </Button>
                </form>
                <Row className="py-3">
                    <Col>
                        Have an Account? <Link to="/login">Login</Link>
                    </Col>
                </Row>
            </FormContainer>
        </div>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'email', 'firstName', 'lastName', 'phone']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'associateForm',
    validate,
})(AssociateRegister)
