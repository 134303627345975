import { SHOW_LARGER_UPLOAD } from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch } from 'react-redux'
import { Col } from 'react-bootstrap'

const DisplayLargeUpload = ({ img }) => {
    const dispatch = useDispatch()
    const closeShowUp = () => {
        dispatch({
            type: SHOW_LARGER_UPLOAD,
            payload: {
                display: false,
                url: null,
            },
        })
    }
    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <Col>
                    <img src={img} alt="img" className="img-fluid " />
                </Col>
            </div>
        </div>
    )
}

export default DisplayLargeUpload
