import { GET_ALL_SEMESTERS, SHOW_LOADING } from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllSemesters =
    (
        searchSchool = '',
        searchCourse = '',
        searchLevel = '',
        searchLanguage = '',
        searchStatus = '',
        pageNumber = ''
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `semester/listall?searchSchool=${searchSchool}&searchCourse=${searchCourse}&searchLevel=${searchLevel}&searchLanguage=${searchLanguage}&searchStatus=${searchStatus}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_SEMESTERS,
                payload: {
                    semesters: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchStatus,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const semesterChangeStatus =
    (status, id) => async (dispatch, getState) => {
        // console.log('action status===>>>', status, 'id ===>>', id)
        const {
            searchSchool,
            searchCourse,
            searchLevel,
            searchLanguage,
            searchStatus,
            page,
        } = getState().semester
        try {
            dispatch(controlLoading(true))
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.put(`semester/changestatus/${id}`, status, {
                headers: { Authorization: `Bearer ${userToken}` },
            })

            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(
                getAllSemesters(
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchStatus,
                    page
                )
            )
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }
