import { useEffect } from 'react'
import { EDIT_SCHOOL_COURSE, SHOW_ADD_SCHOOL_COURSE } from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch, useSelector } from 'react-redux'
import FormAddProgram from './FormAddProgram'
import { getAllCourseBases } from '../../store/actions/courseBaseActions'

const EditAddProgram = () => {
    const dispatch = useDispatch()

    const schoolState = useSelector((state) => state.school)
    const { schoolCourse, editType, school } = schoolState

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseBases } = courseBaseState

    const closeShowUp = () => {
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE,
            payload: {
                schoolCourse: null,
                editType: null,
            },
        })
    }
    useEffect(() => {
        dispatch(getAllCourseBases())
    }, [dispatch])

    const initCourseBase = {
        ...schoolCourse,
        coursebase: schoolCourse?.coursebase?.id,
    }
    const stateDescription = schoolCourse ? schoolCourse.description : ''
    const stateApplyCondition = schoolCourse ? schoolCourse.applyCondition : ''
    const stateCommissionType = schoolCourse ? schoolCourse.commissionType : 1
    const stateCommissionTax = schoolCourse ? schoolCourse.commissionTax : 1
    const stateWorkPermit = schoolCourse ? schoolCourse.workPermit : false

    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <FormAddProgram
                    initialValues={initCourseBase}
                    stateDescription={stateDescription}
                    stateApplyCondition={stateApplyCondition}
                    editType={editType}
                    schoolId={school.id}
                    courseBases={courseBases}
                    stateCommissionType={stateCommissionType}
                    stateCommissionTax={stateCommissionTax}
                    stateWorkPermit={stateWorkPermit}
                />
            </div>
        </div>
    )
}

export default EditAddProgram
