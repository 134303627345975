import {
    Button,
    Card,
    Col,
    ListGroup,
    ListGroupItem,
    Row,
} from 'react-bootstrap'
import { XCircle, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'
import uploadImg from '../../config/uploadImg'
import {
    DISPLAY_UPLOAD,
    DISPLAY_PLACEHOLDER_TYPE,
    DISPLAY_PLACEHOLDER_URL,
} from '../../constants/studentInput'
import { controlLoading } from '../../store/actions/loadingActions'

const typeListArr = [
    'Passport',
    'Transcript',
    'Diploma',
    'Birth Certificate',
    'Valid CAQ',
    'Valid Study Permit',
    'Language Test',
    'Other',
]

const FormUpload = ({
    name,
    picLink,
    setName,
    setPicLink,
    handleAddPicture,
    pictures,
    handlePictureDelete,
    dispatch,
    approved,
}) => {
    const uploadImage = async (e) => {
        const files = e.target.files
        if (!files) return false
        const data = new FormData()
        data.append('file', files[0])
        data.append('upload_preset', 'student_uploads')
        try {
            dispatch(controlLoading(true))
            let res = await uploadImg.post('', data)
            setPicLink(res.data.secure_url)
            dispatch(controlLoading(false))
        } catch (e) {
            console.log(e)
            dispatch(controlLoading(false))
        }
    }

    return (
        <>
            <Card>
                <Card.Header>{DISPLAY_UPLOAD}</Card.Header>
                <Card.Body>
                    <div>
                        Approved{' '}
                        {approved === true ? (
                            <CheckCircleFill size={20} color="green" />
                        ) : (
                            <XCircleFill size={20} color="red" />
                        )}
                    </div>
                    <div className="text-sm mt-3">
                        * Required Passport, Transcript, Diploma, Birth
                        Certificate
                    </div>
                </Card.Body>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col xs={12} lg={8} className="my-3">
                                <select
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    aria-label="Type"
                                >
                                    <option value="" disabled>
                                        {DISPLAY_PLACEHOLDER_TYPE}
                                    </option>
                                    {typeListArr.map((list) => (
                                        <option key={list} value={list}>
                                            {list}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col xs={12} lg={8} className="my-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={picLink}
                                    onChange={(e) => setPicLink(e.target.value)}
                                    placeholder={DISPLAY_PLACEHOLDER_URL}
                                />
                            </Col>

                            <Col xs={12} lg={4} className="my-3">
                                <input
                                    type="file"
                                    id="files"
                                    name="file"
                                    placeholder="Upload an image"
                                    className="custom-file-input"
                                    onChange={uploadImage}
                                />
                                <label
                                    htmlFor="files"
                                    className="custom-file-label"
                                >
                                    Choose file...
                                </label>
                            </Col>
                            <Col xs={12} lg={8} className="my-3">
                                <Button
                                    variant="outline-primary"
                                    onClick={() => handleAddPicture()}
                                >
                                    {DISPLAY_UPLOAD}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Title>
                    <ListGroup className="list-group-flush">
                        <Row>
                            {pictures &&
                                pictures.map((picture, index) => {
                                    const pathIndex =
                                        picture.url.lastIndexOf('.')
                                    const fileType = picture.url
                                        .substr(pathIndex + 1)
                                        .toLowerCase()
                                    return (
                                        <Card
                                            key={index}
                                            style={{ width: '10rem' }}
                                            className="m-2"
                                        >
                                            {fileType === 'pdf' ? (
                                                <a
                                                    href={`${picture.url}`}
                                                    target="_black"
                                                >
                                                    <Card.Img
                                                        variant="top"
                                                        src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                                                        width="150px"
                                                        style={{
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                </a>
                                            ) : (
                                                <Card.Img
                                                    variant="top"
                                                    src={picture.url}
                                                    width="150px"
                                                    style={{
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            )}
                                            <Card.Body>
                                                <Card.Title>
                                                    {picture.type}
                                                </Card.Title>
                                            </Card.Body>
                                            <ListGroup className="list-group-flush">
                                                <ListGroupItem className="text-center">
                                                    <XCircle
                                                        size={16}
                                                        color="red"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                window.confirm(
                                                                    'Are you sure?'
                                                                )
                                                            ) {
                                                                handlePictureDelete(
                                                                    picture.id
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </ListGroupItem>
                                            </ListGroup>
                                        </Card>
                                    )
                                })}
                        </Row>
                    </ListGroup>
                </Card.Body>
            </Card>
        </>
    )
}

export default FormUpload
