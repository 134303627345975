import React from 'react'

const Loader = () => {
    return (
        <div className="loading-page">
            <img
                src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1616609279/samples/loading_mqn5mz.gif"
                alt="loading"
                width="150px"
                height="150px"
            />
        </div>
    )
}

export default Loader
