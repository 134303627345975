import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Field } from 'redux-form'

export const renderInput = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="mt-3">
        <label>
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <input
            className={`form-control ${touched && error && 'is-invalid'}`}
            type={type}
            maxLength={max}
            {...input}
        />
    </div>
)

export const paymentInput = ({
    input,
    placeholder,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="mt-3">
        <input
            className={`form-control ${touched && error && 'is-invalid'}`}
            style={{ height: '50px' }}
            type={type}
            maxLength={max}
            placeholder={placeholder}
            {...input}
        />
        <label>
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
    </div>
)

export const renderRedayOnlyInput = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="mt-3">
        <label>
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <input
            className={`form-control ${touched && error && 'is-invalid'}`}
            type={type}
            readOnly={true}
            maxLength={max}
            {...input}
        />
    </div>
)

export const dateInput = ({
    input,
    label,
    meta: { touched, error },
    ...custom
}) => (
    <div className="mt-3">
        <label>
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <div>
            <DatePicker
                {...input}
                {...custom}
                autoOk={true}
                dateForm="yyyy-MM-dd"
                // minDate={new Date()}

                selected={input.value ? new Date(input.value) : null}
                className={`form-control ${touched && error && 'is-invalid'}`}
            />
        </div>
    </div>
)

export const textAreaInput = ({
    input,
    label,
    rows = '10',
    meta: { touched, error },
}) => (
    <div className="mt-3">
        <label>
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <textarea
            className={`form-control ${touched && error && 'is-invalid'}`}
            rows={rows}
            {...input}
        />
    </div>
)

const renderRadioButton = (props) => {
    const { input, radioButtonValue, caption, ...field } = props
    console.log('====================================')
    console.log('input value', input.value)
    console.log('====================================')
    console.log('field value', field.value)
    return (
        <label>
            <input
                {...field.input}
                type="radio"
                value={radioButtonValue}
                checked={input.value === radioButtonValue}
            />
            <span>{caption}</span>
        </label>
    )
}

export const RadioButton = (props) => {
    return <Field {...props} component={renderRadioButton} />
}
