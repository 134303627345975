import { useState } from 'react'
import { Col } from 'react-bootstrap'
import { getErrors } from '../../store/actions/errorActions'
import {
    createStudentUpload,
    deleteStudentUpload,
} from '../../store/actions/studentActions'
import FormUpload from './FormUpload'

const EditUpload = ({ student, dispatch, applyId }) => {
    const [name, setName] = useState('')
    const [picLink, setPicLink] = useState('')

    const handleAddPicture = () => {
        if (picLink && name) {
            const values = {
                name,
                picLink,
                studentId: student.id,
                applyId,
            }
            dispatch(createStudentUpload(values))
            setName('')
            setPicLink('')
        } else {
            dispatch(getErrors({ msg: 'NEED Type and Url', state: 404 }))
        }
    }

    const handlePictureDelete = (deletePic) => {
        dispatch(deleteStudentUpload(deletePic, student.id, applyId))
    }

    return (
        <Col className="my-3">
            <FormUpload
                name={name}
                picLink={picLink}
                setName={setName}
                setPicLink={setPicLink}
                handleAddPicture={handleAddPicture}
                pictures={student.studentuploads}
                handlePictureDelete={handlePictureDelete}
                dispatch={dispatch}
                approved={student?.approved}
            />
        </Col>
    )
}

export default EditUpload
