import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import {
    SEARCH_STUDENT_BOX,
    SEARCH_USER_SELECT,
} from '../../constants/schoolCourse'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import {
    AGENT_ADMIN,
    USER_ADMIN,
    USER_EMPLOYEE,
} from '../../constants/userRole'
import { getAllStudents } from '../../store/actions/studentActions'
import { SEARCH_STUDENT } from '../../store/types'

const StudentSearch = ({
    displaySearchStudent,
    displaySearchUser,
    users,
    userInfo,
}) => {
    const dispatch = useDispatch()
    const [searchStudent, setSearchStudent] = useState('')
    const [searchUser, setSearchUser] = useState('')

    const options = users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }))

    const [selectedOption, setSelectedOption] = useState(null)

    const handleSearchUserChange = (selectedOption) => {
        setSearchUser(selectedOption?.value ?? '')
        setSelectedOption(selectedOption)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_STUDENT,
            payload: { searchStudent, searchUser },
        })
    }

    useEffect(() => {
        setSearchStudent(displaySearchStudent ?? '')
        setSearchUser(displaySearchUser ?? '')
    }, [displaySearchStudent, displaySearchUser])
    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col className="my-2">
                        <Form.Control
                            type="text"
                            name="searchStudent"
                            value={searchStudent}
                            onChange={(e) => setSearchStudent(e.target.value)}
                            placeholder={SEARCH_STUDENT_BOX}
                        ></Form.Control>
                    </Col>
                    {(userInfo?.role === USER_ADMIN ||
                        userInfo?.role === USER_EMPLOYEE ||
                        userInfo?.role === AGENT_ADMIN) && (
                        <Col xs={6} className="my-2">
                            {/* <Form.Control
                                as="select"
                                name="searchUser"
                                // defaultValue={displayStatus}
                                value={searchUser}
                                onChange={(e) => setSearchUser(e.target.value)}
                            >
                                <option value="">{SEARCH_USER_SELECT}</option>
                                {users.map((user) => (
                                    <option value={user.id} key={user.id}>
                                        {user.firstName} {user.lastName}
                                    </option>
                                ))}
                            </Form.Control> */}
                            <Select
                                name="searchUser"
                                value={selectedOption}
                                onChange={handleSearchUserChange}
                                options={options}
                                isClearable
                                placeholder={SEARCH_USER_SELECT}
                                isSearchable
                            />
                        </Col>
                    )}
                    <Col className="my-2">
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            {frenchSearchButtonSm}{' '}
                            <Button
                                size="sm"
                                variant="outline-warning"
                                className="p-2"
                                style={{
                                    borderRadius: '10px',
                                    width: '100px',
                                    marginLeft: '20px',
                                }}
                                onClick={() => {
                                    dispatch(getAllStudents())
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export default StudentSearch
