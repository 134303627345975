import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Table } from 'react-bootstrap'
import history from '../history'
import Loader from '../components/Loader'
import { getAllCourseLevels } from '../store/actions/courseBaseActions'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
// import ReduxPaginate from '../components/ReduxPaginate'
import NewReduxPaginate from '../components/NewReduxPaginate'
import { CHANGE_SEMESTER_PAGE } from '../store/types'
import { getAllSemesters } from '../store/actions/semesterActions'
import SemesterSearch from '../components/semester/SemesterSearch'
import SemesterList from '../components/semester/SemesterList'

const Programmes = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseLevels } = courseBaseState

    const semesterState = useSelector((state) => state.semester)
    const {
        semesters,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStatus,
        pages,
        page,
        count,
    } = semesterState

    const pageNumber = page || 1

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(
                getAllSemesters(
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchStatus,
                    pageNumber
                )
            )
            dispatch(getAllCourseLevels())
        }
    }, [
        dispatch,
        userInfo,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStatus,
        pageNumber,
    ])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_SEMESTER_PAGE, payload: value })
    }

    return semesters ? (
        <Container>
            <SemesterSearch
                displaySearchSchool={searchSchool}
                displaySearchCourse={searchCourse}
                displaySearchLevel={searchLevel}
                displaySearchLanguage={searchLanguage}
                displaySearchStatus={searchStatus}
                courseLevels={courseLevels}
            />
            <Col>
                {DISPLAY_TOTAL}: {count}
            </Col>
            <Col>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>School</th>
                            <th style={{ width: '200px' }}>Program</th>
                            <th>Apply Fee</th>
                            <th>Start Date</th>
                            <th>Language</th>
                            <th>Total Applies</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {semesters.map((semester) => (
                            <SemesterList
                                semester={semester}
                                dispatch={dispatch}
                                key={semester.id}
                            />
                        ))}
                    </tbody>
                </Table>
            </Col>
            {/* <ReduxPaginate pages={pages} page={page} pageFunc={pageFunc} /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default Programmes
