import { FINISHED_POP_UP } from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import uploadImg from '../../config/uploadImg'
import { controlLoading } from '../../store/actions/loadingActions'
import { Button, Col, Row } from 'react-bootstrap'
import { applyStatusUpdate } from '../../store/actions/applyActions'
import { APPLY_STATUS_FINISHED } from '../../constants/apply'
import { getErrors } from '../../store/actions/errorActions'

const FinishedPopUp = () => {
    const dispatch = useDispatch()

    const applyState = useSelector((state) => state.apply)
    const { finishedId } = applyState
    const closeShowUp = () => {
        dispatch({
            type: FINISHED_POP_UP,
            payload: { show: false, id: null },
        })
    }

    const [picLink, setPicLink] = useState('')

    const uploadImage = async (e) => {
        const files = e.target.files
        if (!files) return false
        const data = new FormData()
        data.append('file', files[0])
        data.append('upload_preset', 'student_uploads')
        try {
            dispatch(controlLoading(true))
            let res = await uploadImg.post('', data)
            setPicLink(res.data.secure_url)
            dispatch(controlLoading(false))
        } catch (e) {
            console.log(e)
            dispatch(controlLoading(false))
        }
    }

    const handleFinished = () => {
        if (picLink) {
            dispatch(
                applyStatusUpdate({
                    id: finishedId,
                    finished: APPLY_STATUS_FINISHED,
                    letterLink: picLink,
                })
            )
            dispatch({
                type: FINISHED_POP_UP,
                payload: { show: false, id: null },
            })
            setPicLink('')
        } else {
            dispatch(
                getErrors({ msg: 'NEED Upload confirm letter', state: 404 })
            )
        }
    }

    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <div className="apply-finished-box">
                    <Row>
                        <Col>Please upload Letter of Acceptance</Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="my-3">
                            <input
                                type="file"
                                id="files"
                                name="file"
                                placeholder="Upload an image"
                                className="custom-file-input"
                                onChange={uploadImage}
                            />
                            <label
                                htmlFor="files"
                                className="custom-file-label"
                            >
                                Choose file...
                            </label>
                        </Col>
                    </Row>
                    {picLink && (
                        <Row>
                            <Col className="my-3 text-center" xs={12}>
                                Pic Link:{' '}
                                <a href={picLink} target="_black">
                                    {picLink}
                                </a>
                            </Col>
                            <Col className="my-3 text-center" xs={12}>
                                <Button
                                    variant="outline-primary"
                                    onClick={() => handleFinished()}
                                >
                                    Finished
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FinishedPopUp
