import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Form } from 'react-bootstrap'
import moment from 'moment'
import { Envelope, ClockHistory, Person } from 'react-bootstrap-icons'
import { updateApplyAgent } from '../../store/actions/applyActions'
import { AGENT_ADMIN, USER_ADMIN } from '../../constants/userRole'
import { XCircle } from 'react-bootstrap-icons'

const DisplayAgent = ({ apply }) => {
    const dispatch = useDispatch()
    const [changeAgent, setChangeAgent] = useState(false)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const applyState = useSelector((state) => state.apply)
    const { users } = applyState

    const handleChangeAgent = (userId) => {
        const values = {
            id: apply.id,
            userId,
        }
        dispatch(updateApplyAgent(values))
        setChangeAgent(false)
    }

    return apply ? (
        <Row>
            <Col className="d-flex">
                <Person size={20} />

                {userInfo?.role === AGENT_ADMIN ||
                userInfo?.role === USER_ADMIN ? (
                    <>
                        <div>
                            {changeAgent ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Form.Control
                                        as="select"
                                        name="searchUser"
                                        value={apply?.user?.id}
                                        onChange={(e) =>
                                            handleChangeAgent(e.target.value)
                                        }
                                    >
                                        {users.map((user) => (
                                            <option
                                                value={user.id}
                                                key={user.id}
                                            >
                                                {user.firstName} {user.lastName}
                                            </option>
                                        ))}
                                    </Form.Control>
                                    <XCircle
                                        className="ml-1"
                                        size={20}
                                        style={{
                                            color: '#ff0000',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setChangeAgent(false)}
                                    />
                                </div>
                            ) : (
                                <span
                                    onDoubleClick={() => setChangeAgent(true)}
                                >
                                    {apply?.user?.firstName}{' '}
                                    {apply?.user?.lastName}
                                </span>
                            )}
                        </div>
                    </>
                ) : (
                    `${apply?.user?.firstName} ${apply?.user?.lastName}`
                )}
            </Col>
            <Col>
                <Envelope size={20} /> {apply?.user?.email}{' '}
            </Col>
            <Col className="text-right">
                <ClockHistory size={20} />{' '}
                {moment(apply?.createdAt).format('lll')}
            </Col>
        </Row>
    ) : (
        ''
    )
}

export default DisplayAgent
