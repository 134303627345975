export const USER_LOGOIN_SUCCESS = 'USER_LOGOIN_SUCCESS'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_UPDATE_INFO = 'USER_UPDATE_INFO'
export const USER_UPDATE_TOKEN = 'USER_UPDATE_TOKEN'
export const SHOW_USER_SIGN = 'SHOW_USER_SIGN'

export const RENEW_USER_TOKEN = 'RENEW_USER_TOKEN'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ONE_USER = 'GET_ONE_USER'
export const USER_EDIT_CLEAR = 'USER_EDIT_CLEAR'

export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_ERRORS = 'GET_ERRORS'
export const GET_MESSAGES = 'GET_MESSAGES'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'

// CourseBase
export const GET_ALL_COURSEBASE = 'GET_ALL_COURSEBASE'
export const GET_ONE_COURSEBASE = 'GET_ONE_COURSEBASE'

// School
export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS'
export const GET_ONE_SCHOOL = 'GET_ONE_SCHOOL'
export const SHOW_ADD_SCHOOL_COURSE = 'SHOW_ADD_SCHOOL_COURSE'
export const SHOW_ADD_SCHOOL_COURSE_SEMESTER = 'SHOW_ADD_SCHOOL_COURSE_SEMESTER'

// CourseLevel
export const GET_ALL_COURSELEVELS = 'GET_ALL_COURSELEVELS'
export const SHOW_ADD_COURSELEVEL = 'SHOW_ADD_COURSELEVEL'

// School Course
export const EDIT_SCHOOL_COURSE = 'EDIT_SCHOOL_COURSE'
export const EDIT_SCHOOL_COURSE_SEMESTER = 'EDIT_SCHOOL_COURSE_SEMESTER'
export const GET_ONE_SCHOOL_PROGRAM = 'GET_ONE_SCHOOL_PROGRAM'

// Etablissement
export const GET_ALL_ETABLISSEMENTS = 'GET_ALL_ETABLISSEMENTS'
export const GET_ONE_ETABLISSEMENT = 'GET_ONE_ETABLISSEMENT'
export const SEARCH_ETABLISSEMENT = 'SEARCH_ETABLISSEMENT'
export const CHANGE_ETABLISSEMENT_PAGE = 'CHANGE_ETABLISSEMENT_PAGE'

// Porgrammes
export const GET_ALL_PROGRAMMES = 'GET_ALL_PROGRAMMES'
export const GET_ALL_PROGRAMMENAMES = 'GET_ALL_PROGRAMMENAMES'
export const GET_ONE_PROGRAMME = 'GET_ONE_PROGRAMME'
export const SEARCH_PROGRAMME = 'SEARCH_PROGRAMME'
export const CHANGE_PROGRAMME_PAGE = 'CHANGE_PROGRAMME_PAGE'

// Student
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS'
export const GET_ONE_STUDENT = 'GET_ONE_STUDENT'
export const SEARCH_STUDENT = 'SEARCH_STUDENT'
export const SHOW_LARGER_UPLOAD = 'SHOW_LARGER_UPLOAD'
export const CHANGE_STUDENT_PAGE = 'CHANGE_STUDENT_PAGE'

// Apply
export const GET_ONE_SEMESTER = 'GET_ONE_SEMESTER'
export const GET_ALL_APPLIES = 'GET_ALL_APPLIES'
export const GET_ONE_APPLY = 'GET_ONE_APPLY'
export const SEARCH_APPLY = 'SEARCH_APPLY'
export const SHOW_ADD_STUDENT = 'SHOW_ADD_STUDENT'
export const SELECTED_STUDENT = 'SELECTED_STUDENT'
export const CHANGE_APPLICATION_PAGE = 'CHANGE_APPLICATION_PAGE'
export const SEARCH_ORDER_BY = 'SEARCH_ORDER_BY'
export const FINISHED_POP_UP = 'FINISHED_POP_UP'

// Semester
export const GET_ALL_SEMESTERS = 'GET_ALL_SEMESTERS'
export const SEARCH_SEMESTER = 'SEARCH_SEMESTER'
export const CHANGE_SEMESTER_PAGE = 'CHANGE_SEMESTER_PAGE'

// Associate
export const GET_ALL_ASSOCIATES = 'GET_ALL_ASSOCIATES'
export const GET_ONE_ASSOCIATE = 'GET_ONE_ASSOCIATE'
export const SEARCH_ASSOCIATE = 'SEARCH_ASSOCIATE'
export const CHANGE_ASSOCIATE_PAGE = 'CHANGE_ASSOCIATE_PAGE'
