import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Col } from 'react-bootstrap'
import BootstrapPagination from '../components/BootstrapPagination'
import AssociateList from '../components/Associate/AssociateList'
import Layout from '../components/Layout'
import { getAllAssociates } from '../store/actions/associateActions'
import AssociateSearch from '../components/Associate/AssociateSearch'

const Associates = ({ match }) => {
    const dispatch = useDispatch()

    const associateState = useSelector((state) => state.associate)
    const { associates, keyword, pages, page, count } = associateState
    const pageNumber = match.params.pageNumber || 1

    useEffect(() => {
        dispatch(getAllAssociates(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    return (
        <Layout>
            {associates ? (
                <Container fluid>
                    <AssociateSearch displayKeyword={keyword} />

                    <Col>
                        <Col className="py-2">Total: {count}</Col>
                        <Table responsive striped hover>
                            <thead>
                                <tr className="table-primary">
                                    <th>Company Name</th>
                                    <th>Owner</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Email Verify</th>
                                    <th>Confirmed</th>
                                    <th>Level</th>
                                    <th>Register Date</th>
                                    <th>Students</th>
                                    <th>Applications</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {associates &&
                                    associates.map((associate) => (
                                        <AssociateList
                                            key={associate.id}
                                            associate={associate}
                                        />
                                    ))}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                        <BootstrapPagination
                            area="associates"
                            pages={pages}
                            page={page}
                            keyword={''}
                        />
                    </Col>
                </Container>
            ) : (
                <div>Loading...</div>
            )}
        </Layout>
    )
}

export default Associates
