import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const userInfoFromStorage = localStorage.getItem('qiecUserInfo')
    ? JSON.parse(localStorage.getItem('qiecUserInfo'))
    : null

const userTokenFromStorage = localStorage.getItem('qiecUserToken')
    ? JSON.parse(localStorage.getItem('qiecUserToken'))
    : null

const initialState = {
    userLogin: {
        userInfo: userInfoFromStorage,
        userToken: userTokenFromStorage,
    },
}

const middleware = [thunk]

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store
