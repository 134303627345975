import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Form, Modal, ListGroup, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { EDIT_TYPE } from '../../constants/editType'
import {
    BUTTON_DELETE,
    BUTTON_EDIT,
    BUTTON_INFO,
    BUTTON_UPLOAD,
} from '../../constants/studentInput'
import {
    deleteStudent,
    updateAgent,
    updateStudentEditableStatus,
} from '../../store/actions/studentActions'
import {
    AGENT_ADMIN,
    USER_ADMIN,
    USER_EMPLOYEE,
} from '../../constants/userRole'
import { XCircle, CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'
import history from '../../history'

const editTypeList = {
    UPLOAD: 'upload',
    EDIT: 'edit',
}

const StudentList = ({ student, users, dispatch }) => {
    const [changeAgent, setChangeAgent] = useState(false)
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const handleChangeAgent = (userId) => {
        const values = {
            id: student.id,
            userId,
        }
        dispatch(updateAgent(values))
        setChangeAgent(false)
    }

    const [show, setShow] = useState(false)
    const [selectedApply, setSelectedApply] = useState(null)
    const [editType, setEditType] = useState(null)

    const handleClose = () => {
        setSelectedApply(null)
        setShow(false)
        setEditType(null)
    }
    const handleShow = useCallback((type) => {
        setShow(true)
        setEditType(type)
    }, [])

    const handleContinue = useCallback(
        (applyId) => {
            if (editType === editTypeList.UPLOAD) {
                history.push(`/studentupload/${student.id}`, {
                    applyId,
                })
            }
            if (editType === editTypeList.EDIT) {
                history.push(`/editstudent/${EDIT_TYPE}/${student.id}`, {
                    applyId,
                })
            }
        },
        [editType, student.id]
    )

    const handleEditableStatus = useCallback(
        (status) => {
            const values = { id: student.id, editableStatus: status }
            dispatch(updateStudentEditableStatus(values))
        },
        [student.id, dispatch]
    )

    return (
        <>
            <tr>
                <td>{student.studentId}</td>
                <td className="custom-th">{student.firstName}</td>
                <td className="custom-th">{student.lastName}</td>
                <td>{student.email}</td>
                <td className="custom-th">
                    {userInfo?.role === AGENT_ADMIN ? (
                        <>
                            <div>
                                {changeAgent ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Form.Control
                                            as="select"
                                            name="searchUser"
                                            value={student.user.id}
                                            onChange={(e) =>
                                                handleChangeAgent(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {users.map((user) => (
                                                <option
                                                    value={user.id}
                                                    key={user.id}
                                                >
                                                    {user.firstName}{' '}
                                                    {user.lastName}
                                                </option>
                                            ))}
                                        </Form.Control>
                                        <XCircle
                                            className="ml-1"
                                            size={20}
                                            style={{
                                                color: '#ff0000',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                setChangeAgent(false)
                                            }
                                        />
                                    </div>
                                ) : (
                                    <span
                                        onDoubleClick={() =>
                                            setChangeAgent(true)
                                        }
                                    >
                                        {student.user.firstName}{' '}
                                        {student.user.lastName}
                                    </span>
                                )}
                            </div>
                        </>
                    ) : (
                        `${student.user.firstName} ${student.user.lastName}`
                    )}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {student?.applies?.length}
                </td>
                <td style={{ textAlign: 'center' }}>
                    {' '}
                    {student?.approved === true ? (
                        <CheckCircleFill size={20} color="green" />
                    ) : (
                        <XCircleFill size={20} color="red" />
                    )}
                </td>
                <td>
                    <ButtonGroup aria-label="Basic">
                        <Link to={`/student/${student.id}`}>
                            <Button
                                variant="outline-info"
                                size="sm"
                                className="mr-1"
                            >
                                {BUTTON_INFO}
                            </Button>
                        </Link>
                        {student?.studentEditable ? (
                            <>
                                <Button
                                    variant="outline-warning"
                                    size="sm"
                                    className="mr-1"
                                    onClick={() =>
                                        student.applies.length > 0
                                            ? handleShow(editTypeList.UPLOAD)
                                            : history.push(
                                                  `/studentupload/${student.id}`
                                              )
                                    }
                                >
                                    {BUTTON_UPLOAD}
                                </Button>

                                <Button
                                    size="sm"
                                    variant="outline-primary"
                                    className="mr-1"
                                    onClick={() =>
                                        student.applies.length > 0
                                            ? handleShow(editTypeList.EDIT)
                                            : history.push(
                                                  `/editstudent/${EDIT_TYPE}/${student.id}`
                                              )
                                    }
                                >
                                    {BUTTON_EDIT}
                                </Button>

                                {student.applies &&
                                    student.applies.length === 0 && (
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className="mr-1"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Are you sure?'
                                                    )
                                                ) {
                                                    dispatch(
                                                        deleteStudent(
                                                            student.id
                                                        )
                                                    )
                                                }
                                            }}
                                        >
                                            {BUTTON_DELETE}
                                        </Button>
                                    )}
                            </>
                        ) : userInfo &&
                          (userInfo.role === USER_ADMIN ||
                              userInfo.role === USER_EMPLOYEE) ? (
                            <Button
                                variant="outline-success"
                                size="sm"
                                className="mr-1"
                                onClick={() => handleEditableStatus(true)}
                            >
                                Return Editable
                            </Button>
                        ) : (
                            <span className="ml-3">Waiting for approval</span>
                        )}
                    </ButtonGroup>
                </td>
            </tr>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Student Edit Requirement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>You need selected one application ID to continue</div>
                    <ListGroup as="ul">
                        {student?.applies?.map((apply) => (
                            <ListGroup.Item
                                key={apply.id}
                                as="li"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setSelectedApply(apply.id)}
                                active={selectedApply === apply.id}
                            >
                                {apply.applyId} ({apply.courseStartDate})
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button
                        variant="primary"
                        onClick={() => handleContinue(selectedApply)}
                        disabled={!selectedApply}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StudentList
