import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Description from '../base/Description'
import {
    SchoolCourseInfo,
    SchoolCourseSemester,
} from '../base/SchoolCourseInfo'

const DisplaySchoolCourse = ({ schoolProgram }) => {
    return (
        <Card className="shadow my-3">
            <Card.Img
                variant="top"
                src={schoolProgram?.school?.logo}
                style={{
                    width: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '5px',
                }}
            />
            <Card.Body>
                <Card.Title>
                    <h2>
                        <Link
                            to={`/etablissement/${schoolProgram?.school?.id}`}
                        >
                            <strong>{schoolProgram?.school?.name}</strong>
                        </Link>
                    </h2>
                </Card.Title>
                <Card.Text>{schoolProgram?.school?.info}</Card.Text>

                <SchoolCourseInfo schoolProgram={schoolProgram} />
                <Description description={schoolProgram.description} />
            </Card.Body>
            <SchoolCourseSemester schoolProgram={schoolProgram} />
        </Card>
    )
}

export default DisplaySchoolCourse
