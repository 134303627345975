const Description = ({ description }) => {
    const createMarkup = () => {
        return { __html: description }
    }
    return (
        <div dangerouslySetInnerHTML={createMarkup()} className="editor"></div>
    )
}

export default Description
