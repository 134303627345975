import React, { useEffect } from 'react'
import { Container, Button } from 'react-bootstrap'
import { useDispatch, useSelector, connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import BackButton from '../components/BackButton'
import { renderInput } from '../components/inputForm'

import history from '../history'
import { userUpdateProfile } from '../store/actions/userActions'

const UserEdit = (props) => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)

    const { userInfo } = userLogin

    const { handleSubmit } = props

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        }
    }, [dispatch, userInfo])

    const submitForm = (formValue) => {
        // console.log('this is form submit form===>>', formValue)
        dispatch(userUpdateProfile(formValue))
    }

    return (
        <Container>
            <BackButton />
            <div>
                <h3>User Info</h3>
                <form
                    className="form-signin"
                    onSubmit={handleSubmit(submitForm)}
                >
                    {/* <Field
                        name="username"
                        type="text"
                        label="User Name"
                        component={renderInput}
                    ></Field> */}
                    <div className="mt-3">
                        <label>Username</label>
                        <div>{userInfo?.username}</div>
                    </div>

                    <div className="mt-3">
                        <label>Email</label>
                        <div>{userInfo?.email}</div>
                    </div>

                    <Field
                        name="firstName"
                        type="text"
                        label="First Name"
                        component={renderInput}
                    ></Field>

                    <Field
                        name="lastName"
                        type="text"
                        label="Last Name"
                        component={renderInput}
                    ></Field>

                    {/* <Field
                        name="email"
                        type="email"
                        label="Email"
                        component={renderInput}
                    ></Field> */}

                    <Field
                        name="address"
                        type="text"
                        label="Address"
                        component={renderInput}
                    ></Field>

                    <Field
                        name="newpassword"
                        type="password"
                        label="New Password"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="rpassword"
                        type="password"
                        label="Confirm Password"
                        component={renderInput}
                    ></Field>

                    <Button
                        type="submit"
                        variant="primary"
                        className="btn-block py-2 mt-4"
                    >
                        Update
                    </Button>
                </form>
            </div>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['firstName', 'lastName', 'email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.newpassword !== values.rpassword) {
        errors.rpassword = 'Not match'
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

const mapStateToProps = (state) => ({
    initialValues: state?.userLogin?.userInfo,
    enableReinitialize: true,
})

const formWrapped = reduxForm({
    form: 'userForm',
    validate,
})(UserEdit)

export default connect(mapStateToProps, null)(formWrapped)
