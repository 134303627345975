import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row, Form, Table } from 'react-bootstrap'
import history from '../history'
import Loader from '../components/Loader'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
import { CHANGE_APPLICATION_PAGE, SEARCH_ORDER_BY } from '../store/types'
// import ReduxPaginate from '../components/ReduxPaginate'
import NewReduxPaginate from '../components/NewReduxPaginate'
import ApplySearch from '../components/apply/ApplySearch'
import { getAllApplies } from '../store/actions/applyActions'
import { getAllCourseLevels } from '../store/actions/courseBaseActions'
import { searchOrderByList } from '../constants/apply'
import { DISPLAY_SEARCH_ORDERBY } from '../constants/studentInput'
import FinishedPopUp from '../components/apply/FinishedPopUp'
import NewList from '../components/apply/NewList'
import { USER_ADMIN } from '../constants/userRole'

const StudentRegistrationList = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseLevels } = courseBaseState

    const applyState = useSelector((state) => state.apply)
    const {
        applies,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStudent,
        searchUser,
        searchPaid,
        searchFinished,
        searchOrderBy,
        users,
        pages,
        page,
        count,
        finishedPopUp,
    } = applyState

    const pageNumber = page || 1
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(
                getAllApplies(
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchStudent,
                    searchUser,
                    searchPaid,
                    searchFinished,
                    searchOrderBy,
                    pageNumber
                )
            )
            dispatch(getAllCourseLevels())
        }
    }, [
        dispatch,
        userInfo,
        searchSchool,
        searchCourse,
        searchLevel,
        searchLanguage,
        searchStudent,
        searchUser,
        searchPaid,
        searchFinished,
        searchOrderBy,
        pageNumber,
    ])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_APPLICATION_PAGE, payload: value })
    }

    return applies ? (
        <Container fluid>
            {finishedPopUp && <FinishedPopUp />}
            <Container>
                <ApplySearch
                    displaySearchSchool={searchSchool}
                    displaySearchCourse={searchCourse}
                    displaySearchLevel={searchLevel}
                    displaySearchLanguage={searchLanguage}
                    displaySearchStudent={searchStudent}
                    displaySearchUser={searchUser}
                    displaySearchPaid={searchPaid}
                    displaySearchFinished={searchFinished}
                    users={users}
                    userInfo={userInfo}
                    courseLevels={courseLevels}
                />
            </Container>
            <Col className="py-3">
                <Row>
                    <Col>
                        {' '}
                        {DISPLAY_TOTAL}: {count}
                    </Col>
                    <Col>
                        <Form.Control
                            as="select"
                            name="searchOrderBy"
                            // defaultValue={displayStatus}
                            value={searchOrderBy}
                            onChange={(e) => {
                                dispatch({
                                    type: SEARCH_ORDER_BY,
                                    payload: e.target.value,
                                })
                            }}
                        >
                            <option value="">{DISPLAY_SEARCH_ORDERBY}</option>
                            {searchOrderByList.map((orderBy) => (
                                <option
                                    value={orderBy.value}
                                    key={orderBy.value}
                                >
                                    {orderBy.key}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                </Row>
            </Col>
            <Col>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th className="custom-th">Application ID</th>
                            <th className="custom-th">Application DATE</th>
                            <th className="custom-th">Student ID</th>
                            <th className="custom-th">First Name</th>
                            <th className="custom-th">Last Name</th>
                            <th className="custom-th">Email</th>
                            <th className="custom-th">Student Birthday</th>
                            <th className="custom-th">Application Qty</th>
                            <th className="custom-th">Last Updated</th>
                            <th className="custom-th">Student Updated</th>
                            <th className="custom-th">Agent Name</th>
                            <th className="custom-th">Agent Email</th>
                            <th className="custom-th">Agent Company</th>
                            <th className="custom-th">School Name</th>
                            <th className="custom-th">Program</th>
                            <th className="custom-th">Program Language</th>
                            <th className="custom-th">Session</th>
                            <th className="custom-th">Status</th>
                            <th className="custom-th">Notes</th>
                            <th></th>
                            <th></th>
                            {userInfo?.role === USER_ADMIN && <th></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {applies.map((apply, index) => (
                            <NewList
                                apply={apply}
                                dispatch={dispatch}
                                key={apply.id}
                                userInfo={userInfo}
                                index={index}
                            />
                        ))}
                    </tbody>
                </Table>
            </Col>
            {/* <ReduxPaginate pages={pages} page={page} pageFunc={pageFunc} /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default StudentRegistrationList
