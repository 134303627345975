import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { EDIT_TYPE } from '../constants/editType'
import { getOneStudent } from '../store/actions/studentActions'
import FormStudent from '../components/student/FormStudent'
import { genderList } from '../constants/studentInput'
import { GET_ONE_STUDENT } from '../store/types'

const EditStudent = ({ match, history, location }) => {
    const type = match.params.type
    const id = match.params.id
    const dispatch = useDispatch()

    const { applyId } = location.state || {}

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const studentState = useSelector((state) => state.student)
    const { student } = studentState

    useEffect(() => {
        if (student?.applies?.length > 0 && !applyId) {
            alert('something went wrong')
            history.push('/students')
        }
    }, [student, applyId, history])

    useEffect(
        () => () =>
            dispatch({
                type: GET_ONE_STUDENT,
                payload: null,
            }),

        [dispatch]
    )

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            if (type === EDIT_TYPE) {
                dispatch(getOneStudent(id))
            }
        }
    }, [dispatch, userInfo, id, history, type])

    const studentId = student ? student.id : ''
    const valuse = student ? student : { gender: genderList[0] }
    return (
        <Container>
            <FormStudent
                initialValues={valuse}
                editType={type}
                studentId={studentId}
                applyId={applyId}
            />
        </Container>
    )
}

export default EditStudent
