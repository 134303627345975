import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../store/actions/userActions'
import {
    Building,
    Book,
    People,
    CardChecklist,
    JournalMedical,
    PersonBoundingBox,
    PersonLinesFill,
    BoxArrowRight,
    Journals,
    Envelope,
    List,
} from 'react-bootstrap-icons'
import { AGENT_ADMIN, USER_ADMIN, USER_EMPLOYEE } from '../constants/userRole'
import history from '../history'
import { SEARCH_APPLY, SEARCH_ORDER_BY, SEARCH_STUDENT } from '../store/types'

const Header = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    const logoutHandler = () => {
        dispatch(logout())
    }

    return (
        <header>
            <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect>
                <Container>
                    <Navbar.Brand>
                        <img
                            src="https://res.cloudinary.com/dvbvpmjez/image/upload/v1619183491/School_Pictures/qiec_3_gycpnj.png"
                            width="100"
                            className="d-inline-block align-top"
                            alt="logo"
                            style={
                                {
                                    // filter: 'invert(1)',
                                }
                            }
                        />
                    </Navbar.Brand>

                    {userInfo && (
                        <>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ml-auto">
                                    <Nav.Link
                                        className="d-inline-flex"
                                        onClick={() =>
                                            history.push('/etablissements')
                                        }
                                    >
                                        <Building className="mr-1" size={14} />
                                        Schools
                                    </Nav.Link>

                                    <Nav.Link
                                        className="d-inline-flex"
                                        onClick={() =>
                                            history.push('/programmes')
                                        }
                                    >
                                        <Book className="mr-1" size={14} />
                                        Programs
                                    </Nav.Link>

                                    <Nav.Link
                                        className="d-inline-flex"
                                        onClick={() => {
                                            dispatch({
                                                type: SEARCH_STUDENT,
                                                payload: {
                                                    searchStudent: '',
                                                    searchUser: '',
                                                },
                                            })
                                            history.push('/students')
                                        }}
                                    >
                                        <People className="mr-1" size={14} />
                                        Students
                                    </Nav.Link>

                                    <Nav.Link
                                        className="d-inline-flex"
                                        onClick={() => {
                                            dispatch({
                                                type: SEARCH_APPLY,
                                                payload: {
                                                    searchSchool: '',
                                                    searchCourse: '',
                                                    searchLevel: '',
                                                    searchLanguage: '',
                                                    searchUser: '',
                                                    searchStudent: '',
                                                    searchPaid: '',
                                                    searchFinished: '',
                                                },
                                            })
                                            dispatch({
                                                type: SEARCH_ORDER_BY,
                                                payload: '',
                                            })
                                            history.push('/applications')
                                        }}
                                    >
                                        <CardChecklist
                                            className="mr-1"
                                            size={14}
                                        />
                                        Applications
                                    </Nav.Link>

                                    {userInfo &&
                                        (userInfo.role === USER_ADMIN ||
                                            userInfo.role ===
                                                USER_EMPLOYEE) && (
                                            <Nav.Link
                                                className="d-inline-flex"
                                                onClick={() => {
                                                    dispatch({
                                                        type: SEARCH_APPLY,
                                                        payload: {
                                                            searchSchool: '',
                                                            searchCourse: '',
                                                            searchLevel: '',
                                                            searchLanguage: '',
                                                            searchUser: '',
                                                            searchStudent: '',
                                                            searchPaid: '',
                                                            searchFinished: '',
                                                        },
                                                    })
                                                    dispatch({
                                                        type: SEARCH_ORDER_BY,
                                                        payload: '',
                                                    })
                                                    history.push('/srl')
                                                }}
                                            >
                                                <CardChecklist
                                                    className="mr-1"
                                                    size={14}
                                                />
                                                SRL
                                            </Nav.Link>
                                        )}

                                    <Nav.Link
                                        className="d-inline-flex"
                                        onClick={() => {
                                            history.push('/contactus')
                                        }}
                                    >
                                        <Envelope className="mr-1" size={14} />
                                        Contact us
                                    </Nav.Link>

                                    {userInfo &&
                                        userInfo.role === USER_ADMIN && (
                                            <>
                                                <NavDropdown
                                                    title="Setting"
                                                    id="setting"
                                                >
                                                    <LinkContainer to="/coursebase">
                                                        <NavDropdown.Item className="d-inline-flex">
                                                            <JournalMedical
                                                                className="mr-1"
                                                                size={14}
                                                            />
                                                            Program Base
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/school">
                                                        <NavDropdown.Item className="d-flex align-items-center">
                                                            <Building
                                                                className="mr-1"
                                                                size={14}
                                                            />
                                                            School
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/semesters">
                                                        <NavDropdown.Item className="d-flex align-items-center">
                                                            <Journals
                                                                className="mr-1"
                                                                size={14}
                                                            />
                                                            School Semesters
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </NavDropdown>
                                            </>
                                        )}
                                    <NavDropdown
                                        title={userInfo?.firstName}
                                        id="username"
                                    >
                                        <LinkContainer to="/profile">
                                            <NavDropdown.Item className="d-flex align-items-center">
                                                <PersonBoundingBox
                                                    className="mr-2 align-self-center"
                                                    size={14}
                                                />
                                                Profile
                                            </NavDropdown.Item>
                                        </LinkContainer>

                                        {userInfo &&
                                            userInfo.role === USER_ADMIN && (
                                                <>
                                                    <LinkContainer to="/users">
                                                        <NavDropdown.Item className="d-flex align-items-center">
                                                            <PersonLinesFill
                                                                className="mr-2 align-self-center"
                                                                size={14}
                                                            />
                                                            Users
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/associates">
                                                        <NavDropdown.Item className="d-flex align-items-center">
                                                            <Building
                                                                className="mr-2 align-self-center"
                                                                size={14}
                                                            />
                                                            Associates
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </>
                                            )}

                                        {userInfo &&
                                            (userInfo.role === USER_ADMIN ||
                                                userInfo.role ===
                                                    AGENT_ADMIN) && (
                                                <LinkContainer to="/associatecontrol">
                                                    <NavDropdown.Item className="d-flex align-items-center">
                                                        <List
                                                            className="mr-2 align-self-center"
                                                            size={14}
                                                        />
                                                        Associate Control
                                                    </NavDropdown.Item>
                                                </LinkContainer>
                                            )}

                                        <NavDropdown.Item
                                            className="d-flex align-items-center"
                                            onClick={logoutHandler}
                                        >
                                            <BoxArrowRight
                                                className="mr-2 align-self-center"
                                                size={14}
                                            />
                                            Logout
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </>
                    )}
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
