import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row, Form } from 'react-bootstrap'
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import {
    deleteOneUser,
    getOneUser,
    resendEmail,
    updateOneUser,
} from '../store/actions/userActions'
import moment from 'moment'

const AdminUserEdit = ({ match }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const id = match.params.id
    const rolesSelect = ['admin', 'employee', 'agent', 'agentAdmin']
    const agentLevel = [1, 2, 3, 4, 5]

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo, user } = userLogin

    const [isActive, setIsActive] = useState(false)
    const [newrole, setNewrole] = useState(null)
    const [level, setLevel] = useState(1)

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        }
    }, [dispatch, userInfo, history])

    useEffect(() => {
        if (!user) {
            dispatch(getOneUser(id))
        } else {
            setIsActive(user.isActive)
            setNewrole(user.role)
            setLevel(user.level)
        }
    }, [dispatch, id, user])

    const handleConfirm = (e) => {
        setIsActive(!isActive)
    }

    const handleSubmit = () => {
        const userLevel = newrole === 'agent' ? level : 1
        const values = {
            ...user,
            role: newrole,
            isActive: isActive,
            level: userLevel,
        }
        dispatch(updateOneUser(values))
    }

    return user ? (
        <Container>
            <Col className="my-3">
                <Button variant="info" onClick={() => history.goBack()}>
                    Back
                </Button>
            </Col>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Company Name:
                </Col>
                <Col xs={8}>{user?.associate?.name}</Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    First Name:
                </Col>
                <Col xs={8}>{user.firstName}</Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Last Name:
                </Col>
                <Col xs={8}>{user.lastName}</Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Email:
                </Col>
                <Col xs={8}>{user.email}</Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Address:
                </Col>
                <Col xs={8}>{user.address}</Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Last Login Time:
                </Col>
                <Col xs={8}>
                    {user?.lastLoginTime &&
                        moment(user?.lastLoginTime).format('lll')}
                </Col>
            </Row>
            <Row className="py-3">
                <Col xs={4} className="text-right">
                    Email verify:
                </Col>
                <Col xs={8}>
                    {' '}
                    {user.verify === true ? (
                        <CheckCircleFill size={25} color="green" />
                    ) : (
                        <>
                            <XCircleFill size={25} color="red" />{' '}
                            <Button
                                style={{ marginLeft: '20px' }}
                                size="sm"
                                variant="info"
                                onClick={() => dispatch(resendEmail(user.id))}
                            >
                                Resend
                            </Button>
                        </>
                    )}
                </Col>
            </Row>
            <Form>
                <Row className="py-3">
                    <Col xs={4} className="text-right">
                        Confirmed:
                    </Col>
                    <Col xs={8}>
                        <input
                            type="checkbox"
                            defaultChecked={user.isActive}
                            onChange={handleConfirm}
                            className="form-control"
                        />
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col xs={4} className="text-right">
                        Role:
                    </Col>
                    <Col xs={8}>
                        {newrole && (
                            <select
                                className="form-control"
                                defaultValue={newrole}
                                onChange={(e) => setNewrole(e.target.value)}
                            >
                                {rolesSelect.map((aa) => {
                                    return (
                                        <option key={aa} value={aa}>
                                            {aa}
                                        </option>
                                    )
                                })}
                            </select>
                        )}
                    </Col>
                </Row>
                {newrole === 'agent' && (
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Level:
                        </Col>
                        <Col xs={8}>
                            {agentLevel && (
                                <select
                                    className="form-control"
                                    defaultValue={level}
                                    onChange={(e) => setLevel(e.target.value)}
                                >
                                    {agentLevel.map((aa) => {
                                        return (
                                            <option key={aa} value={aa}>
                                                {aa}
                                            </option>
                                        )
                                    })}
                                </select>
                            )}
                        </Col>
                    </Row>
                )}
                <Row className="py-3">
                    <Col xs={4} className="text-right"></Col>
                    <Col xs={8}>
                        <Button
                            variant="success"
                            onClick={() => handleSubmit()}
                        >
                            Update
                        </Button>

                        {!user?.isActive &&
                            user?.students?.length === 0 &&
                            user?.applies?.length === 0 && (
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    variant="danger"
                                    onClick={() => {
                                        if (window.confirm('Are you sure?')) {
                                            dispatch(deleteOneUser(user.id))
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                    </Col>
                </Row>
            </Form>
        </Container>
    ) : (
        ' '
    )
}

export default AdminUserEdit
