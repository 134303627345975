import 'mapbox-gl/dist/mapbox-gl.css'
import ReactMapGL, { Marker } from 'react-map-gl'
import { useEffect, useState } from 'react'

const DisplayMap = ({ latitude, longitude }) => {
    const [viewport, setViewport] = useState({
        width: '100%',
        height: '35vh',
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        zoom: 12,
    })

    useEffect(() => {
        setViewport({
            width: '100%',
            height: '35vh',
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            zoom: 12,
        })
    }, [latitude, longitude])

    return (
        <div>
            <ReactMapGL
                {...viewport}
                onViewportChange={setViewport}
                // mapStyle="mapbox://styles/jerry3222/ckclf0wex00641ip2x07h7iiv"
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxApiAccessToken="pk.eyJ1IjoiamVycnkzMjIyIiwiYSI6ImNrY2xhMW01dzBlbjEyeHB3Y2k4a2UxZDAifQ.srcyVHgdAoUFkssPknaaPQ"
            >
                <Marker
                    latitude={parseFloat(latitude)}
                    longitude={parseFloat(longitude)}
                    offsetLeft={-20}
                    offsetTop={-10}
                >
                    <img
                        src="https://res.cloudinary.com/dvbvpmjez/image/upload/v1620063499/School_Pictures/placeholder_1_ggt0kp.png"
                        alt="pin"
                    />
                </Marker>
            </ReactMapGL>
        </div>
    )
}

export default DisplayMap
