import { Col, Row } from 'react-bootstrap'
import {
    INPUT_FIRSTNAME,
    INPUT_LASTNAME,
    INPUT_EMAIL,
    INPUT_CITYOFBIRTH,
    INPUT_COUNTRYOFBIRTH,
    INPUT_GENTDER,
    INPUT_ADDRESS,
    INPUT_CITY,
    INPUT_PROVINCE,
    INPUT_COUNTRY,
    INPUT_POSTCODE,
    INPUT_NATIONALITY,
    INPUT_PHONE,
    INPUT_NATIVELANGUAGE,
    INPUT_LANGUAGEATHOME,
    INPUT_FATHERFIRSTNAME,
    INPUT_FATHERLASTNAME,
    INPUT_MOTHERFIRSTNAME,
    INPUT_MOTHERLASTNAME,
    INPUT_CARD_NAME,
    INPUT_CARD_NO,
    INPUT_CARD_EXPIRED,
    INPUT_CARD_SECURITY,
    INPUT_BIRTHDATE,
} from '../../constants/studentInput'
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'

const DisplayStudent = ({ student, apply = '', applyQty = null }) => {
    return student ? (
        <Col>
            <Row>
                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_FIRSTNAME}</label>
                    <p>{student.firstName}</p>
                </Col>
                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_LASTNAME}</label>
                    <p>{student.lastName}</p>
                </Col>

                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_EMAIL}</label>
                    <p>{student.email}</p>
                </Col>
                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_BIRTHDATE}</label>
                    <p>{student.birthDate}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_CITYOFBIRTH}</label>
                    <p>{student.cityOfBirth}</p>
                </Col>
                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_COUNTRYOFBIRTH}</label>
                    <p>{student.countryOfBirth}</p>
                </Col>

                <Col xs={6} lg={3}>
                    <label className="text-muted">{INPUT_GENTDER}</label>
                    <p>{student.gender}</p>
                </Col>
                {student?.applies?.length > 0 ? (
                    <Col xs={6} lg={3}>
                        <label className="text-muted">
                            Applications Quantity
                        </label>
                        <p>{student?.applies?.length}</p>
                    </Col>
                ) : null}
            </Row>
            {!apply && (
                <>
                    <hr />
                    <Row>
                        <Col xs={12} lg={6}>
                            <label className="text-muted">
                                {INPUT_ADDRESS}
                            </label>
                            <p>{student.address}</p>
                        </Col>

                        <Col xs={6} lg={3}>
                            <label className="text-muted">{INPUT_CITY}</label>
                            <p>{student.city}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_PROVINCE}
                            </label>
                            <p>{student.province}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_COUNTRY}
                            </label>
                            <p>{student.country}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_POSTCODE}
                            </label>
                            <p>{student.postCode}</p>
                        </Col>

                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_NATIONALITY}
                            </label>
                            <p>{student.nationality}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">{INPUT_PHONE}</label>
                            <p>{student.Phone}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_NATIVELANGUAGE}
                            </label>
                            <p>{student.nativeLanguage}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_LANGUAGEATHOME}
                            </label>
                            <p>{student.languageAtHome}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">ID</label>
                            <p>{student.studentId}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                Approved/Approuvée
                            </label>
                            <p>
                                {' '}
                                {student?.approved === true ? (
                                    <CheckCircleFill size={20} color="green" />
                                ) : (
                                    <XCircleFill size={20} color="red" />
                                )}
                            </p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_FATHERFIRSTNAME}
                            </label>
                            <p>{student.fatherFirstName}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_FATHERLASTNAME}
                            </label>
                            <p>{student.fatherLastName}</p>
                        </Col>

                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_MOTHERFIRSTNAME}
                            </label>
                            <p>{student.motherFirstName}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_MOTHERLASTNAME}
                            </label>
                            <p>{student.motherLastName}</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <div>
                                NOTE : I hereby authorize QUEBEC INTERNATIONAL
                                EDUCATION CENTER INC. to use my credit card for
                                the payment of my apply fee.
                            </div>
                            <div>
                                NOTE : Par la présente, j’autorise CENTRE
                                INTERNATIONAL D'ÉDUCATION DU QUÉBEC à utiliser
                                ma carte de crédit pour payer mes frais
                                d'inscription
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <label className="text-muted">Card Type</label>
                            <p>{student.cardType}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_CARD_NAME}
                            </label>
                            <p>{student.cardName}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_CARD_NO}
                            </label>
                            <p>{student.cardNo}</p>
                        </Col>

                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_CARD_EXPIRED}
                            </label>
                            <p>{student.cardExpired}</p>
                        </Col>
                        <Col xs={6} lg={3}>
                            <label className="text-muted">
                                {INPUT_CARD_SECURITY}
                            </label>
                            <p>{student.cardSecurity}</p>
                        </Col>
                        <Col xs={12} lg={6}>
                            <label className="text-muted">
                                Billing Address
                            </label>
                            <p>{student.billingAddress}</p>
                        </Col>
                        <Col xs={12} lg={6}>
                            <label className="text-muted">
                                Billing Post Code
                            </label>
                            <p>{student.billingPostCode}</p>
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    ) : (
        ''
    )
}

export default DisplayStudent
