import { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ApplyNote from '../components/apply/ApplyNote'
import DisplayAgent from '../components/apply/DisplayAgent'
import DisplaySemester from '../components/apply/DisplaySemester'
import DisplayStatus from '../components/apply/DisplayStatus'
import BackButton from '../components/BackButton'
import ProgrammesList from '../components/programme/ProgrammesList'
import DisplayStudent from '../components/student/DisplayStudent'
import DisplayUpload from '../components/student/DisplayUpload'

import { getOneApply } from '../store/actions/applyActions'
import { GET_ONE_APPLY } from '../store/types'

const Application = ({ match, history }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const applyState = useSelector((state) => state.apply)
    const { apply } = applyState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneApply(id))
        }
        return () => dispatch({ type: GET_ONE_APPLY, payload: null })
    }, [dispatch, userInfo, id, history])
    return apply ? (
        <Container>
            <BackButton />
            <DisplayStatus apply={apply} />
            {apply?.schoolcoursesemester?.schoolcourse && (
                <ProgrammesList
                    schoolCourse={apply?.schoolcoursesemester.schoolcourse}
                />
            )}
            <div className="my-3">
                <DisplaySemester semester={apply?.schoolcoursesemester} />
            </div>
            <DisplayStudent student={apply.student} />
            <Row>
                {apply?.student?.studentuploads &&
                    apply?.student?.studentuploads?.map((upload) => (
                        <DisplayUpload
                            upload={upload}
                            dispatch={dispatch}
                            key={upload.id}
                        />
                    ))}
            </Row>
            <hr />
            <DisplayAgent apply={apply} />
            <ApplyNote notes={apply?.applynotes} applyId={apply.id} />
        </Container>
    ) : (
        ''
    )
}

export default Application
