import { Button, Col, Row } from 'react-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { DISPLAY_LEARN_MORE } from '../../constants/studentInput'
import { ApplySchoolCourseInfo } from '../apply/ApplySchoolCourseInfo'
import { SchoolCourseInfo } from '../base/SchoolCourseInfo'

const ProgrammesList = ({ schoolCourse, apply = '' }) => {
    return (
        <Row className={apply ? '' : 'shadow my-5 rounded border'}>
            <Col xs={12} lg={4} className="header-picture">
                {schoolCourse.coursebase.logo && apply ? (
                    <img
                        src={schoolCourse.coursebase.logo}
                        alt="logo"
                        width="200px"
                        style={{
                            objectFit: 'cover',
                        }}
                        className="img-fluid"
                    />
                ) : (
                    <img
                        src={schoolCourse.coursebase.logo}
                        alt="logo"
                        style={{
                            objectFit: 'cover',
                        }}
                        className="img-fluid"
                    />
                )}
            </Col>
            <Col xs={12} lg={4}>
                <Col className="py-3">
                    <div
                        style={{
                            padding: '10px 0',
                            fontWeight: '600',
                            fontSize: '20px',
                        }}
                    >
                        {schoolCourse.coursebase.name}
                    </div>

                    <>
                        <p style={{ whiteSpace: 'pre-line' }}>
                            {schoolCourse.coursebase.info}
                        </p>
                        {!apply && (
                            <Link to={`/schoolprogram/${schoolCourse.id}`}>
                                {' '}
                                <Button
                                    variant="primary"
                                    style={{
                                        borderRadius: '10px',
                                        marginRight: 'auto',
                                    }}
                                >
                                    {DISPLAY_LEARN_MORE}{' '}
                                    <ArrowRight size={18} className="mx-1" />
                                </Button>
                            </Link>
                        )}
                    </>
                </Col>
            </Col>
            <Col xs={12} lg={4} className="my-2">
                <Col className="py-3">
                    <img
                        src={schoolCourse.school.logo}
                        width="100px"
                        alt="school_logo"
                    />
                    <div style={{ padding: '10px 0', fontWeight: '600' }}>
                        {schoolCourse.school.name}
                    </div>
                </Col>
                {apply?.applyCommission ? (
                    <ApplySchoolCourseInfo apply={apply} />
                ) : (
                    <SchoolCourseInfo schoolProgram={schoolCourse} />
                )}
            </Col>
        </Row>
    )
}

export default ProgrammesList
