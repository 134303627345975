import { Col, Container, Row } from 'react-bootstrap'

const ContactUs = () => {
    const contactList = [
        { item: 'General question/Question générale:', email: 'info@qiec.ca' },
        {
            item: 'Question of commission/Question de commission: ',
            email: 'accounting@qiec.ca',
        },
        {
            item: 'Student admission/Admission des étudiants: ',
            email: 'helene@qiec.ca',
        },
    ]
    return (
        <Container>
            <div className="contact-us-upper">
                <div className="contact-us-div">
                    <h1 className="contact-us-header">Contact US</h1>
                </div>
                <p style={{ fontSize: '16px', marginTop: '20px' }}>
                    +1-438-408-0919
                </p>
                <p
                    style={{
                        fontSize: '14px',
                        color: '#909090',
                    }}
                >
                    Montreal Canada
                </p>
                <table
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    style={{
                        width: '700px',
                        backgroundColor: '#f3f3f3',
                        borderRadius: '5px',
                        color: '#909090',
                    }}
                >
                    <thead>
                        <tr>
                            <td
                                style={{ padding: '10px', textAlign: 'center' }}
                            >
                                <div className="contact-us-icon-box">
                                    <div className="contact-us-icon">
                                        <svg
                                            className="mk-svg-icon"
                                            data-name="mk-moon-location-3"
                                            data-cacheid="icon-619bb3a1a5e2f"
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                                fill: 'currentColor',
                                                color: 'white',
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M418 32h-324c-51.7 0-94 42.3-94 94v354l96-96h322c51.7 0 94-42.3 94-94v-164c0-51.7-42.3-94-94-94zm-258 224c-17.673 0-32-14.327-32-32s14.327-32 32-32 32 14.327 32 32-14.327 32-32 32zm96 0c-17.673 0-32-14.327-32-32s14.327-32 32-32 32 14.327 32 32-14.327 32-32 32zm96 0c-17.673 0-32-14.327-32-32s14.327-32 32-32 32 14.327 32 32-14.327 32-32 32z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ padding: '0 60px 0px 60px' }}>
                                {contactList.map((item, index) => (
                                    <Row key={index}>
                                        <Col xs={7}>{item.item}</Col>
                                        <Col xs={5}>{item.email}</Col>
                                    </Row>
                                ))}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    paddingTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <div className="contact-us-icon-box">
                                    <div className="contact-us-icon">
                                        <svg
                                            className="mk-svg-icon"
                                            data-name="mk-moon-location-3"
                                            data-cacheid="icon-619bb3a1a5e2f"
                                            style={{
                                                height: '20px',
                                                width: '20px',
                                                fill: 'currentColor',
                                                color: 'white',
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path d="M321.788 371.146c-11.188 6.236-20.175 2.064-32.764-4.572-11.46-8.748-45.402-35.438-81.226-71.188-26.156-33.084-46.162-64.288-55.375-79.293-.625-1.66-.944-2.632-.944-2.632-5.397-13.476-8.771-22.92-1.324-33.521 6.854-9.727 9.5-12.383 18.24-20.108l-87.79-130.124c-10.604 7.728-27.018 25.106-40.509 44.378-12.538 18.317-23.154 38.587-26.049 53.055 15.295 55.117 52.258 157.896 120.583 231.325l-.021.308c65.73 81.028 170.165 131.43 225.571 153.226 14.679-1.385 35.938-9.844 55.456-20.404 20.598-11.415 39.567-25.945 48.329-35.685l-120.288-100.829c-8.597 7.91-11.498 10.254-21.889 16.064zm-116.178-242.488c7.241-5.302 5.313-14.944 1.926-20.245l-66.579-101.913c-4.344-5.291-13.396-8.064-21.252-5.579l-27.433 18.381 88.034 129.879 25.304-20.523zm287.339 269.188l-94.473-76.788c-4.93-3.918-14.313-6.838-20.325-.188l-23.046 23.05 120.047 101.015 21.136-25.357c3.285-7.564 1.467-16.857-3.339-21.732z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: '0 60px 60px 60px' }}>
                                If you are an existing customer and have a
                                question, please contact your Account
                                Representative for assistance. <br />
                                Si vous êtes un client existant et que vous avez
                                une question, veuillez contacter votre
                                représentant de compte pour obtenir de l’aide.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Container>
    )
}

export default ContactUs
