import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import { renderInput, textAreaInput } from './inputForm'

export const InputCourseBase = ({ school = '' }) => {
    return (
        <>
            <Col>
                <Field
                    name="name"
                    type="text"
                    label="Name"
                    max="200"
                    component={renderInput}
                ></Field>
            </Col>
            <Col>
                <Field
                    name="logo"
                    type="text"
                    label="Logo"
                    max="200"
                    component={renderInput}
                ></Field>
            </Col>
            {school && (
                <>
                    <Col>
                        <Field
                            name="sequence"
                            type="number"
                            label="Sequence"
                            max="10"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col>
                        <Field
                            name="address"
                            type="text"
                            label="Address"
                            max="200"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col>
                        <Field
                            name="latitude"
                            type="text"
                            label="Map Latitude"
                            max="20"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col>
                        <Field
                            name="longitude"
                            type="text"
                            label="Map Longitude"
                            max="20"
                            component={renderInput}
                        ></Field>
                    </Col>
                </>
            )}
            <Col>
                <Field
                    name="info"
                    label="Information"
                    rows="5"
                    component={textAreaInput}
                ></Field>
            </Col>
        </>
    )
}
