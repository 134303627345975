import { Col, Row } from 'react-bootstrap'
import {
    DISPLAY_APPLY_COURSE_STARTDATE,
    DISPLAY_APPLY_DONE,
    DISPLAY_APPLY_PAID,
    // DISPLAY_APPLY_PROCESS,
    DISPLAY_APPLY_UNPAID,
} from '../../constants/studentInput'

export const applyStatus = {
    1: { color: 'bg-success', status: 'New' },
    2: { color: 'bg-primary', status: 'Reviewing' },
    3: { color: 'bg-danger', status: 'Missing' },
    4: { color: 'bg-warning', status: 'Processing' },
    5: { color: 'bg-info', status: 'Done' },
}

const DisplayStatus = ({ apply }) => {
    return (
        <Col className="my-3">
            <Row>
                <Col>
                    <div
                        className={`apply-status ${
                            apply.paid ? 'bg-success' : 'bg-danger'
                        }`}
                    >
                        {apply.paid ? DISPLAY_APPLY_PAID : DISPLAY_APPLY_UNPAID}
                    </div>
                </Col>
                <Col>
                    {DISPLAY_APPLY_COURSE_STARTDATE}: {apply.courseStartDate}
                </Col>
                <Col className="display-finsihed">
                    {apply.letterLink ? (
                        <span style={{ marginRight: '10px' }}>
                            <a href={`${apply.letterLink}`} target="_black">
                                Letter of Acceptance
                            </a>
                        </span>
                    ) : (
                        ''
                    )}
                    <div
                        className={`apply-finished ${
                            apply.finished
                                ? 'bg-info'
                                : applyStatus[apply?.applyStatus]?.color
                        }`}
                    >
                        {apply.finished
                            ? DISPLAY_APPLY_DONE
                            : applyStatus[apply?.applyStatus]?.status}
                    </div>
                </Col>
            </Row>
        </Col>
    )
}

export default DisplayStatus
