import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import { getOneCourseBase } from '../store/actions/courseBaseActions'
import history from '../history'
import Description from '../components/base/Description'
import Header from '../components/base/Header'
import Pictures from '../components/base/Pictures'

const CourseBaseInfo = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseBase } = courseBaseState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneCourseBase(id))
        }
    }, [dispatch, userInfo, id])

    return (
        <Container>
            <Header baseInfo={courseBase} />
            {courseBase.pictures && courseBase.pictures.length > 0 && (
                <Pictures baseInfo={courseBase} />
            )}
            <Col>
                <Row>
                    <Col xs={8}>
                        <Description description={courseBase.description} />
                    </Col>
                    <Col xs={4}></Col>
                </Row>
            </Col>
        </Container>
    )
}

export default CourseBaseInfo
