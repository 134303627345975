import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from 'react-redux'
import { updateApplyAdminNote } from '../../store/actions/applyActions'

const ApplyAdminNote = ({ apply }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const [adminNote, setAdminNote] = useState(apply?.adminNote)

    const saveChange = () => {
        const values = { adminNote }
        dispatch(updateApplyAdminNote(values, apply.id))
        handleClose()
    }

    return (
        <>
            <Button
                variant="success"
                onClick={handleShow}
                className="custom-th"
                size="sm"
            >
                Admin Note
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea
                        style={{
                            width: '100%',
                            height: '100px',
                            borderRadius: '5px',
                            borderColor: '#cccccc',
                            padding: '5px',
                        }}
                        value={adminNote}
                        onChange={(e) => setAdminNote(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveChange}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ApplyAdminNote
