import {
    GET_ALL_APPLIES,
    GET_ONE_APPLY,
    GET_ONE_SEMESTER,
    SELECTED_STUDENT,
    SHOW_LOADING,
} from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import history from '../../history'
import { controlLoading } from './loadingActions'

export const getAllApplies =
    (
        searchSchool = '',
        searchCourse = '',
        searchLevel = '',
        searchLanguage = '',
        searchStudent = '',
        searchUser = '',
        searchPaid = '',
        searchFinished = '',
        searchOrderBy = '',
        pageNumber = ''
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `apply/listall?searchSchool=${searchSchool}&searchCourse=${searchCourse}&searchLevel=${searchLevel}&searchLanguage=${searchLanguage}&searchUser=${searchUser}&searchStudent=${searchStudent}&searchOrderBy=${searchOrderBy}&searchPaid=${searchPaid}&searchFinished=${searchFinished}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_APPLIES,
                payload: {
                    applies: res.data.data.applies,
                    users: res.data.data.users,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    searchSchool,
                    searchCourse,
                    searchLevel,
                    searchLanguage,
                    searchUser,
                    searchStudent,
                    searchPaid,
                    searchFinished,
                    searchOrderBy,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const getOneSemester = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`apply/listonesemester/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: GET_ONE_SEMESTER,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneApply = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`apply/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: GET_ONE_APPLY,
            payload: res.data.data,
        })
        dispatch({
            type: SELECTED_STUDENT,
            payload: res.data.data.student,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createApply = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`apply/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        await request.get(`apply/applicationemail/${res.data.data.id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch({
            type: SELECTED_STUDENT,
            payload: null,
        })
        dispatch(controlLoading(false))
        history.push('/applications')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateApply = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`apply/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SELECTED_STUDENT,
            payload: null,
        })
        dispatch({
            type: GET_ONE_APPLY,
            payload: null,
        })
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
        history.push('/applications')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteApply = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`apply/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
        history.push('/applications')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const applyStatusUpdate = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`apply/statusupdate/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateApplyAgent = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
            apply: {
                searchSchool,
                searchCourse,
                searchLevel,
                searchLanguage,
                searchStudent,
                searchUser,
                searchPaid,
                searchFinished,
                searchOrderBy,
                page,
            },
        } = getState()
        let res = await request.put(
            `apply/applychangeagent/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(
            getAllApplies(
                searchSchool,
                searchCourse,
                searchLevel,
                searchLanguage,
                searchStudent,
                searchUser,
                searchPaid,
                searchFinished,
                searchOrderBy,
                page
            )
        )
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createApplyNote = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`apply/createapplynote`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
        dispatch(getOneApply(values.applyId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteApplyNote = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`apply/deleteapplynote/${values.id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
        dispatch(getOneApply(values.applyId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const confirmApplyNote = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(
            `apply/confirmapplynote/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
        dispatch(getOneApply(values.applyId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateApplyAdminNote =
    (values, id) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.put(`apply/updateadminnote/${id}`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch(getAllApplies())
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const confirmStudentUpdated = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(
            `apply/confirmstudentupdated/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch(getAllApplies())
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
