import React, { useState } from 'react'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import { renderInput } from '../inputForm'
import {
    createSchoolCourse,
    updateSchoolCourse,
} from '../../store/actions/schoolActions'

const commissionTypeList = [
    { value: 1, name: 'Percentage' },
    { value: 2, name: 'Exact amount' },
]

const commissionTaxList = [
    { value: 1, name: 'Plus GST QST' },
    { value: 2, name: 'Tax included' },
]

const FormAddProgram = (props) => {
    const dispatch = useDispatch()

    const {
        handleSubmit,
        editType,
        stateDescription,
        stateApplyCondition,
        schoolId,
        courseBases,
        stateCommissionType,
        stateCommissionTax,
        stateWorkPermit,
    } = props

    const [commissionType, setCommissionType] = useState(
        () => stateCommissionType
    )

    const [commissionTax, setCommissionTax] = useState(() => stateCommissionTax)

    const [description, setDescription] = useState(() => stateDescription)
    const [applyCondition, setApplyCondition] = useState(
        () => stateApplyCondition
    )
    const [workPermit, setWorkPermit] = useState(() => stateWorkPermit)

    const handleWorkPermit = () => {
        setWorkPermit(!workPermit)
    }

    const submitForm = (formValue) => {
        formValue['description'] = description
        formValue['applyCondition'] = applyCondition
        formValue['commissionType'] = commissionType
        formValue['commissionTax'] = commissionTax
        formValue['schoolId'] = schoolId
        formValue['workPermit'] = workPermit
        if (editType === EDIT_TYPE) {
            dispatch(updateSchoolCourse(formValue))
        } else if (editType === ADD_NEW_TYPE) {
            dispatch(createSchoolCourse(formValue))
        }
    }

    return (
        <Container>
            <form className="form-signin" onSubmit={handleSubmit(submitForm)}>
                <Col>
                    <h3 className="text-center">
                        School Program{' '}
                        {editType === EDIT_TYPE ? 'Edit' : 'Add New'}
                    </h3>
                </Col>

                <Col className="py-3">
                    <Row>
                        <Col>
                            <label htmlFor="coursebase">Program Base</label>
                            <Field
                                name="coursebase"
                                className="form-control"
                                component="select"
                            >
                                <option />
                                {courseBases &&
                                    courseBases.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.name} -{' '}
                                            {item.courselevel.level}
                                        </option>
                                    ))}
                            </Field>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Field
                        name="coursePrice"
                        type="text"
                        label="Program Price"
                        max="200"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="applyFee"
                        type="number"
                        label="Apply Fee"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="securityDeposit"
                        type="number"
                        label="security Deposit"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <label style={{ marginTop: '20px' }}>Commission Type</label>
                    <div>
                        {commissionTypeList.map((type) => (
                            <label
                                key={type.value}
                                style={{ marginRight: '20px' }}
                            >
                                <input
                                    type="radio"
                                    value={type.value}
                                    name="commissionType"
                                    style={{ marginRight: '5px' }}
                                    defaultChecked={
                                        type.value === commissionType
                                    }
                                    onChange={(e) =>
                                        setCommissionType(e.target.value)
                                    }
                                />
                                {type.name}
                            </label>
                        ))}
                    </div>
                </Col>
                <Col>
                    <label style={{ marginTop: '20px' }}>Commission Tax</label>
                    <div>
                        {commissionTaxList.map((tax) => (
                            <label
                                key={tax.value}
                                style={{ marginRight: '20px' }}
                            >
                                <input
                                    type="radio"
                                    value={tax.value}
                                    name="commissionTax"
                                    style={{ marginRight: '5px' }}
                                    defaultChecked={tax.value === commissionTax}
                                    onChange={(e) =>
                                        setCommissionTax(e.target.value)
                                    }
                                />
                                {tax.name}
                            </label>
                        ))}
                    </div>
                </Col>
                <Col>
                    <Field
                        name="commission"
                        type="number"
                        label="Commission Level 1"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="commission2"
                        type="number"
                        label="Commission Level 2"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="commission3"
                        type="number"
                        label="Commission Level 3"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="commission4"
                        type="number"
                        label="Commission Level 4"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <Field
                        name="commission5"
                        type="number"
                        label="Commission Level 5"
                        max="10"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <label style={{ marginTop: '20px' }}>
                        Post-Graduation Work Permit
                    </label>
                    <div>
                        <input
                            type="checkbox"
                            defaultChecked={workPermit}
                            onChange={handleWorkPermit}
                        />
                    </div>
                </Col>
                <Col>
                    <Field
                        name="courseDuration"
                        type="text"
                        label="Program Duration Time"
                        max="30"
                        component={renderInput}
                    ></Field>
                </Col>

                <Col className="my-3">
                    <label>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={
                            editType === EDIT_TYPE
                                ? stateDescription
                                : description
                        }
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                        }}
                    />
                </Col>
                <Col className="my-3">
                    <label>Apply Condition</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={
                            editType === EDIT_TYPE
                                ? stateApplyCondition
                                : applyCondition
                        }
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setApplyCondition(data)
                        }}
                    />
                </Col>

                <Col className="py-2">
                    <Button
                        type="submit"
                        variant="info"
                        className="btn-block mr-3"
                    >
                        {editType === EDIT_TYPE ? 'Update' : 'Submit'}
                    </Button>
                </Col>
                <Col className="my-5"></Col>
            </form>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['coursePrice', 'applyFee', 'coursebase']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'addProgramForm',
    validate,
    enableReinitialize: true,
})(FormAddProgram)
