import { Col } from 'react-bootstrap'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

const Pictures = ({ baseInfo }) => {
    return (
        <Col className="my-3">
            <Carousel
                autoPlay={true}
                centerMode={true}
                dynamicHeight={true}
                infiniteLoop={true}
                centerSlidePercentage={50}
            >
                {baseInfo.pictures.map((picture) => (
                    <div key={picture.id}>
                        <img
                            src={picture.picLink}
                            alt="carousePic"
                            // style={{ objectFit: 'cover' }}
                        />
                        {/* <p className="legend">{picture?.name}</p> */}
                    </div>
                ))}
            </Carousel>
        </Col>
    )
}

export default Pictures
