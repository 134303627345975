import { useState, useEffect } from 'react'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import {
    createCourseBase,
    updateCourseBase,
} from '../../store/actions/courseBaseActions'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import history from '../../history'
import FormCouseBasePictures from './FormCouseBasePictures'
import { GET_ONE_COURSEBASE, SHOW_ADD_COURSELEVEL } from '../../store/types'
import { InputCourseBase } from '../InputCourseBase'
import { PlusCircleDotted } from 'react-bootstrap-icons'

const FormCourseBase = (props) => {
    const dispatch = useDispatch()

    const {
        handleSubmit,
        editType,
        stateDescription,
        statePictures,
        courseBaseId,
        courseLevels,
    } = props

    const [description, setDescription] = useState(() => stateDescription)
    const [pictures, setPictures] = useState(() => statePictures)

    useEffect(() => {
        setPictures(statePictures)
    }, [statePictures])

    const submitForm = (formValue) => {
        formValue['description'] = description
        if (editType === EDIT_TYPE) {
            dispatch(updateCourseBase(formValue))
        } else if (editType === ADD_NEW_TYPE) {
            formValue['pictures'] = pictures
            dispatch(createCourseBase(formValue))
        }
    }

    return (
        <Container>
            <form className="form-signin" onSubmit={handleSubmit(submitForm)}>
                <Col>
                    <h3 className="text-center">
                        Program Base{' '}
                        {editType === EDIT_TYPE ? 'Edit' : 'Add New'}
                    </h3>
                </Col>
                <FormCouseBasePictures
                    pictures={pictures}
                    setPictures={setPictures}
                    editType={editType}
                    courseBaseId={courseBaseId}
                    dispatch={dispatch}
                />
                <Col className="py-3">
                    <Row>
                        <Col xs={10}>
                            <label htmlFor="category">Level</label>
                            <Field
                                name="courselevel"
                                className="form-control"
                                component="select"
                            >
                                {courseLevels &&
                                    courseLevels.map((item, index) => (
                                        <option key={index} value={item.id}>
                                            {item.level}
                                        </option>
                                    ))}
                            </Field>
                        </Col>
                        <Col xs={2}>
                            <label htmlFor="edit">Edit</label>
                            <p
                                onClick={() =>
                                    dispatch({
                                        type: SHOW_ADD_COURSELEVEL,
                                        payload: true,
                                    })
                                }
                            >
                                <PlusCircleDotted
                                    size={24}
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </p>
                        </Col>
                    </Row>
                </Col>
                <InputCourseBase />

                <Col className="my-3">
                    <label>Description</label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={stateDescription}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setDescription(data)
                        }}
                    />
                </Col>

                <Col className="py-2">
                    <Button type="submit" variant="info" className="mr-3">
                        {editType === EDIT_TYPE ? 'Update' : 'Submit'}
                    </Button>
                    <Button
                        variant="warning"
                        onClick={() => {
                            dispatch({
                                type: GET_ONE_COURSEBASE,
                                payload: null,
                            })
                            history.goBack()
                        }}
                    >
                        Back
                    </Button>
                </Col>
                <Col className="my-5"></Col>
            </form>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['name', 'logo', 'info']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'courseBaseForm',
    validate,
    enableReinitialize: true,
})(FormCourseBase)
