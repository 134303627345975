import {
    EDIT_SCHOOL_COURSE,
    EDIT_SCHOOL_COURSE_SEMESTER,
    GET_ALL_SCHOOLS,
    GET_ONE_SCHOOL,
    GET_ONE_SCHOOL_PROGRAM,
    SHOW_ADD_SCHOOL_COURSE,
    SHOW_ADD_SCHOOL_COURSE_SEMESTER,
    SHOW_LOADING,
} from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import history from '../../history'

export const getAllSchools = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`school/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_SCHOOLS,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneSchool = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`school/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_SCHOOL,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneSchoolForCourse = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`school/listoneforcourse/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_SCHOOL,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createSchool = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`school/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllSchools())
        history.push('/school')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateSchool = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`school/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({ type: GET_ONE_SCHOOL, payload: null })
        dispatch(getAllSchools())
        history.push('/school')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const statusChangeSchool = (id, value) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        const values = {
            status: value,
        }
        let res = await request.put(`school/statuschange/${id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllSchools())
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createSchoolPic = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`school/createpic`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneSchool(values.schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteSchoolPic = (id, schoolId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`school/deletepic/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneSchool(schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createSchoolCourse = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`school/createschoolcourse`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE,
            payload: {
                schoolCourse: null,
                editType: null,
            },
        })
        dispatch(getOneSchoolForCourse(values.schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateSchoolCourse = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(
            `school/updateschoolcourse/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE,
            payload: {
                schoolCourse: null,
                editType: null,
            },
        })
        dispatch(getOneSchoolForCourse(values.schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteSchoolCourse = (id, schoolId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`school/deleteschoolcourse/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneSchoolForCourse(schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createSchoolCourseSemester = (values, schoolId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(
            `school/createschoolcoursesemester`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE_SEMESTER,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE_SEMESTER,
            payload: {
                schoolCourseSemester: null,
                schoolCourseId: null,
                editType: null,
            },
        })
        dispatch(getOneSchoolForCourse(schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateSchoolCourseSemester = (values, schoolId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(
            `school/updateschoolcoursesemester/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE_SEMESTER,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE_SEMESTER,
            payload: {
                schoolCourseSemester: null,
                schoolCourseId: null,
                editType: null,
            },
        })
        dispatch(getOneSchoolForCourse(schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteSchoolCourseSemester = (id, schoolId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(
            `school/deleteschoolcoursesemester/${id}`,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneSchoolForCourse(schoolId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneSchoolProgram = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`school/schoolcourse/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_SCHOOL_PROGRAM,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
