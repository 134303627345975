import { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { EDIT_TYPE } from '../../constants/editType'
import history from '../../history'
import {
    createSchoolPic,
    deleteSchoolPic,
} from '../../store/actions/schoolActions'
import PictureAddBase from '../PictureAddBase'

const FormSchoolPictures = ({
    pictures,
    setPictures,
    editType,
    schoolId,
    dispatch,
}) => {
    const [name, setName] = useState('')
    const [picLink, setPicLink] = useState('')
    useEffect(() => {
        if (!pictures) {
            history.push(`/school`)
        }
    }, [pictures])

    const handleAddPicture = () => {
        if (picLink) {
            const values = {
                name,
                picLink,
                schoolId,
            }
            if (editType === EDIT_TYPE) {
                dispatch(createSchoolPic(values))
                setName('')
                setPicLink('')
            } else {
                setPictures(() => [...pictures, values])
                setName('')
                setPicLink('')
            }
        }
    }

    const handlePictureDelete = (deletePic) => {
        if (editType === EDIT_TYPE) {
            dispatch(deleteSchoolPic(deletePic, schoolId))
        } else {
            setPictures(() =>
                pictures.filter((pic) => pic.picLink !== deletePic)
            )
        }
    }

    return (
        <Col>
            <PictureAddBase
                name={name}
                picLink={picLink}
                setName={setName}
                setPicLink={setPicLink}
                handleAddPicture={handleAddPicture}
                pictures={pictures}
                editType={editType}
                handlePictureDelete={handlePictureDelete}
            />
        </Col>
    )
}

export default FormSchoolPictures
