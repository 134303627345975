import { useEffect } from 'react'
import { Col, Container, Row, Button, Table } from 'react-bootstrap'
import Layout from '../components/Layout'
import { reduxForm, Field, reset } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { renderInput } from '../components/inputForm'
import {
    addMember,
    commissionAccess,
    deleteOneMember,
    editMember,
    getOneAssociate,
} from '../store/actions/associateActions'
import { USER_EMPLOYEE, AGENT } from '../constants/userRole'
import { resendEmail } from '../store/actions/userActions'

const AssociateControl = (props) => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const associateState = useSelector((state) => state.associate)
    const { associate } = associateState

    useEffect(() => {
        if (userInfo && userInfo.associate) {
            dispatch(getOneAssociate(userInfo.associate.id))
        }
    }, [dispatch, userInfo])

    const submitForm = (formValues) => {
        formValues['associateId'] = userInfo?.associate?.id
        formValues['address'] = ''
        console.log(formValues)
        dispatch(addMember(formValues))
        dispatch(reset('associateAddMemberForm'))
    }

    const handleUserCommission = (e, userId) => {
        const values = {
            userId,
            commissionAccess: e.target.checked,
        }
        dispatch(commissionAccess(associate.id, values))
    }

    const { handleSubmit } = props
    return (
        <Layout>
            {associate && (
                <Container>
                    <Col>
                        <h1 className="text-center">{associate.name}</h1>
                    </Col>
                    <Col>
                        <h3>Invite Staff Members</h3>
                        <p>Maximum 10 staff accounts</p>
                        <Col>
                            <form
                                className="form-signin"
                                onSubmit={handleSubmit(submitForm)}
                            >
                                <Row>
                                    <Col xs={3}>
                                        <Field
                                            name="firstName"
                                            type="text"
                                            label="First Name"
                                            component={renderInput}
                                        ></Field>
                                    </Col>
                                    <Col xs={3}>
                                        <Field
                                            name="lastName"
                                            type="text"
                                            label="Last Name"
                                            component={renderInput}
                                        ></Field>
                                    </Col>
                                    <Col xs={3}>
                                        <Field
                                            name="email"
                                            type="email"
                                            label="Email"
                                            component={renderInput}
                                        ></Field>
                                    </Col>
                                    <Col
                                        xs={3}
                                        className="d-flex align-items-end"
                                    >
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="btn-block py-2 mt-4"
                                            style={{
                                                borderRadius: '10px',
                                            }}
                                        >
                                            INVITE
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Col>
                    {associate.users.length > 0 &&
                        associate.users.filter((user) => user.verify === false)
                            .length > 0 && (
                            <>
                                {' '}
                                <hr />
                                <Col>
                                    <h3>Invited Staff Members</h3>
                                    <Table responsive striped hover>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {associate.users
                                                .filter(
                                                    (userV) =>
                                                        userV.verify === false
                                                )
                                                .map((user) => (
                                                    <tr key={user.id}>
                                                        <td>
                                                            {user.firstName}
                                                        </td>
                                                        <td>{user.lastName}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            <span
                                                                style={{
                                                                    background:
                                                                        '#F5B7B1',
                                                                    padding:
                                                                        '5px 10px',
                                                                    borderRadius:
                                                                        '20px',
                                                                }}
                                                            >
                                                                Not Approved
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                style={{
                                                                    borderRadius:
                                                                        '10px',
                                                                }}
                                                                onClick={() =>
                                                                    dispatch(
                                                                        resendEmail(
                                                                            user.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                Resend
                                                            </Button>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                style={{
                                                                    borderRadius:
                                                                        '10px',
                                                                    marginLeft:
                                                                        '10px',
                                                                }}
                                                                onClick={() => {
                                                                    if (
                                                                        window.confirm(
                                                                            'Are you sure?'
                                                                        )
                                                                    ) {
                                                                        dispatch(
                                                                            deleteOneMember(
                                                                                associate.id,
                                                                                user.id
                                                                            )
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </>
                        )}
                    {associate.users.length > 0 &&
                        associate.users.filter((user) => user.verify === true)
                            .length > 0 && (
                            <>
                                {' '}
                                <hr />
                                <Col>
                                    <h3>Approved Staff Members</h3>
                                    <Table responsive striped hover>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Student</th>
                                                <th>Apply</th>
                                                <th>Commission</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {associate.users
                                                .filter(
                                                    (userV) =>
                                                        userV.verify === true
                                                )
                                                .map((user) => (
                                                    <tr key={user.id}>
                                                        <td>
                                                            {user.firstName}
                                                        </td>
                                                        <td>{user.lastName}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            {
                                                                user?.students
                                                                    ?.length
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                user?.applies
                                                                    ?.length
                                                            }
                                                        </td>
                                                        <td>
                                                            {user.role ===
                                                            AGENT ? (
                                                                <input
                                                                    defaultChecked={
                                                                        user?.commissionAccess
                                                                    }
                                                                    type="checkbox"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleUserCommission(
                                                                            e,
                                                                            user.id
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                'Yes'
                                                            )}
                                                        </td>
                                                        <td>
                                                            {user.isActive ? (
                                                                <span
                                                                    style={{
                                                                        background:
                                                                            '#58D68D',
                                                                        padding:
                                                                            '5px 10px',
                                                                        borderRadius:
                                                                            '20px',
                                                                    }}
                                                                >
                                                                    Active
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        background:
                                                                            '#F1948A',
                                                                        padding:
                                                                            '5px 10px',
                                                                        borderRadius:
                                                                            '20px',
                                                                    }}
                                                                >
                                                                    Inactive
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {(user.role ===
                                                                USER_EMPLOYEE ||
                                                                user.role ===
                                                                    AGENT) && (
                                                                <>
                                                                    <Button
                                                                        size="sm"
                                                                        variant={
                                                                            user.isActive ===
                                                                            true
                                                                                ? 'warning'
                                                                                : 'primary'
                                                                        }
                                                                        style={{
                                                                            borderRadius:
                                                                                '10px',
                                                                        }}
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                editMember(
                                                                                    associate.id,
                                                                                    {
                                                                                        id: user.id,
                                                                                        isActive:
                                                                                            !user.isActive,
                                                                                    }
                                                                                )
                                                                            )
                                                                        }}
                                                                    >
                                                                        {user.isActive ===
                                                                        true
                                                                            ? 'DEACTIVATE'
                                                                            : 'ACTIVE'}
                                                                    </Button>
                                                                    {user
                                                                        ?.students
                                                                        ?.length ===
                                                                        0 &&
                                                                        user
                                                                            ?.applies
                                                                            ?.length ===
                                                                            0 &&
                                                                        user.isActive ===
                                                                            false && (
                                                                            <Button
                                                                                size="sm"
                                                                                variant="danger"
                                                                                style={{
                                                                                    borderRadius:
                                                                                        '10px',
                                                                                    marginLeft:
                                                                                        '10px',
                                                                                }}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        window.confirm(
                                                                                            'Are you sure?'
                                                                                        )
                                                                                    ) {
                                                                                        dispatch(
                                                                                            deleteOneMember(
                                                                                                associate.id,
                                                                                                user.id
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            >
                                                                                DELETE
                                                                            </Button>
                                                                        )}
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </>
                        )}
                </Container>
            )}
        </Layout>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'firstName', 'lastName']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'associateAddMemberForm',
    validate,
})(AssociateControl)
