import { Button } from 'react-bootstrap'
import { Search } from 'react-bootstrap-icons'

export const englishSearchButtonSm = (
    <Button
        size="sm"
        type="submit"
        variant="outline-success"
        className="p-2"
        style={{ borderRadius: '10px' }}
    >
        Search <Search />
    </Button>
)

export const frenchSearchButtonSm = (
    <Button
        size="sm"
        type="submit"
        variant="outline-success"
        className="p-2"
        style={{ borderRadius: '10px', width: '100px' }}
    >
        Search <Search />
    </Button>
)
