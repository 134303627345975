import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { languageList, semesterStatusList } from '../../constants/language'
import {
    SEARCH_BOX,
    SEARCH_COURSE,
    SEARCH_LANGUAGE,
    SEARCH_LEVEL,
    SEARCH_STATUS,
} from '../../constants/schoolCourse'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import { getAllSemesters } from '../../store/actions/semesterActions'
import { SEARCH_SEMESTER } from '../../store/types'

const SemesterSearch = ({
    displaySearchSchool,
    displaySearchCourse,
    displaySearchLevel,
    displaySearchLanguage,
    displaySearchStatus,
    courseLevels,
}) => {
    const dispatch = useDispatch()
    const [searchSchool, setSearchSchool] = useState('')
    const [searchCourse, setSearchCourse] = useState('')
    const [searchLevel, setSearchLevel] = useState('')
    const [searchLanguage, setSearchLanguage] = useState('')
    const [searchStatus, setSearchStatus] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_SEMESTER,
            payload: {
                searchSchool,
                searchCourse,
                searchLevel,
                searchLanguage,
                searchStatus,
            },
        })
    }

    useEffect(() => {
        setSearchSchool(displaySearchSchool ?? '')
        setSearchCourse(displaySearchCourse ?? '')
        setSearchLevel(displaySearchLevel ?? '')
        setSearchLanguage(displaySearchLanguage ?? '')
        setSearchStatus(displaySearchStatus ?? '')
    }, [
        displaySearchSchool,
        displaySearchCourse,
        displaySearchLevel,
        displaySearchLanguage,
        displaySearchStatus,
    ])
    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchSchool"
                            value={searchSchool}
                            onChange={(e) => setSearchSchool(e.target.value)}
                            placeholder={SEARCH_BOX}
                        ></Form.Control>
                    </Col>
                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchCourse"
                            value={searchCourse}
                            onChange={(e) => setSearchCourse(e.target.value)}
                            placeholder={SEARCH_COURSE}
                        ></Form.Control>
                    </Col>

                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLanguage"
                            // defaultValue={displayStatus}
                            value={searchLanguage}
                            onChange={(e) => setSearchLanguage(e.target.value)}
                        >
                            <option value="">{SEARCH_LANGUAGE}</option>
                            {languageList.map((language) => (
                                <option value={language} key={language}>
                                    {language}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={6} lg={3} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchStatus"
                            // defaultValue={displayStatus}
                            value={searchStatus}
                            onChange={(e) => setSearchStatus(e.target.value)}
                        >
                            <option value="">{SEARCH_STATUS}</option>
                            {semesterStatusList.map((status) => (
                                <option value={status.value} key={status.value}>
                                    {status.key}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={6} lg={8} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLevel"
                            // defaultValue={displayStatus}
                            value={searchLevel}
                            onChange={(e) => setSearchLevel(e.target.value)}
                        >
                            <option value="">{SEARCH_LEVEL}</option>
                            {courseLevels.map((level) => (
                                <option value={level.level} key={level.level}>
                                    {level.level}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={6} lg={4} className="my-2">
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            {frenchSearchButtonSm}{' '}
                            <Button
                                size="sm"
                                variant="outline-warning"
                                className="p-2"
                                style={{
                                    borderRadius: '10px',
                                    width: '100px',
                                    marginLeft: '20px',
                                }}
                                onClick={() => {
                                    dispatch(getAllSemesters())
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export default SemesterSearch
