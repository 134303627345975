import React from 'react'
import { Link } from 'react-router-dom'
import {
    CheckCircleFill,
    XCircleFill,
    PencilSquare,
} from 'react-bootstrap-icons'
import moment from 'moment'

const AssociateList = ({ associate }) => {
    return (
        <tr>
            <td>{associate.name}</td>
            <td>
                {associate.firstName} {associate.lastName}
            </td>
            <td>{associate.phone}</td>
            <td>{associate.email}</td>
            <td style={{ textAlign: 'center' }}>
                {associate.emailVerify === true ? (
                    <CheckCircleFill size={25} color="green" />
                ) : (
                    <XCircleFill size={25} color="red" />
                )}
            </td>
            <td style={{ textAlign: 'center' }}>
                {associate.isActive === true ? (
                    <CheckCircleFill size={25} color="green" />
                ) : (
                    <XCircleFill size={25} color="red" />
                )}
            </td>
            <td>{associate.level}</td>
            <td>{moment(associate.createdAt).format('ll')}</td>
            <td>
                {associate.users.reduce(
                    (total, user) => total + user?.students?.length,
                    0
                )}
            </td>
            <td>
                {' '}
                {associate.users.reduce(
                    (total, user) => total + user?.applies?.length,
                    0
                )}
            </td>
            <td>
                <div className="d-flex justify-content-between">
                    <Link to={`/adminassociate/${associate.id}`}>
                        {/* <img
                            src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615915229/samples/edit_avjmbi.png"
                            alt="edit"
                            width="30px"
                        /> */}
                        <PencilSquare size={25} color="blue" />
                    </Link>
                </div>
            </td>
        </tr>
    )
}

export default AssociateList
