import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { languageList } from '../../constants/language'
import {
    SEARCH_BOX,
    SEARCH_COURSE,
    SEARCH_LANGUAGE,
    SEARCH_LEVEL,
} from '../../constants/schoolCourse'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import {
    getAllProgrammes,
    getAllProgramNames,
} from '../../store/actions/programmeActions'
import { SEARCH_PROGRAMME } from '../../store/types'

const ProgrammeSearch = ({
    displaySearchName,
    displaySearchCourse,
    displaySearchLevel,
    displaySearchLanguage,
    courseLevels,
}) => {
    const dispatch = useDispatch()
    const [searchName, setSearchName] = useState('')
    const [searchCourse, setSearchCourse] = useState('')
    const [searchLevel, setSearchLevel] = useState('')
    const [searchLanguage, setSearchLanguage] = useState('')

    const programmeState = useSelector((state) => state.programme)
    const { programNames } = programmeState

    useEffect(() => {
        if (!programNames) {
            dispatch(getAllProgramNames())
        }
    }, [dispatch, programNames])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_PROGRAMME,
            payload: { searchName, searchCourse, searchLevel, searchLanguage },
        })
    }

    useEffect(() => {
        setSearchName(displaySearchName ?? '')
        setSearchCourse(displaySearchCourse ?? '')
        setSearchLevel(displaySearchLevel ?? '')
        setSearchLanguage(displaySearchLanguage ?? '')
    }, [
        displaySearchName,
        displaySearchCourse,
        displaySearchLevel,
        displaySearchLanguage,
    ])
    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={6} lg={4} className="my-2">
                        <Form.Control
                            type="text"
                            name="searchName"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            placeholder={SEARCH_BOX}
                        ></Form.Control>
                    </Col>
                    <Col xs={6} lg={4} className="my-2">
                        <input
                            list="name_list"
                            type="text"
                            className="form-control"
                            value={searchCourse}
                            name="searchCourse"
                            onChange={(e) => setSearchCourse(e.target.value)}
                            placeholder={SEARCH_COURSE}
                        />
                        <datalist id="name_list">
                            {programNames?.map((list, index) => (
                                <option key={index} value={list.name} />
                            ))}
                        </datalist>
                    </Col>

                    <Col xs={12} lg={4} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLanguage"
                            // defaultValue={displayStatus}
                            value={searchLanguage}
                            onChange={(e) => setSearchLanguage(e.target.value)}
                        >
                            <option value="">{SEARCH_LANGUAGE}</option>
                            {languageList.map((language) => (
                                <option value={language} key={language}>
                                    {language}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={12} lg={8} className="my-2">
                        <Form.Control
                            as="select"
                            name="searchLevel"
                            // defaultValue={displayStatus}
                            value={searchLevel}
                            onChange={(e) => setSearchLevel(e.target.value)}
                        >
                            <option value="">{SEARCH_LEVEL}</option>
                            {courseLevels.map((level) => (
                                <option value={level.level} key={level.level}>
                                    {level.level}
                                </option>
                            ))}
                        </Form.Control>
                    </Col>
                    <Col xs={12} lg={4} className="my-2">
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            {frenchSearchButtonSm}
                            <Button
                                size="sm"
                                variant="outline-warning"
                                className="p-2"
                                style={{
                                    borderRadius: '10px',
                                    width: '100px',
                                    marginLeft: '20px',
                                }}
                                onClick={() => {
                                    dispatch(getAllProgrammes())
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export default ProgrammeSearch
