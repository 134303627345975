import { useEffect } from 'react'
import { Button, Col, Container, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_NEW_TYPE } from '../constants/editType'
import { getAllCourseBases } from '../store/actions/courseBaseActions'
import CourseBaseList from '../components/courseBase/CourseBaseList'
import { GET_ONE_COURSEBASE } from '../store/types'

const CourseBase = ({ match, history }) => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseBases } = courseBaseState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getAllCourseBases())
        }
    }, [dispatch, userInfo, history])
    return (
        <Container>
            <Col className="my-3">
                <Button
                    variant="outline-info"
                    onClick={() => {
                        dispatch({ type: GET_ONE_COURSEBASE, payload: null })
                        history.push(`/editcoursebase/${ADD_NEW_TYPE}/0`)
                    }}
                >
                    Add New Program Base
                </Button>
            </Col>
            <Col>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Level</th>
                            <th>Info</th>
                            <th style={{ width: '150px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseBases &&
                            courseBases.map((courseBase) => (
                                <CourseBaseList
                                    key={courseBase.id}
                                    courseBase={courseBase}
                                    dispatch={dispatch}
                                />
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Container>
    )
}

export default CourseBase
