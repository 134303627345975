import request from '../../config/request'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import { createMessage } from './messageActions'
import { GET_ALL_ASSOCIATES, GET_ONE_ASSOCIATE, SHOW_LOADING } from '../types'
import history from '../../history'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'

export const getAllAssociates =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.get(
                `associate/?keyword=${keyword}&pageNumber=${pageNumber}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            newToken(res)
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_ASSOCIATES,
                payload: {
                    associates: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword: keyword,
                },
            })
            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const getOneAssociate = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`associate/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_ASSOCIATE,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const associateRegister = (registerData) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const res = await request.post('/associate', registerData, config)
        if (res.data.code === 201) {
            dispatch(
                createMessage({
                    registerOK: 'Register successful, please verify your email',
                })
            )
            history.push('/login')
            // window.location.href = '/login'
        } else {
            const errors = {
                msg: res.data.msg,
                status: 401,
            }
            getErrors(errors)
        }
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (error) {
        errorsReturn(error, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const confirmAssociateEmail = (token) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })

        await request.get(`associate/confirmation/${token}`)
        dispatch(
            createMessage({ verifyOk: 'Email verified, waiting for active' })
        )
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const resendAssociateEmail = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`associate/resendemail/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(createMessage({ emailSentOk: 'Email sent' }))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteOneAssociate = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`associate/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllAssociates())
        history.push('/associates')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateOneAssociate =
    (id, values) => async (dispatch, getState) => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true,
            })
            const {
                userLogin: { userToken },
            } = getState()
            let res = await request.put(`associate/${id}`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            newToken(res)
            dispatch(renewUserToken(res))

            dispatch({
                type: SHOW_LOADING,
                payload: false,
            })
            dispatch(
                createMessage({ updateConfig: 'Associate updated successful' })
            )
            dispatch(getOneAssociate(id))
        } catch (e) {
            errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
        }
    }

export const addMember = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`associate/addmember`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneAssociate(values.associateId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const editMember = (id, values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`user/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneAssociate(id))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteOneMember = (id, userId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`user/${userId}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneAssociate(id))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const commissionAccess = (id, values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(
            `user/usercommission/${values.userId}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneAssociate(id))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
