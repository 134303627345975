import { Button, Col, Row } from 'react-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { DISPLAY_LEARN_MORE } from '../../constants/studentInput'

const SchoolList = ({ school }) => {
    return (
        <Row className="shadow my-5 rounded border">
            <Col xs={12} lg={4} className="header-picture">
                {school.logo && (
                    <img
                        src={school.logo}
                        alt="logo"
                        style={{
                            objectFit: 'cover',
                        }}
                        className="img-fluid"
                    />
                )}
            </Col>
            <Col xs={12} lg={8}>
                <Col className="py-3">
                    <h2>
                        <strong>{school.name}</strong>
                    </h2>
                    <p style={{ whiteSpace: 'pre-line' }}>{school.info}</p>
                    <Link to={`/etablissement/${school.id}`}>
                        {' '}
                        <Button
                            variant="primary"
                            style={{
                                borderRadius: '10px',
                                marginRight: 'auto',
                            }}
                        >
                            {DISPLAY_LEARN_MORE}{' '}
                            <ArrowRight size={18} className="mx-1" />
                        </Button>
                    </Link>
                </Col>
            </Col>
        </Row>
    )
}

export default SchoolList
