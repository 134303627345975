import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { USER_ADMIN, USER_EMPLOYEE } from '../../constants/userRole'
import history from '../../history'

const NewApplyNote = ({ applynotes, id, short = false }) => {
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const display = useMemo(() => {
        const noteType =
            userInfo?.role === USER_ADMIN || userInfo?.role === USER_EMPLOYEE
                ? 2
                : 1
        if (
            applynotes
                .filter((note) => !note.confirm)
                .some((a) => a.noteType !== noteType)
        ) {
            return (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '20px',
                    }}
                >
                    <snap
                        style={{
                            backgroundColor: 'green',
                            borderRadius: '10px',
                            padding: '10px',
                            color: 'white',
                            cursor: 'pointer',
                        }}
                        onClick={() => history.push(`/application/${id}`)}
                    >
                        {short ? 'Note' : '>> You have new note <<'}
                    </snap>
                </div>
            )
        }
        return null
    }, [applynotes, short, userInfo, id])
    return display
}

export default NewApplyNote
