import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Col, Button } from 'react-bootstrap'
import history from '../history'
import Description from '../components/base/Description'
import Header from '../components/base/Header'
import { getOneSchoolForCourse } from '../store/actions/schoolActions'
import Pictures from '../components/base/Pictures'
import AddProgrom from '../components/school/AddProgrom'
import EditAddProgram from '../components/school/EditAddProgram'
import Loader from '../components/Loader'
import EditSchoolCourseSemester from '../components/school/EditSchoolCourseSemester'

const AddCourseForSchool = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const schoolState = useSelector((state) => state.school)
    const { school, showAddCourse, showAddCourseSemester } = schoolState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneSchoolForCourse(id))
        }
    }, [dispatch, userInfo, id])

    return school ? (
        <Container>
            {showAddCourse && <EditAddProgram />}
            {showAddCourseSemester && <EditSchoolCourseSemester />}
            <Col className="py-3">
                <Button
                    style={{ width: '100px', borderRadius: '15px' }}
                    variant="outline-info"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </Col>
            <Header baseInfo={school} />
            {school?.pictures && school?.pictures?.length > 0 && (
                <Pictures baseInfo={school} />
            )}

            <Col>
                <Description description={school?.description} />
            </Col>
            <Col>
                <AddProgrom school={school} dispatch={dispatch} />
            </Col>
        </Container>
    ) : (
        <Loader />
    )
}

export default AddCourseForSchool
