import {
    CHANGE_PROGRAMME_PAGE,
    GET_ALL_PROGRAMMENAMES,
    GET_ALL_PROGRAMMES,
    SEARCH_PROGRAMME,
} from '../types'

const initialState = {
    programmes: [],
    programme: {},
    programNames: null,
    searchName: '',
    searchCourse: '',
    searchLevel: '',
    searchLanguage: '',
    pages: null,
    page: null,
    count: 0,
}

const programmeReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PROGRAMMES:
            return {
                ...state,
                programmes: payload.programmes,
                searchName: payload.searchName,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case SEARCH_PROGRAMME:
            return {
                ...state,
                searchName: payload.searchName,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                page: 1,
            }
        case CHANGE_PROGRAMME_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ALL_PROGRAMMENAMES:
            return {
                ...state,
                programNames: payload,
            }
        default:
            return state
    }
}

export default programmeReducer
