export const COURSE_PRICE = 'Program Price'
export const APPLY_FEE = 'Apply Fee'
export const COMMISSION = 'Commission'

export const COURSE_DURATION = 'Program Duration'
export const COURSE_DESCRIPTION = 'Program Description'

export const COURSE_LANGUAGE = 'Language'
export const COURSE_SEMESTER = 'Start Date'
export const COURSE_SEMESTER_REMARK = 'Remark'

export const BUTTON_APPLY = 'Apply'
export const SCHOOL_INFO_LINK = 'School info'

export const COURSE_LEVEL = 'Level'
export const SEARCH_BOX = 'School...'
export const SEARCH_COURSE = 'Program...'
export const SEARCH_LEVEL = '--Level--'
export const SEARCH_LANGUAGE = '--Language--'
export const DISPLAY_TOTAL = 'Total'
export const SEARCH_STUDENT_BOX = 'Student...'
export const SEARCH_USER_SELECT = '--User--'
export const SEARCH_STATUS = '--Status--'
