import { XSquare } from 'react-bootstrap-icons'

const CloseIcon = ({ closeFn }) => {
    return (
        <div className="text-right mr-3">
            <XSquare
                onClick={closeFn}
                style={{ cursor: 'pointer' }}
                size={26}
            />
        </div>
    )
}

export default CloseIcon
