import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import {
    getAllCourseLevels,
    getOneCourseBase,
} from '../store/actions/courseBaseActions'
import FormCourseBase from '../components/courseBase/FormCourseBase'
import { EDIT_TYPE } from '../constants/editType'
import EditCourseLevel from '../components/courseBase/EditCourseLevel'

const EditCourseBase = ({ match, history }) => {
    const type = match.params.type
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseBase, courseLevels, showAddCourseLevel } = courseBaseState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            if (type === EDIT_TYPE) {
                dispatch(getOneCourseBase(id))
            }
            dispatch(getAllCourseLevels())
        }
    }, [dispatch, userInfo, id, history, type])

    const initCourseBase =
        type === EDIT_TYPE
            ? {
                  ...courseBase,
                  courselevel: courseBase?.courselevel?.id,
              }
            : { courselevel: courseLevels[0]?.id }

    const stateDescription = courseBase ? courseBase.description : ''
    const statePictures = courseBase ? courseBase.pictures : []
    const editType = type
    const courseBaseId = courseBase ? courseBase.id : ''
    return (
        <Container>
            {showAddCourseLevel && <EditCourseLevel />}
            <FormCourseBase
                initialValues={initCourseBase}
                stateDescription={stateDescription}
                editType={editType}
                statePictures={statePictures}
                courseBaseId={courseBaseId}
                courseLevels={courseLevels}
            />
        </Container>
    )
}

export default EditCourseBase
