import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { EDIT_TYPE } from '../../constants/editType'
import { STATUS_DELETE, STATUS_ONE } from '../../constants/status'
import { statusChangeCourseBase } from '../../store/actions/courseBaseActions'

const CourseBaseList = ({ courseBase, dispatch }) => {
    return (
        <tr
            style={
                courseBase.status === STATUS_DELETE
                    ? { color: 'red', textDecoration: 'line-through' }
                    : { textDecoration: 'none' }
            }
        >
            <td>
                {courseBase.logo && (
                    <img
                        src={courseBase.logo}
                        alt="logo"
                        width="100px"
                        height="50px"
                        style={{ objectFit: 'cover' }}
                    />
                )}
            </td>
            <td>{courseBase.name}</td>
            <td>{courseBase.courselevel.level}</td>
            <td>{courseBase.info}</td>
            <td>
                {courseBase.status > STATUS_DELETE ? (
                    <>
                        <Link
                            to={`/editcoursebase/${EDIT_TYPE}/${courseBase.id}`}
                        >
                            <Button
                                size="sm"
                                variant="outline-info"
                                className="mr-3"
                            >
                                Edit
                            </Button>
                        </Link>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                                if (window.confirm('Are you sure?')) {
                                    dispatch(
                                        statusChangeCourseBase(
                                            courseBase.id,
                                            STATUS_DELETE
                                        )
                                    )
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </>
                ) : (
                    <Button
                        variant="outline-warning"
                        size="sm"
                        onClick={() => {
                            if (window.confirm('Are you sure?')) {
                                dispatch(
                                    statusChangeCourseBase(
                                        courseBase.id,
                                        STATUS_ONE
                                    )
                                )
                            }
                        }}
                    >
                        Restore
                    </Button>
                )}
            </td>
        </tr>
    )
}

export default CourseBaseList
