import { GET_MESSAGES, CREATE_MESSAGE } from '../types'

const initialState = {}

export const messageReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_MESSAGES:
            return (state = payload)
        case CREATE_MESSAGE:
            return (state = payload)
        default:
            return state
    }
}
