import React, { Component } from 'react'
import { withAlert } from 'react-alert'
import { connect } from 'react-redux'

class Alert extends Component {
    componentDidUpdate(prevProps) {
        const { error, alert, message } = this.props
        if (error !== prevProps.error) {
            if (error.msg) alert.error(error.msg)
        }

        if (message !== prevProps.message) {
            if (message.loginOK) alert.success(message.loginOK)
            if (message.registerOK) alert.success(message.registerOK)
            if (message.updateConfig) alert.success(message.updateConfig)
            if (message.editCategory) alert.success(message.editCategory)
            if (message.deleteCategory) alert.success(message.deleteCategory)
            if (message.copyOK) alert.success(message.copyOK)
            if (message.verifyOk) alert.success(message.verifyOk)
            if (message.emailSentOk) alert.success(message.emailSentOk)
        }
    }
    render() {
        return <div></div>
    }
}

const mapStateToProps = (state) => ({
    error: state.errors,
    message: state.messages,
})

const Alertconbin = withAlert()(Alert)

export default connect(mapStateToProps, null)(Alertconbin)
