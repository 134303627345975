import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container } from 'react-bootstrap'
import history from '../history'
// import Paginate from '../components/Paginate'
// import BootstrapPagination from '../components/BootstrapPagination'
import NewReduxPaginate from '../components/NewReduxPaginate'
import { getAllEtablissements } from '../store/actions/etablissementActions'
import Loader from '../components/Loader'
import SearchBox from '../components/etablissement/SearchBox'
import SchoolList from '../components/etablissement/SchoolList'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
import { CHANGE_ETABLISSEMENT_PAGE } from '../store/types'

const Etablissements = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const ietablissementState = useSelector((state) => state.etablissement)
    const { etablissements, searchName, pages, page, count } =
        ietablissementState

    const pageNumber = page || 1
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getAllEtablissements(searchName, pageNumber))
        }
    }, [dispatch, userInfo, searchName, pageNumber])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_ETABLISSEMENT_PAGE, payload: value })
    }

    return etablissements ? (
        <Container>
            <SearchBox displaySearchName={searchName} />
            <Col>
                {DISPLAY_TOTAL}: {count}
            </Col>
            <Col>
                {etablissements.map((school) => (
                    <SchoolList school={school} key={school.id} />
                ))}
            </Col>
            {/* <Paginate area="etablissement" pages={pages} page={page} /> */}
            {/* <BootstrapPagination
                area="etablissement"
                pages={pages}
                page={page}
            /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default Etablissements
