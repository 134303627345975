import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Col, Row } from 'react-bootstrap'
import history from '../history'
import Description from '../components/base/Description'
import Header from '../components/base/Header'
import { getOneSchoolProgram } from '../store/actions/schoolActions'
import Pictures from '../components/base/Pictures'

import Loader from '../components/Loader'
import DisplaySchoolCourse from '../components/school/DisplaySchoolCourse'
import { GET_ONE_SCHOOL_PROGRAM } from '../store/types'
import BackButton from '../components/BackButton'

const SchoolProgram = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const schoolState = useSelector((state) => state.school)
    const { schoolProgram } = schoolState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneSchoolProgram(id))
        }
        return () => dispatch({ type: GET_ONE_SCHOOL_PROGRAM, payload: null })
    }, [dispatch, userInfo, id])

    return schoolProgram ? (
        <Container>
            <BackButton />
            <Header baseInfo={schoolProgram.coursebase} />
            {schoolProgram?.coursebase?.pictures &&
                schoolProgram?.coursebase?.pictures?.length > 0 && (
                    <Pictures baseInfo={schoolProgram.coursebase} />
                )}

            <Row>
                <Col xs={12} lg={4} className="my-3">
                    <DisplaySchoolCourse schoolProgram={schoolProgram} />
                </Col>
                <Col xs={12} lg={8}>
                    <Col>
                        <Description
                            description={schoolProgram?.coursebase?.description}
                        />
                    </Col>
                    <h3 style={{ fontWeight: 'bold' }}>Apply Condition</h3>
                    <Col>
                        <Description
                            description={schoolProgram?.applyCondition}
                        />
                    </Col>
                </Col>
            </Row>

            <Col></Col>
        </Container>
    ) : (
        <Loader />
    )
}

export default SchoolProgram
