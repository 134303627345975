import { useEffect } from 'react'
import { Button, Col, Container, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_NEW_TYPE } from '../constants/editType'
import { GET_ONE_SCHOOL } from '../store/types'
import { getAllSchools } from '../store/actions/schoolActions'
import SchoolList from '../components/school/SchoolList'

const School = ({ history }) => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const schoolState = useSelector((state) => state.school)
    const { schools } = schoolState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getAllSchools())
        }
    }, [dispatch, userInfo, history])
    return (
        <Container>
            <Col className="my-3">
                <Button
                    variant="outline-info"
                    onClick={() => {
                        dispatch({ type: GET_ONE_SCHOOL, payload: null })
                        history.push(`/editschool/${ADD_NEW_TYPE}/0`)
                    }}
                >
                    Add New School
                </Button>
            </Col>
            <Col>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>Logo</th>
                            <th>Name</th>
                            <th>Info</th>
                            <th style={{ width: '150px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {schools &&
                            schools.map((school) => (
                                <SchoolList
                                    key={school.id}
                                    school={school}
                                    dispatch={dispatch}
                                />
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Container>
    )
}

export default School
