import { Col, Row } from 'react-bootstrap'
import { Calendar3, FileWord, JournalBookmark } from 'react-bootstrap-icons'

const DisplaySemester = ({ semester }) => {
    return (
        <Col>
            <Row>
                <Col xs={4}>
                    <Calendar3 /> {semester?.semester}{' '}
                    {semester?.waitingList && '(Waiting List)'}
                </Col>
                <Col xs={4}>
                    <FileWord /> {semester?.language}
                </Col>
                <Col xs={4}>
                    <JournalBookmark /> {semester?.remark}
                </Col>
            </Row>
        </Col>
    )
}

export default DisplaySemester
