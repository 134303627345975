import {
    USER_LOGOUT,
    USER_UPDATE_INFO,
    USER_LOGOIN_SUCCESS,
    USER_UPDATE_TOKEN,
    SHOW_USER_SIGN,
    RENEW_USER_TOKEN,
    GET_ALL_USERS,
    GET_ONE_USER,
    USER_EDIT_CLEAR,
} from '../types'

export const userLoginReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case USER_LOGOIN_SUCCESS:
            return {
                ...state,
                userInfo: payload.userInfo,
                userToken: payload.userToken,
            }
        case USER_UPDATE_INFO:
            return { ...state, userInfo: payload }
        case USER_UPDATE_TOKEN:
            return { ...state, userToken: payload }
        case RENEW_USER_TOKEN:
            return {
                ...state,
                userToken: payload,
            }
        case USER_LOGOUT:
            return {}
        case GET_ALL_USERS:
            return {
                ...state,
                users: payload.users,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                user: null,
            }
        case GET_ONE_USER:
            return {
                ...state,
                user: payload,
            }
        case USER_EDIT_CLEAR:
            return {
                ...state,
                user: null,
            }

        default:
            return state
    }
}

export const userUpdateProfileReducer = (
    state = { showSign: false },
    action
) => {
    const { type, payload } = action
    switch (type) {
        case SHOW_USER_SIGN:
            return {
                ...state,
                showSign: payload,
            }

        default:
            return state
    }
}
