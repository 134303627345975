import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Row, Col } from 'react-bootstrap'
import { renderInput } from '../components/inputForm'
import FormContainer from '../components/FormContainer'
import { register } from '../store/actions/userActions'

const Register = (props) => {
    const dispatch = useDispatch()

    const submitForm = (formValues) => {
        dispatch(register(formValues))
    }

    const { handleSubmit } = props
    return (
        <div>
            <FormContainer>
                <h3>Register</h3>

                <form
                    className="form-signin"
                    onSubmit={handleSubmit(submitForm)}
                >
                    <Field
                        name="username"
                        type="text"
                        label="User Name"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="firstName"
                        type="text"
                        label="First Name"
                        component={renderInput}
                    ></Field>

                    <Field
                        name="lastName"
                        type="text"
                        label="Last Name"
                        component={renderInput}
                    ></Field>

                    <Field
                        name="email"
                        type="email"
                        label="Email"
                        component={renderInput}
                    ></Field>

                    <Field
                        name="password"
                        type="password"
                        label="Password"
                        component={renderInput}
                    ></Field>
                    <Field
                        name="rpassword"
                        type="password"
                        label="Confirm Password"
                        component={renderInput}
                    ></Field>

                    <Button
                        type="submit"
                        variant="primary"
                        className="btn-block py-2 mt-4"
                    >
                        Submit
                    </Button>
                </form>
                <Row className="py-3">
                    <Col>
                        Have an Account? <Link to="/login">Login</Link>
                    </Col>
                </Row>
            </FormContainer>
        </div>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'username',
        'firstName',
        'lastName',
        'email',
        'password',
        'rpassword',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.password !== values.rpassword) {
        errors.rpassword = 'Not match'
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }
    return errors
}

export default reduxForm({
    form: 'userForm',
    validate,
})(Register)
