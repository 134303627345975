import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Col, Row, Table, Button } from 'react-bootstrap'
import history from '../history'
import Loader from '../components/Loader'
import { getOneStudent } from '../store/actions/studentActions'
import DisplayStudent from '../components/student/DisplayStudent'
import DisplayUpload from '../components/student/DisplayUpload'
import { GET_ONE_STUDENT } from '../store/types'
import BackButton from '../components/BackButton'
import { Link } from 'react-router-dom'
import { BUTTON_INFO } from '../constants/studentInput'

const StudentInfo = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const studentState = useSelector((state) => state.student)
    const { student } = studentState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneStudent(id))
        }
        return () => dispatch({ type: GET_ONE_STUDENT, payload: null })
    }, [dispatch, userInfo, id])

    return student ? (
        <Container>
            <BackButton />
            <DisplayStudent student={student} />
            <hr />
            <Row>
                {student.studentuploads &&
                    student.studentuploads.length > 0 &&
                    student.studentuploads.map((upload) => (
                        <DisplayUpload
                            upload={upload}
                            dispatch={dispatch}
                            key={upload.id}
                        />
                    ))}
            </Row>
            {student?.applies?.length > 0 && (
                <Col>
                    <hr />
                    <h3>Applications</h3>
                    <Table responsive striped hover>
                        <thead>
                            <tr>
                                <th>School</th>
                                <th>Course</th>
                                <th width="100px">Start Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {student.applies.map((apply) => (
                                <tr key={apply.id}>
                                    <td>
                                        {
                                            apply?.schoolcoursesemester
                                                ?.schoolcourse?.school?.name
                                        }
                                    </td>
                                    <td>
                                        {
                                            apply?.schoolcoursesemester
                                                ?.schoolcourse?.coursebase?.name
                                        }
                                    </td>
                                    <td>{apply?.courseStartDate}</td>
                                    <td>
                                        <Link to={`/application/${apply.id}`}>
                                            <Button
                                                variant="outline-info"
                                                size="sm"
                                                className="mr-3"
                                            >
                                                {BUTTON_INFO}
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            )}
        </Container>
    ) : (
        <Loader />
    )
}

export default StudentInfo
