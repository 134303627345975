import { Button, Col } from 'react-bootstrap'
import history from '../history'

const BackButton = () => {
    return (
        <Col className="py-3">
            <Button
                style={{ width: '100px', borderRadius: '15px' }}
                variant="outline-info"
                onClick={() => history.goBack()}
            >
                Back
            </Button>
        </Col>
    )
}

export default BackButton
