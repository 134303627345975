export const INPUT_FIRSTNAME = 'First Name / Prénom'
export const INPUT_LASTNAME = 'Last Name / Nom de famille'
export const INPUT_EMAIL = 'Email / Courriel'
export const INPUT_BIRTHDATE = 'Birth Date / Date de naissance'
export const INPUT_CITYOFBIRTH = 'City Of Birth / Ville de naissance'
export const INPUT_COUNTRYOFBIRTH = 'Country Of Birth / Pays de naissance'
export const INPUT_GENTDER = 'Gender / Genre'
export const INPUT_ADDRESS = 'Address / Adresse'
export const INPUT_CITY = 'City / Ville'
export const INPUT_PROVINCE = 'Province / Province'
export const INPUT_COUNTRY = 'Country / Pays'
export const INPUT_POSTCODE = 'Post Code / Code postal'
export const INPUT_NATIONALITY = 'Nationality / Nationalité'
export const INPUT_PHONE = 'Phone / Numéro de téléphone'
export const INPUT_NATIVELANGUAGE = 'Native Language / Langue maternelle'
export const INPUT_LANGUAGEATHOME =
    'Language At Home / Langue parlée à la maison'
export const INPUT_FATHERFIRSTNAME = 'Father First Name / Prénom du père'
export const INPUT_FATHERLASTNAME = 'Father Last Name / Nom de famille du père'
export const INPUT_MOTHERFIRSTNAME = 'Mother First Name / Prénom de la mère'
export const INPUT_MOTHERLASTNAME =
    'Mother Last Name / Nom de famille de la mère'
export const INPUT_STARTCOURSEDATE = 'Course Start Date'
export const INPUT_CARD_NAME = 'Card Holder Name / Nom du détenteur de carte'
export const INPUT_CARD_NO = 'Card Number / Numéro de carte'
export const INPUT_CARD_EXPIRED = 'Card Expired Date / Date d’expiration MM/YY'
export const INPUT_CARD_SECURITY =
    'Card Security Number / Code de sécurité (CVV)'

export const genderList = ['Male', 'Female']

export const cardTypeList = ['Visa', 'MasterCard']

export const buttonSubmit = 'Submit'
export const buttonUpdate = 'Update'
export const buttonBack = 'Back'
export const DISPLAY_STUDENT = 'Student'
export const DISPLAY_ADD_NEW = 'Add New'
export const DISPLAY_EDIT = 'EDIT'
export const DISPLAY_UPLOAD = 'Upload'

// STUDENT
export const DISPLAY_ADD_NEW_STUDNET = 'Add New Student'
export const DISPLAY_STUDENT_ID = 'Student ID'
export const DISPLAY_FIRST_NAME = 'First Name'
export const DISPLAY_LAST_NAME = 'Last Name'
export const DISPLAY_EMAIL = 'Email'
export const DISPLAY_USER = 'Agent'
export const BUTTON_DELETE = 'Delete'
export const BUTTON_EDIT = 'Edit'
export const BUTTON_UPLOAD = 'Upload'
export const BUTTON_INFO = 'Info'
export const BUTTON_SELECT = 'Select'
export const BUTTON_ADD_STUDENT = 'Add Student'
export const BUTTON_EDIT_STUDENT = 'Edit Student'

// Upload
export const DISPLAY_PLACEHOLDER_TYPE = 'Type'
export const DISPLAY_PLACEHOLDER_URL = 'Url'

// Apply
export const DISPLAY_APPLY = 'Application'
export const DISPLAY_APPLY_ID = 'ID'
export const DISPLAY_APPLY_DONE = 'Done'
export const DISPLAY_APPLY_PROCESS = 'Processing'
export const DISPLAY_APPLY_YES = 'YES'
export const DISPLAY_APPLY_NO = 'NO'

export const DISPLAY_APPLY_STATUS = 'Status'
export const DISPLAY_APPLY_DATE = 'Date'
export const DISPLAY_APPLY_PAYEMNT_STATUS = 'Payment'
export const DISPLAY_APPLY_PAID = 'Paid'
export const DISPLAY_APPLY_UNPAID = 'Unpaid'
export const DISPLAY_APPLY_FINISHED = 'Finished'
export const DISPLAY_APPLY_UNFINISHED = 'Unfinished'

export const DISPLAY_APPLY_AMOUNT = 'Amount'
export const DISPLAY_APPLY_STUDENT_NAME = 'Student Name'
export const DISPLAY_APPLY_SCHOOL_NAME = 'School Name'
export const DISPLAY_APPLY_COURSE_NAME = 'Course Name'
export const DISPLAY_APPLY_COURSE_STARTDATE = 'Start Date'
export const DISPLAY_SEARCH_ORDERBY = '-Order By-'

export const DISPLAY_LEARN_MORE = 'LEARN MORE'
