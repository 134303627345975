import { useDispatch, useSelector } from 'react-redux'
import { Button, Row } from 'react-bootstrap'
import { SEARCH_STUDENT, SHOW_ADD_STUDENT } from '../../store/types'
import SelectStudent from './SelectStudent'
import DisplayStudent from '../student/DisplayStudent'
import {
    BUTTON_ADD_STUDENT,
    BUTTON_EDIT_STUDENT,
} from '../../constants/studentInput'
import DisplayUpload from '../student/DisplayUpload'

const EditStudent = () => {
    const dispatch = useDispatch()
    const applyState = useSelector((state) => state.apply)
    const { showAddStudent, selectedStudent } = applyState
    return (
        <div className="my-3">
            <div className="my-3">
                <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => {
                        dispatch({
                            type: SEARCH_STUDENT,
                            payload: {
                                searchStudent: '',
                                searchUser: '',
                            },
                        })
                        dispatch({
                            type: SHOW_ADD_STUDENT,
                            payload: true,
                        })
                    }}
                >
                    {selectedStudent ? BUTTON_EDIT_STUDENT : BUTTON_ADD_STUDENT}
                </Button>
            </div>
            <div>{showAddStudent && <SelectStudent />}</div>
            {selectedStudent && (
                <>
                    <div>
                        <DisplayStudent student={selectedStudent} />
                    </div>
                    <hr />
                    <Row>
                        {selectedStudent.studentuploads &&
                            selectedStudent.studentuploads.length > 0 &&
                            selectedStudent.studentuploads.map((upload) => (
                                <DisplayUpload
                                    upload={upload}
                                    dispatch={dispatch}
                                    key={upload.id}
                                />
                            ))}
                    </Row>
                </>
            )}
        </div>
    )
}

export default EditStudent
