import {
    Button,
    Card,
    Col,
    ListGroup,
    ListGroupItem,
    Row,
} from 'react-bootstrap'
import { EDIT_TYPE } from '../constants/editType'
import { XCircle } from 'react-bootstrap-icons'

const PictureAddBase = ({
    name,
    picLink,
    setName,
    setPicLink,
    handleAddPicture,
    pictures,
    editType,
    handlePictureDelete,
}) => {
    return (
        <>
            <Card>
                <Card.Header>Pictures</Card.Header>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col xs={12} lg={4} className="py-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Pic Name"
                                />
                            </Col>

                            <Col xs={12} lg={4} className="py-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={picLink}
                                    onChange={(e) => setPicLink(e.target.value)}
                                    placeholder="Pic Link"
                                />
                            </Col>
                            <Col xs={12} lg={4} className="py-1">
                                <Button
                                    variant="outline-primary"
                                    onClick={() => handleAddPicture()}
                                >
                                    Add Picture
                                </Button>
                            </Col>
                        </Row>
                    </Card.Title>
                    <ListGroup className="list-group-flush">
                        <Row>
                            {pictures &&
                                pictures.map((picture, index) => (
                                    <Card
                                        key={index}
                                        style={{ width: '10rem' }}
                                        className="m-2"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src={picture.picLink}
                                            width="150px"
                                            height="100px"
                                            style={{ objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            <Card.Title>
                                                {picture.name}
                                            </Card.Title>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem className="text-center">
                                                <XCircle
                                                    size={16}
                                                    color="red"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            window.confirm(
                                                                'Are you sure?'
                                                            )
                                                        ) {
                                                            handlePictureDelete(
                                                                editType ===
                                                                    EDIT_TYPE
                                                                    ? picture.id
                                                                    : picture.picLink
                                                            )
                                                        }
                                                    }}
                                                />
                                            </ListGroupItem>
                                        </ListGroup>
                                    </Card>
                                ))}
                        </Row>
                    </ListGroup>
                </Card.Body>
            </Card>
        </>
    )
}

export default PictureAddBase
