import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row, Form, Table } from 'react-bootstrap'
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import {
    deleteOneAssociate,
    getOneAssociate,
    resendAssociateEmail,
    updateOneAssociate,
} from '../store/actions/associateActions'
import { GET_ONE_ASSOCIATE } from '../store/types'

const AdminAssociate = ({ match }) => {
    const id = match.params.id
    const history = useHistory()
    const dispatch = useDispatch()
    const associateLevel = [1, 2, 3, 4, 5]

    const associateState = useSelector((state) => state.associate)
    const { associate } = associateState

    const [level, setLevel] = useState(1)
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        dispatch(getOneAssociate(id))
        return () => {
            dispatch({ type: GET_ONE_ASSOCIATE, payload: null })
        }
    }, [dispatch, id])

    useEffect(() => {
        if (associate) {
            setIsActive(associate.isActive)
            setLevel(associate.level)
        }
    }, [associate])

    const handleConfirm = (e) => {
        setIsActive(!isActive)
    }

    const handleSubmit = () => {
        const values = {
            ...associate,
            isActive: isActive,
            level: level,
        }
        dispatch(updateOneAssociate(id, values))
    }

    return (
        <Layout>
            {associate ? (
                <Container>
                    <Col className="my-3">
                        <Button variant="info" onClick={() => history.goBack()}>
                            Back
                        </Button>
                    </Col>
                    {associate.logo && (
                        <Row className="py-3">
                            <Col xs={4} className="text-right">
                                Logo:
                            </Col>
                            <Col xs={8}>
                                <img
                                    src={associate.logo}
                                    style={{
                                        width: '200px',
                                        height: '200px',
                                        objectFit: 'cover',
                                    }}
                                    alt="logo"
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Company Name:
                        </Col>
                        <Col xs={8}>{associate.name}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Email:
                        </Col>
                        <Col xs={8}>{associate.email}</Col>
                    </Row>
                    {associate.certificate && (
                        <Row className="py-3">
                            <Col xs={4} className="text-right">
                                Certificate:
                            </Col>
                            <Col xs={8}>
                                <a href={associate.certificate} target="_black">
                                    <img
                                        src={associate.certificate}
                                        style={{
                                            width: '200px',
                                            height: '200px',
                                            objectFit: 'cover',
                                        }}
                                        alt="certificate"
                                    />
                                </a>
                            </Col>
                        </Row>
                    )}
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Canadian company:
                        </Col>
                        <Col xs={8}>
                            {associate.inCanada === true ? (
                                <CheckCircleFill size={25} color="green" />
                            ) : (
                                <XCircleFill size={25} color="red" />
                            )}
                        </Col>
                    </Row>
                    {associate.inCanada === true && (
                        <>
                            <Row className="py-3">
                                <Col xs={4} className="text-right">
                                    GST number:
                                </Col>
                                <Col xs={8}>{associate.gst}</Col>
                            </Row>
                            <Row className="py-3">
                                <Col xs={4} className="text-right">
                                    QST number:
                                </Col>
                                <Col xs={8}>{associate.qst}</Col>
                            </Row>
                        </>
                    )}

                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Owner’s First Name:
                        </Col>
                        <Col xs={8}>{associate.firstName}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Owner’s Last Name:
                        </Col>
                        <Col xs={8}>{associate.lastName}</Col>
                    </Row>

                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Street Address:
                        </Col>
                        <Col xs={8}>{associate.address}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            City:
                        </Col>
                        <Col xs={8}>{associate.city}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Province:
                        </Col>
                        <Col xs={8}>{associate.province}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Country:
                        </Col>
                        <Col xs={8}>{associate.country}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Postal Code:
                        </Col>
                        <Col xs={8}>{associate.postCode}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Phone:
                        </Col>
                        <Col xs={8}>{associate.phone}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Cellphone:
                        </Col>
                        <Col xs={8}>{associate.cellphone}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Skype ID:
                        </Col>
                        <Col xs={8}>{associate.skype}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            WhatsApp ID:
                        </Col>
                        <Col xs={8}>{associate.whatsapp}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Referred:
                        </Col>
                        <Col xs={8}>{associate.referred}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            When begin recruiting students:
                        </Col>
                        <Col xs={8}>{associate.beginDate}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Where recruit from:
                        </Col>
                        <Col xs={8}>{associate.recruitFrom}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            How many students per year:
                        </Col>
                        <Col xs={8}>{associate.countStudent}</Col>
                    </Row>
                    <Row className="py-3">
                        <Col xs={4} className="text-right">
                            Email verify:
                        </Col>
                        <Col xs={8}>
                            {' '}
                            {associate.emailVerify === true ? (
                                <CheckCircleFill size={25} color="green" />
                            ) : (
                                <>
                                    <XCircleFill size={25} color="red" />{' '}
                                    <Button
                                        style={{ marginLeft: '20px' }}
                                        size="sm"
                                        variant="info"
                                        onClick={() =>
                                            dispatch(
                                                resendAssociateEmail(
                                                    associate.id
                                                )
                                            )
                                        }
                                    >
                                        Resend
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Form>
                        <Row className="py-3">
                            <Col xs={4} className="text-right">
                                Confirmed:
                            </Col>
                            <Col xs={8}>
                                <input
                                    type="checkbox"
                                    defaultChecked={associate.isActive}
                                    onChange={handleConfirm}
                                />
                            </Col>
                        </Row>

                        <Row className="py-3">
                            <Col xs={4} className="text-right">
                                Level:
                            </Col>
                            <Col xs={8}>
                                {associateLevel && (
                                    <select
                                        className="form-control"
                                        value={level}
                                        onChange={(e) =>
                                            setLevel(e.target.value)
                                        }
                                    >
                                        {associateLevel.map((aa) => {
                                            return (
                                                <option key={aa} value={aa}>
                                                    {aa}
                                                </option>
                                            )
                                        })}
                                    </select>
                                )}
                            </Col>
                        </Row>

                        <Row className="py-3">
                            <Col xs={4} className="text-right"></Col>
                            <Col xs={8}>
                                <Button
                                    variant="success"
                                    onClick={() => handleSubmit()}
                                >
                                    Update
                                </Button>

                                {!associate?.isActive &&
                                    associate?.users?.length === 0 && (
                                        <Button
                                            style={{ marginLeft: '20px' }}
                                            variant="danger"
                                            onClick={() => {
                                                if (
                                                    window.confirm(
                                                        'Are you sure?'
                                                    )
                                                ) {
                                                    dispatch(
                                                        deleteOneAssociate(
                                                            associate.id
                                                        )
                                                    )
                                                }
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                            </Col>
                        </Row>
                    </Form>

                    {associate.users && associate.users.length > 0 && (
                        <Table responsive striped hover>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Email Verify</th>
                                    <th>Active</th>
                                    <th>Students</th>
                                    <th>Applications</th>
                                </tr>
                            </thead>

                            <tbody>
                                {associate.users.map((user) => (
                                    <tr key={user.id}>
                                        <td>{user.role}</td>

                                        <td>
                                            {user.firstName} {user.lastName}
                                        </td>
                                        <td>{user.email}</td>
                                        <td>
                                            {user.verify ? (
                                                <CheckCircleFill
                                                    size={25}
                                                    color="green"
                                                />
                                            ) : (
                                                <XCircleFill
                                                    size={25}
                                                    color="red"
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {user.isActive ? (
                                                <CheckCircleFill
                                                    size={25}
                                                    color="green"
                                                />
                                            ) : (
                                                <XCircleFill
                                                    size={25}
                                                    color="red"
                                                />
                                            )}
                                        </td>
                                        <td>{user.students.length}</td>
                                        <td>{user.applies.length}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </Container>
            ) : (
                <div>Loading...</div>
            )}
        </Layout>
    )
}

export default AdminAssociate
