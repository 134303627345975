import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Pagination, Table } from 'react-bootstrap'
import history from '../../history'
import { DISPLAY_TOTAL } from '../../constants/schoolCourse'
import StudentSearch from '../student/StudentSearch'
import { getAllStudents } from '../../store/actions/studentActions'
import {
    DISPLAY_EMAIL,
    DISPLAY_FIRST_NAME,
    DISPLAY_LAST_NAME,
    DISPLAY_STUDENT_ID,
    DISPLAY_USER,
} from '../../constants/studentInput'
import { CHANGE_STUDENT_PAGE } from '../../store/types'
import ApplyStudentList from './ApplyStudentList'

const ApplyStudents = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const studentState = useSelector((state) => state.student)
    const {
        students,
        searchStudent,
        searchUser,
        users,
        pages,
        page,
        count,
    } = studentState

    const pageNumber = page || 1

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getAllStudents(searchStudent, searchUser, pageNumber))
        }
    }, [dispatch, userInfo, searchStudent, searchUser, pageNumber])

    return (
        <Container>
            <StudentSearch
                displaySearchStudent={searchStudent}
                displaySearchUser={searchUser}
                users={users}
                userInfo={userInfo}
            />
            <Col className="py-3">
                {DISPLAY_TOTAL}: {count}
            </Col>
            <Col>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>{DISPLAY_STUDENT_ID}</th>
                            <th>{DISPLAY_FIRST_NAME}</th>
                            <th>{DISPLAY_LAST_NAME}</th>
                            <th>{DISPLAY_EMAIL}</th>
                            <th>{DISPLAY_USER}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => (
                            <ApplyStudentList
                                student={student}
                                dispatch={dispatch}
                                key={student.id}
                            />
                        ))}
                    </tbody>
                </Table>
            </Col>
            {pages > 1 && (
                <Col className="d-flex justify-content-center my-3">
                    <Pagination>
                        {[...Array(pages).keys()].map((x) => (
                            <Pagination.Item
                                key={x + 1}
                                active={x + 1 === page}
                                onClick={() =>
                                    dispatch({
                                        type: CHANGE_STUDENT_PAGE,
                                        payload: x + 1,
                                    })
                                }
                            >
                                {x + 1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            )}
        </Container>
    )
}

export default ApplyStudents
