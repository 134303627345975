import {
    GET_ALL_COURSEBASE,
    GET_ALL_COURSELEVELS,
    GET_ONE_COURSEBASE,
    SHOW_LOADING,
} from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'
import history from '../../history'

export const getAllCourseBases = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`coursebase/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_COURSEBASE,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneCourseBase = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`coursebase/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })

        newToken(res)
        dispatch(renewUserToken(res))

        dispatch({
            type: GET_ONE_COURSEBASE,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createCourseBase = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`coursebase/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllCourseBases())
        history.push('/coursebase')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const updateCourseBase = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.put(`coursebase/update/${values.id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch({ type: GET_ONE_COURSEBASE, payload: null })
        dispatch(getAllCourseBases())
        history.push('/coursebase')
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const statusChangeCourseBase = (id, value) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        const values = {
            status: value,
        }
        let res = await request.put(`coursebase/statuschange/${id}`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllCourseBases())
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createCourseBasePic = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`coursebase/createpic`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneCourseBase(values.courseBaseId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteCourseBasePic = (id, courseBaseId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`coursebase/deletepic/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getOneCourseBase(courseBaseId))
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getAllCourseLevels = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`coursebase/listallcourselevels`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_COURSELEVELS,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const createCourseLevel = (values) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.post(`coursebase/createcourselevel`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllCourseLevels())
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const deleteCourseLevel = (id, courseBaseId) => async (
    dispatch,
    getState
) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.delete(`coursebase/deletecourselevel/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
        dispatch(getAllCourseLevels())
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
