import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { EDIT_TYPE } from '../../constants/editType'
import { STATUS_DELETE, STATUS_ONE } from '../../constants/status'
import { statusChangeSchool } from '../../store/actions/schoolActions'

const SchoolList = ({ school, dispatch }) => {
    return (
        <tr
            style={
                school.status === STATUS_DELETE
                    ? { color: 'red', textDecoration: 'line-through' }
                    : { textDecoration: 'none' }
            }
        >
            <td>
                {school.logo && (
                    <img
                        src={school.logo}
                        alt="logo"
                        width="100px"
                        height="50px"
                        style={{ objectFit: 'cover' }}
                    />
                )}
            </td>
            <td>{school.name}</td>
            <td>{school.info}</td>
            <td>
                {school.status > STATUS_DELETE ? (
                    <>
                        <div>
                            <Link to={`/addcourseforschool/${school.id}`}>
                                <Button
                                    size="sm"
                                    variant="outline-success"
                                    className="mr-3"
                                    style={{ width: '100px' }}
                                >
                                    Add Program
                                </Button>
                            </Link>
                        </div>
                        <div className="py-1">
                            <Link to={`/editschool/${EDIT_TYPE}/${school.id}`}>
                                <Button
                                    size="sm"
                                    variant="outline-info"
                                    className="mr-3"
                                    style={{ width: '100px' }}
                                >
                                    Edit
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button
                                variant="outline-danger"
                                size="sm"
                                style={{ width: '100px' }}
                                onClick={() => {
                                    if (window.confirm('Are you sure?')) {
                                        dispatch(
                                            statusChangeSchool(
                                                school.id,
                                                STATUS_DELETE
                                            )
                                        )
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    </>
                ) : (
                    <Button
                        variant="outline-warning"
                        size="sm"
                        onClick={() => {
                            if (window.confirm('Are you sure?')) {
                                dispatch(
                                    statusChangeSchool(school.id, STATUS_ONE)
                                )
                            }
                        }}
                    >
                        Restore
                    </Button>
                )}
            </td>
        </tr>
    )
}

export default SchoolList
