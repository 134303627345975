import { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { SEARCH_BOX } from '../../constants/schoolCourse'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import { getAllEtablissements } from '../../store/actions/etablissementActions'
import { SEARCH_ETABLISSEMENT } from '../../store/types'

const SearchBox = ({ displaySearchName }) => {
    const dispatch = useDispatch()
    const [searchName, setSearchName] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_ETABLISSEMENT,
            payload: { searchName },
        })
    }

    useEffect(() => {
        setSearchName(displaySearchName ?? '')
    }, [displaySearchName])
    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={6}>
                        <Form.Control
                            type="text"
                            name="searchName"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            placeholder={SEARCH_BOX}
                        ></Form.Control>
                    </Col>
                    <Col xs={6}>
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            {frenchSearchButtonSm}{' '}
                            <Button
                                size="sm"
                                variant="outline-warning"
                                className="p-2"
                                style={{
                                    borderRadius: '10px',
                                    width: '100px',
                                    marginLeft: '20px',
                                }}
                                onClick={() => {
                                    dispatch(getAllEtablissements())
                                }}
                            >
                                Reset
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    )
}

export default SearchBox
