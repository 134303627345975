import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ApplyButtons from './ApplyButtons'
import { Button, Modal } from 'react-bootstrap'
import { BUTTON_INFO, DISPLAY_APPLY_DONE } from '../../constants/studentInput'
import NewApplyNote from './NewApplyNote'
import { applyStatus } from './DisplayStatus'
import ApplyAdminNote from './ApplyAdminNote'
import { USER_ADMIN } from '../../constants/userRole'
import { confirmStudentUpdated } from '../../store/actions/applyActions'
import { useDispatch } from 'react-redux'

const NewList = ({ apply, index, userInfo }) => {
    const dispatch = useDispatch()
    const trBackgroundColor = index % 2 === 0 ? '#F8F9F9' : '#EAEDED'

    const [show, setShow] = useState(false)

    const handleClose = () => setShow(false)

    const handleConfirm = useCallback(() => {
        const values = { id: apply.id }
        dispatch(confirmStudentUpdated(values))
        setShow(false)
    }, [apply.id, dispatch])

    return (
        <>
            <tr style={{ backgroundColor: trBackgroundColor }}>
                <td>{apply.applyId}</td>
                <td>{moment(apply?.createdAt).format('ll')}</td>
                <td>{apply.student.studentId}</td>
                <td>{apply.student.firstName}</td>
                <td>{apply.student.lastName}</td>
                <td>{apply.student.email}</td>
                <td>{apply.student.birthDate}</td>
                <td>{apply.student?.applies?.length}</td>
                <td>
                    <Link
                        to={`/student/${apply.student.id}`}
                        style={
                            apply?.studentUpdated
                                ? { color: 'red' }
                                : { color: 'black' }
                        }
                    >
                        {moment(apply.student.updatedAt).format('ll')}
                    </Link>
                </td>
                <td>
                    {apply?.studentUpdated ? (
                        <Button
                            variant="outline-success"
                            size="sm"
                            onClick={() => setShow(true)}
                        >
                            Confirm
                        </Button>
                    ) : null}
                </td>
                <td>
                    {apply?.user?.firstName} {apply?.user?.lastName}
                </td>
                <td>{apply?.user?.email}</td>
                <td>{apply?.user?.associate?.name}</td>
                <td>
                    {
                        apply?.schoolcoursesemester?.schoolcourse?.school?.name?.split(
                            '-'
                        )[0]
                    }
                </td>
                <td>
                    {apply.schoolcoursesemester.schoolcourse.coursebase.name}
                </td>
                <td>{apply.schoolcoursesemester.language}</td>
                <td>
                    {apply.schoolcoursesemester.semester}{' '}
                    {apply.schoolcoursesemester?.waitingList &&
                        '(Waiting List)'}
                </td>
                <td>
                    <div
                        className={`d-flex align-items-center justify-content-center p-2
                    ${
                        apply.finished
                            ? 'bg-info'
                            : applyStatus[apply?.applyStatus]?.color
                    }
                `}
                    >
                        {apply.finished
                            ? DISPLAY_APPLY_DONE
                            : applyStatus[apply?.applyStatus]?.status}
                    </div>
                </td>
                <td>
                    {apply?.applynotes?.length > 0 && (
                        <NewApplyNote
                            applynotes={apply.applynotes}
                            id={apply.id}
                            short={true}
                        />
                    )}
                </td>
                <td>
                    <Link to={`/application/${apply.id}`}>
                        <Button
                            variant="outline-info"
                            size="sm"
                            className="mr-3"
                        >
                            {BUTTON_INFO}
                        </Button>
                    </Link>
                </td>
                <td style={{ width: 300 }}>
                    <ApplyButtons apply={apply} />
                </td>
                {userInfo?.role === USER_ADMIN && (
                    <td>
                        <ApplyAdminNote apply={apply} />
                    </td>
                )}
            </tr>
            {userInfo?.role === USER_ADMIN && apply?.adminNote ? (
                <tr style={{ backgroundColor: trBackgroundColor }}>
                    <td colSpan={22}>
                        <div
                            style={{
                                border: '1px dotted orange',
                                padding: '5px',
                            }}
                        >
                            {' '}
                            {apply?.adminNote
                                ?.split('\n')
                                .map((line, index) => (
                                    <div key={index}>{line}</div>
                                ))}
                        </div>
                    </td>
                </tr>
            ) : null}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Student Updated Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        After confirmed student status change back to editable
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                    <Button variant="primary" onClick={() => handleConfirm()}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default NewList
