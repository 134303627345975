import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Col, Button } from 'react-bootstrap'
import history from '../history'
import Description from '../components/base/Description'
import Header from '../components/base/Header'
import Pictures from '../components/base/Pictures'
import Loader from '../components/Loader'
import { getOneEtablissement } from '../store/actions/etablissementActions'
import EtablissementCourseList from '../components/etablissement/EtablissementCourseList'
import DisplayMap from '../components/school/DisplayMap'
import { GET_ONE_ETABLISSEMENT } from '../store/types'

const Etablissement = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const etablissementState = useSelector((state) => state.etablissement)
    const { etablissement } = etablissementState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneEtablissement(id))
        }
        return () => {
            dispatch({ type: GET_ONE_ETABLISSEMENT, payload: null })
        }
    }, [dispatch, userInfo, id])

    return etablissement ? (
        <Container>
            <Col className="py-3">
                <Button
                    style={{ width: '100px', borderRadius: '15px' }}
                    variant="outline-info"
                    onClick={() => history.goBack()}
                >
                    Back
                </Button>
            </Col>
            <Header baseInfo={etablissement} />
            {etablissement?.pictures && etablissement?.pictures?.length > 0 && (
                <Pictures baseInfo={etablissement} />
            )}

            <Col>
                <Description description={etablissement?.description} />
            </Col>
            <Col>
                <h4>
                    <strong>Programs</strong>
                </h4>

                {etablissement?.schoolcourses &&
                    etablissement?.schoolcourses?.map((schoolProgram) => (
                        <EtablissementCourseList
                            key={schoolProgram?.id}
                            schoolCourse={schoolProgram}
                        />
                    ))}
            </Col>
            <Col>
                <h4>
                    <strong>Location</strong>
                </h4>

                <strong>{etablissement?.address}</strong>
                <div className="py-3"></div>
                {etablissement?.latitude && etablissement?.longitude && (
                    <DisplayMap
                        latitude={etablissement?.latitude}
                        longitude={etablissement?.longitude}
                    />
                )}
            </Col>
        </Container>
    ) : (
        <Loader />
    )
}

export default Etablissement
