import {
    STATUS_OPEN,
    DISPLAY_OPEN,
    DISPLAY_CLOSED,
} from '../../constants/semester'

const DisplayStatus = ({ status }) => {
    return (
        <div
            className={`apply-status ${
                status === STATUS_OPEN ? 'bg-success' : 'bg-danger'
            }`}
        >
            {status === STATUS_OPEN ? DISPLAY_OPEN : DISPLAY_CLOSED}
        </div>
    )
}

export default DisplayStatus
