import { Container, Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import history from '../../history'
import { GET_ONE_STUDENT } from '../../store/types'
import moment from 'moment'

import {
    createStudent,
    updateStudent,
} from '../../store/actions/studentActions'
import {
    INPUT_BIRTHDATE,
    INPUT_CITYOFBIRTH,
    INPUT_COUNTRYOFBIRTH,
    INPUT_EMAIL,
    INPUT_FIRSTNAME,
    INPUT_LASTNAME,
    genderList,
    INPUT_GENTDER,
    INPUT_ADDRESS,
    INPUT_CITY,
    INPUT_PROVINCE,
    INPUT_COUNTRY,
    INPUT_POSTCODE,
    INPUT_NATIONALITY,
    INPUT_PHONE,
    INPUT_NATIVELANGUAGE,
    INPUT_LANGUAGEATHOME,
    INPUT_FATHERFIRSTNAME,
    INPUT_FATHERLASTNAME,
    INPUT_MOTHERLASTNAME,
    INPUT_MOTHERFIRSTNAME,
    buttonUpdate,
    buttonSubmit,
    buttonBack,
    DISPLAY_STUDENT,
    DISPLAY_EDIT,
    DISPLAY_ADD_NEW,
    INPUT_CARD_NAME,
    INPUT_CARD_NO,
    INPUT_CARD_EXPIRED,
    INPUT_CARD_SECURITY,
    cardTypeList,
} from '../../constants/studentInput'
import { renderInput } from '../inputForm'

const FormStudent = (props) => {
    const dispatch = useDispatch()

    const { handleSubmit, editType, applyId } = props
    const submitForm = (formValue) => {
        formValue.applyId = applyId
        if (editType === EDIT_TYPE) {
            dispatch(updateStudent(formValue))
        } else if (editType === ADD_NEW_TYPE) {
            dispatch(createStudent(formValue))
        }
    }

    return (
        <Container>
            <form className="form-signin" onSubmit={handleSubmit(submitForm)}>
                <Col>
                    <h3 className="text-center">
                        {DISPLAY_STUDENT}{' '}
                        {editType === EDIT_TYPE
                            ? DISPLAY_EDIT
                            : DISPLAY_ADD_NEW}{' '}
                        / Étudiant nouvel ajout
                    </h3>
                </Col>

                <Row>
                    <Col xs={6}>
                        <Field
                            name="firstName"
                            type="text"
                            label={INPUT_FIRSTNAME}
                            max="100"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="lastName"
                            type="text"
                            label={INPUT_LASTNAME}
                            max="100"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="email"
                            type="text"
                            label={INPUT_EMAIL}
                            max="100"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6} lg={3}>
                        <Field
                            name="birthDate"
                            type="date"
                            label={INPUT_BIRTHDATE}
                            max="20"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={12} lg={3} className="my-3">
                        <label htmlFor="category">{INPUT_GENTDER}</label>
                        <Field
                            name="gender"
                            className="form-control"
                            component="select"
                        >
                            {genderList.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Field>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Field
                            name="cityOfBirth"
                            type="text"
                            label={INPUT_CITYOFBIRTH}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="countryOfBirth"
                            type="text"
                            label={INPUT_COUNTRYOFBIRTH}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            name="address"
                            type="text"
                            label={INPUT_ADDRESS}
                            max="200"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="city"
                            type="text"
                            label={INPUT_CITY}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="province"
                            type="text"
                            label={INPUT_PROVINCE}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="country"
                            type="text"
                            label={INPUT_COUNTRY}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="postCode"
                            type="text"
                            label={INPUT_POSTCODE}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Field
                            name="nationality"
                            type="text"
                            label={INPUT_NATIONALITY}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="Phone"
                            type="text"
                            label={INPUT_PHONE}
                            max="20"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="nativeLanguage"
                            type="text"
                            label={INPUT_NATIVELANGUAGE}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="languageAtHome"
                            type="text"
                            label={INPUT_LANGUAGEATHOME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={6}>
                        <Field
                            name="fatherFirstName"
                            type="text"
                            label={INPUT_FATHERFIRSTNAME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="fatherLastName"
                            type="text"
                            label={INPUT_FATHERLASTNAME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="motherFirstName"
                            type="text"
                            label={INPUT_MOTHERFIRSTNAME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="motherLastName"
                            type="text"
                            label={INPUT_MOTHERLASTNAME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <div>
                            NOTE : I hereby authorize QUEBEC INTERNATIONAL
                            EDUCATION CENTER INC. to use my credit card for the
                            payment of my apply fee.
                        </div>
                        <div>
                            NOTE : Par la présente, j’autorise CENTRE
                            INTERNATIONAL D'ÉDUCATION DU QUÉBEC à utiliser ma
                            carte de crédit pour payer mes frais d'inscription
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <label htmlFor="cartType">
                            Card Type / Type de carte
                        </label>
                        <Field
                            name="cardType"
                            className="form-control"
                            component="select"
                        >
                            {cardTypeList.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="cardName"
                            type="text"
                            label={INPUT_CARD_NAME}
                            max="50"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="cardNo"
                            type="text"
                            label={INPUT_CARD_NO}
                            max="20"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <Field
                            name="cardExpired"
                            type="text"
                            label={INPUT_CARD_EXPIRED}
                            max="10"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="cardSecurity"
                            type="text"
                            label={INPUT_CARD_SECURITY}
                            max="5"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Field
                            name="billingAddress"
                            type="text"
                            label="Billing Address / Adresse de facturation"
                            max="200"
                            component={renderInput}
                        ></Field>
                    </Col>
                    <Col xs={6}>
                        <Field
                            name="billingPostCode"
                            type="text"
                            label="Billing Post Code / Code postal de la facturation"
                            max="10"
                            component={renderInput}
                        ></Field>
                    </Col>
                </Row>
                <Col className="py-3">
                    <Button type="submit" variant="info" className="mr-3">
                        {editType === EDIT_TYPE ? buttonUpdate : buttonSubmit}
                    </Button>
                    <Button
                        variant="warning"
                        onClick={() => {
                            dispatch({
                                type: GET_ONE_STUDENT,
                                payload: null,
                            })
                            history.goBack()
                        }}
                    >
                        {buttonBack}
                    </Button>
                </Col>
                <Col className="my-5"></Col>
            </form>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'birthDate',
        'address',
        'city',
        'province',
        'country',
        'postCode',
        'cityOfBirth',
        'countryOfBirth',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!moment(values['birthDate'], 'YYYY-MM-DD', true).isValid()) {
        errors['birthDate'] = 'Wrong date'
    }

    return errors
}

export default reduxForm({
    form: 'studentForm',
    validate,
    enableReinitialize: true,
})(FormStudent)
