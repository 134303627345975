import {
    EDIT_SCHOOL_COURSE_SEMESTER,
    SHOW_ADD_SCHOOL_COURSE_SEMESTER,
} from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch, useSelector } from 'react-redux'
import FormSchoolCourseSemester from './FormSchoolCourseSemester'
import { languageList } from '../../constants/language'

const EditSchoolCourseSemester = () => {
    const dispatch = useDispatch()

    const schoolState = useSelector((state) => state.school)
    const { schoolCourseSemester, editType, school, schoolCourseId } =
        schoolState

    const closeShowUp = () => {
        dispatch({
            type: SHOW_ADD_SCHOOL_COURSE_SEMESTER,
            payload: false,
        })
        dispatch({
            type: EDIT_SCHOOL_COURSE_SEMESTER,
            payload: {
                schoolCourseSemester: null,
                schoolCourseId: null,
                editType: null,
            },
        })
    }

    const stateWaitingList = schoolCourseSemester?.waitingList
        ? schoolCourseSemester?.waitingList
        : false

    const initSemester = schoolCourseSemester
        ? schoolCourseSemester
        : { language: languageList[0] }

    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <FormSchoolCourseSemester
                    initialValues={initSemester}
                    editType={editType}
                    schoolId={school.id}
                    schoolCourseId={schoolCourseId}
                    stateWaitingList={stateWaitingList}
                />
            </div>
        </div>
    )
}

export default EditSchoolCourseSemester
