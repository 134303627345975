import { useEffect } from 'react'
import { Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import DisplaySemester from '../components/apply/DisplaySemester'
import FormApply from '../components/apply/FormApply'
import Loader from '../components/Loader'
import ProgrammesList from '../components/programme/ProgrammesList'
import { EDIT_TYPE } from '../constants/editType'
import {
    DISPLAY_ADD_NEW,
    DISPLAY_APPLY,
    DISPLAY_EDIT,
} from '../constants/studentInput'
import { getOneApply, getOneSemester } from '../store/actions/applyActions'
import EditStudent from '../components/apply/EditApplyStudent'
import BackButton from '../components/BackButton'

const EditApply = ({ match, history }) => {
    const type = match.params.type
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const applyState = useSelector((state) => state.apply)
    const { semester, apply } = applyState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            if (type === EDIT_TYPE) {
                dispatch(getOneApply(id))
            } else {
                dispatch(getOneSemester(id))
            }
        }
    }, [dispatch, userInfo, id, history, type])
    return semester || apply ? (
        <Container>
            <BackButton />
            <Col className="text-center py-3">
                <h2>
                    <strong>
                        {DISPLAY_APPLY}{' '}
                        {type === EDIT_TYPE ? DISPLAY_EDIT : DISPLAY_ADD_NEW}
                    </strong>
                </h2>
            </Col>
            {type === EDIT_TYPE
                ? apply?.schoolcoursesemester?.schoolcourse && (
                      <ProgrammesList
                          schoolCourse={apply.schoolcoursesemester.schoolcourse}
                      />
                  )
                : semester.schoolcourse && (
                      <ProgrammesList schoolCourse={semester.schoolcourse} />
                  )}

            <DisplaySemester
                semester={
                    type === EDIT_TYPE ? apply.schoolcoursesemester : semester
                }
            />
            <EditStudent />
            <FormApply editType={type} initialValues={apply} />
        </Container>
    ) : (
        <Loader />
    )
}

export default EditApply
