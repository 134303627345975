import {
    CHANGE_STUDENT_PAGE,
    GET_ALL_STUDENTS,
    GET_ONE_STUDENT,
    SEARCH_STUDENT,
    SHOW_LARGER_UPLOAD,
} from '../types'

const initialState = {
    students: [],
    student: {},
    users: [],
    searchUser: '',
    searchStudent: '',
    pages: null,
    page: null,
    count: 0,
    showLargeUpload: false,
    showUploadId: null,
}

const studentReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_STUDENTS:
            return {
                ...state,
                students: payload.students,
                users: payload.users,
                searchUser: payload.searchUser,
                searchStudent: payload.searchStudent,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case GET_ONE_STUDENT:
            return {
                ...state,
                student: payload,
            }
        case SEARCH_STUDENT:
            return {
                ...state,
                searchUser: payload.searchUser,
                searchStudent: payload.searchStudent,
                page: 1,
            }
        case SHOW_LARGER_UPLOAD:
            return {
                ...state,
                showLargeUpload: payload.display,
                showUploadId: payload.url,
            }
        case CHANGE_STUDENT_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default studentReducer
