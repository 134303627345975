import React, { useState, useEffect } from 'react'
import { Col, Pagination } from 'react-bootstrap'

const NewReduxPaginate = ({ pages, page, pageFunc }) => {
    const [currentButton, setCurrentButton] = useState(() => page)
    const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        setCurrentButton(page)
    }, [page])

    useEffect(() => {
        const numberOfPages = []
        for (let i = 1; i <= pages; i++) {
            numberOfPages.push(i)
        }
        let tempNumberOfPages = [...arrOfCurrButtons]

        let dotsInitial = '...'
        let dotsLeft = '... '
        let dotsRight = ' ...'

        if (numberOfPages.length < 6) {
            tempNumberOfPages = numberOfPages
        } else if (currentButton >= 1 && currentButton <= 3) {
            tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length]
        } else if (currentButton === 4) {
            const sliced = numberOfPages.slice(0, 5)
            tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length]
        } else if (
            currentButton > 4 &&
            currentButton < numberOfPages.length - 2
        ) {
            // from 5 to 8 -> (10 - 2)
            const sliced1 = numberOfPages.slice(
                currentButton - 2,
                currentButton
            ) // sliced1 (5-2, 5) -> [4,5]
            const sliced2 = numberOfPages.slice(
                currentButton,
                currentButton + 1
            ) // sliced1 (5, 5+1) -> [6]
            tempNumberOfPages = [
                1,
                dotsLeft,
                ...sliced1,
                ...sliced2,
                dotsRight,
                numberOfPages.length,
            ] // [1, '...', 4, 5, 6, '...', 10]
        } else if (currentButton > numberOfPages.length - 3) {
            // > 7
            const sliced = numberOfPages.slice(numberOfPages.length - 4) // slice(10-4)
            tempNumberOfPages = [1, dotsLeft, ...sliced]
        } else if (currentButton === dotsInitial) {
            // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
            // arrOfCurrButtons[3] = 4 + 1 = 5
            // or
            // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
            // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
            setCurrentButton(arrOfCurrButtons[arrOfCurrButtons.length - 3] + 1)
        } else if (currentButton === dotsRight) {
            setCurrentButton(arrOfCurrButtons[3] + 2)
        } else if (currentButton === dotsLeft) {
            setCurrentButton(arrOfCurrButtons[3] - 2)
        }

        setArrOfCurrButtons(tempNumberOfPages)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentButton, pages])

    return (
        <div>
            {pages > 1 && (
                <Col className="d-flex justify-content-center my-3">
                    <Pagination>
                        {arrOfCurrButtons.map((item, index) => (
                            <Pagination.Item
                                key={index}
                                active={item === page}
                                onClick={() => pageFunc(parseInt(item))}
                            >
                                {item}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </Col>
            )}
        </div>
    )
}

export default NewReduxPaginate
