import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import { deleteSchoolCourseSemester } from '../../store/actions/schoolActions'
import {
    EDIT_SCHOOL_COURSE_SEMESTER,
    SHOW_ADD_SCHOOL_COURSE_SEMESTER,
} from '../../store/types'
import { useDispatch } from 'react-redux'
import { STATUS_OPEN } from '../../constants/semester'

const SchoolCourseSemesterList = ({
    schoolCourseSemester,
    schoolCourseId,
    schoolId,
}) => {
    const dispatch = useDispatch()
    return (
        <ListGroup>
            <ListGroupItem>
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                        dispatch({
                            type: SHOW_ADD_SCHOOL_COURSE_SEMESTER,
                            payload: true,
                        })
                        dispatch({
                            type: EDIT_SCHOOL_COURSE_SEMESTER,
                            payload: {
                                schoolCourseSemester: null,
                                schoolCourseId: schoolCourseId,
                                editType: ADD_NEW_TYPE,
                            },
                        })
                    }}
                >
                    Add Start Date
                </Button>
            </ListGroupItem>
            {schoolCourseSemester &&
                schoolCourseSemester
                    .filter((aa) => aa.status === STATUS_OPEN)
                    .map((semester) => (
                        <ListGroupItem key={semester.id}>
                            <Row>
                                <Col>
                                    {semester.semester}{' '}
                                    {semester.waitingList && '(Waiting List)'}
                                </Col>
                                <Col>{semester.language}</Col>
                                <Col>{semester.remark}</Col>
                                <Col>
                                    <Button
                                        variant="outline-warning"
                                        size="sm"
                                        className="mx-3"
                                        onClick={() => {
                                            dispatch({
                                                type: SHOW_ADD_SCHOOL_COURSE_SEMESTER,
                                                payload: true,
                                            })
                                            dispatch({
                                                type: EDIT_SCHOOL_COURSE_SEMESTER,
                                                payload: {
                                                    schoolCourseSemester:
                                                        semester,
                                                    editType: EDIT_TYPE,
                                                },
                                            })
                                        }}
                                    >
                                        Edit
                                    </Button>

                                    {semester.applies &&
                                        semester.applies.length === 0 && (
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            'Are you sure?'
                                                        )
                                                    ) {
                                                        dispatch(
                                                            deleteSchoolCourseSemester(
                                                                semester.id,
                                                                schoolId
                                                            )
                                                        )
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))}
        </ListGroup>
    )
}

export default SchoolCourseSemesterList
