import {
    GET_ALL_ETABLISSEMENTS,
    GET_ONE_ETABLISSEMENT,
    SHOW_LOADING,
} from '../types'
import request from '../../config/request'
import { newToken } from '../../admin/newtoken'
import { logout, renewUserToken } from './userActions'
import { errorsReturn } from './errors'
import { getErrors } from './errorActions'

export const getAllEtablissements = (
    searchName = '',
    pageNumber = ''
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(
            `etablissement/listall?searchName=${searchName}&pageNumber=${pageNumber}`,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )

        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ALL_ETABLISSEMENTS,
            payload: {
                etablissements: res.data.data,
                pages: res.data.pages,
                page: res.data.page,
                count: res.data.count,
                searchName,
            },
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}

export const getOneEtablissement = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SHOW_LOADING,
            payload: true,
        })
        const {
            userLogin: { userToken },
        } = getState()
        let res = await request.get(`etablissement/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        newToken(res)
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_ETABLISSEMENT,
            payload: res.data.data,
        })
        dispatch({
            type: SHOW_LOADING,
            payload: false,
        })
    } catch (e) {
        errorsReturn(e, dispatch, SHOW_LOADING, getErrors, logout)
    }
}
