import { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import FormContainer from '../components/FormContainer'
import request from '../config/request'
import { getErrors } from '../store/actions/errorActions'

const ForgetPassword = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState(false)

    const submitHandler = async (e) => {
        e.preventDefault()
        if (!email) {
            dispatch(getErrors('Please enter your email!'))
        }
        const values = { email }

        try {
            await request.post('/user/forgetpassword', values)
            setSuccess(true)
        } catch (e) {
            const errors = {
                msg: e?.response?.data?.msg,
            }
            dispatch(getErrors(errors))
        }
    }

    return (
        <div>
            <FormContainer>
                <h3>Forget Password</h3>
                {success ? (
                    <div>
                        Your temporary password already sent to {email}, please
                        check your email then <Link to="/login">Login</Link>
                    </div>
                ) : (
                    <>
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                ></Form.Control>
                            </Form.Group>

                            <Button
                                type="submit"
                                variant="primary"
                                className="btn-block py-2 mt-4"
                            >
                                Send
                            </Button>
                        </Form>
                        <Row className="py-3">
                            <Col>
                                <Link to="/login">Login</Link>
                            </Col>
                        </Row>
                    </>
                )}
            </FormContainer>
        </div>
    )
}

export default ForgetPassword
