import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Col, Button } from 'react-bootstrap'
import history from '../history'
import Loader from '../components/Loader'
import { getOneStudent } from '../store/actions/studentActions'
import DisplayStudent from '../components/student/DisplayStudent'
import EditUpload from '../components/student/EditUpload'
import { GET_ONE_STUDENT } from '../store/types'
import { useLocation } from 'react-router-dom'

const StudentUpload = ({ match }) => {
    const id = match.params.id
    const location = useLocation()
    const { applyId } = location.state || {}
    console.log('applyId ==>', applyId)
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const studentState = useSelector((state) => state.student)
    const { student } = studentState

    useEffect(() => {
        if (student?.applies?.length > 0 && !applyId) {
            alert('something went wrong')
            history.push('/students')
        }
    }, [student, applyId])

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            dispatch(getOneStudent(id))
        }
        return () => dispatch({ type: GET_ONE_STUDENT, payload: null })
    }, [dispatch, userInfo, id])

    return student ? (
        <Container>
            <Col className="py-3">
                <Button
                    style={{ width: '200px', borderRadius: '15px' }}
                    variant="outline-info"
                    onClick={() => history.push('/students')}
                >
                    Finish Upload
                </Button>
            </Col>
            <EditUpload
                student={student}
                dispatch={dispatch}
                applyId={applyId}
            />
            <DisplayStudent student={student} />

            <Col></Col>
        </Container>
    ) : (
        <Loader />
    )
}

export default StudentUpload
