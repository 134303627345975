import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import { EDIT_TYPE } from '../constants/editType'
import { getOneSchool } from '../store/actions/schoolActions'
import FormSchool from '../components/school/FormSchool'

const EditSchool = ({ match, history }) => {
    const type = match.params.type
    const id = match.params.id
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const schoolState = useSelector((state) => state.school)
    const { school } = schoolState

    useEffect(() => {
        if (!userInfo) {
            history.replace('/login')
        } else {
            if (type === EDIT_TYPE) {
                dispatch(getOneSchool(id))
            }
        }
    }, [dispatch, userInfo, id, history, type])

    const stateDescription = school ? school.description : ''
    const statePictures = school ? school.pictures : []
    const editType = type
    const schoolId = school ? school.id : ''
    return (
        <Container>
            <FormSchool
                initialValues={school}
                stateDescription={stateDescription}
                editType={editType}
                statePictures={statePictures}
                schoolId={schoolId}
            />
        </Container>
    )
}

export default EditSchool
