import { Button } from 'react-bootstrap'
import {
    DISPLAY_CLOSED,
    DISPLAY_OPEN,
    STATUS_CLOSED,
    STATUS_OPEN,
} from '../../constants/semester'
import { semesterChangeStatus } from '../../store/actions/semesterActions'
import DisplayStatus from './DisplayStatus'

const SemesterList = ({ semester, dispatch }) => {
    return (
        <tr>
            <td>
                <DisplayStatus status={semester?.status} />
            </td>
            <td>{semester?.schoolcourse?.school?.name}</td>
            <td>{semester?.schoolcourse?.coursebase?.name}</td>
            <td>${semester?.schoolcourse?.applyFee}</td>
            <td>{semester?.semester}</td>
            <td>{semester?.language}</td>
            <td>{semester?.applies?.length}</td>
            <td>
                {semester?.status === STATUS_OPEN ? (
                    <Button
                        size="sm"
                        variant="outline-info"
                        onClick={() =>
                            dispatch(
                                semesterChangeStatus(
                                    { status: STATUS_CLOSED },
                                    semester.id
                                )
                            )
                        }
                    >
                        {DISPLAY_CLOSED}
                    </Button>
                ) : (
                    <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() =>
                            dispatch(
                                semesterChangeStatus(
                                    { status: STATUS_OPEN },
                                    semester.id
                                )
                            )
                        }
                    >
                        {DISPLAY_OPEN}
                    </Button>
                )}
            </td>
        </tr>
    )
}

export default SemesterList
