import {
    CHANGE_ETABLISSEMENT_PAGE,
    GET_ALL_ETABLISSEMENTS,
    GET_ONE_ETABLISSEMENT,
    SEARCH_ETABLISSEMENT,
} from '../types'

const initialState = {
    etablissements: [],
    etablissement: {},
    searchName: '',
    pages: null,
    page: null,
    count: 0,
}

const etablissementReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ETABLISSEMENTS:
            return {
                ...state,
                etablissements: payload.etablissements,
                searchName: payload.searchName,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case GET_ONE_ETABLISSEMENT:
            return {
                ...state,
                etablissement: payload,
            }
        case SEARCH_ETABLISSEMENT:
            return {
                ...state,
                searchName: payload.searchName,
                page: 1,
            }
        case CHANGE_ETABLISSEMENT_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default etablissementReducer
