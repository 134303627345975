import { SHOW_ADD_COURSELEVEL } from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch } from 'react-redux'
import FormCourseLevel from './FormCourseLevel'

const EditCourseLevel = () => {
    const dispatch = useDispatch()
    const closeShowUp = () => {
        dispatch({
            type: SHOW_ADD_COURSELEVEL,
            payload: false,
        })
    }
    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <FormCourseLevel />
            </div>
        </div>
    )
}

export default EditCourseLevel
