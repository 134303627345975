import { useSelector } from 'react-redux'
import { Table } from 'react-bootstrap'
import { Clock, Reception4, Cash, Wallet, Award } from 'react-bootstrap-icons'
import {
    APPLY_FEE,
    COURSE_PRICE,
    COURSE_DURATION,
    COURSE_LEVEL,
    COMMISSION,
} from '../../constants/schoolCourse'

import { AGENT } from '../../constants/userRole'

export const ApplySchoolCourseInfo = ({ apply }) => {
    const userLogin = useSelector((state) => state.userLogin)
    const schoolProgram = apply.schoolcoursesemester.schoolcourse

    const { userInfo } = userLogin

    return (
        <Table>
            <tbody>
                <tr>
                    <td>
                        <Reception4 /> {COURSE_LEVEL}
                    </td>
                    <td>{schoolProgram.coursebase.courselevel.level}</td>
                </tr>
                <tr>
                    <td>
                        <Cash /> {COURSE_PRICE}
                    </td>
                    <td>{schoolProgram.coursePrice}</td>
                </tr>
                <tr>
                    <td>
                        <Wallet /> {APPLY_FEE}
                    </td>
                    <td>${schoolProgram.applyFee}</td>
                </tr>
                {schoolProgram?.securityDeposit ? (
                    <tr>
                        <td>
                            <Wallet /> Security Deposit
                        </td>
                        <td>${schoolProgram?.securityDeposit}</td>
                    </tr>
                ) : null}
                {userInfo &&
                    (userInfo.role !== AGENT || userInfo.commissionAccess) && (
                        <tr>
                            <td>
                                <Award /> {COMMISSION}
                            </td>
                            <td>
                                {apply.applyCommission
                                    ? `${
                                          apply.applyCommissionType === 2
                                              ? '$'
                                              : ''
                                      }${apply.applyCommission} ${
                                          apply.applyCommissionType === 1
                                              ? '%'
                                              : ''
                                      } [${
                                          apply.applyCommissionTax === 1
                                              ? 'Plus GST QST'
                                              : 'Tax included'
                                      }]`
                                    : ''}
                            </td>
                        </tr>
                    )}
                <tr>
                    <td>
                        <Clock /> {COURSE_DURATION}
                    </td>
                    <td>{schoolProgram.courseDuration}</td>
                </tr>
                <tr>
                    <td>
                        <Award /> Post-Graduation Work Permit
                    </td>
                    <td>{schoolProgram?.workPermit ? 'Yes' : 'No'}</td>
                </tr>
            </tbody>
        </Table>
    )
}
