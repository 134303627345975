import {
    CHANGE_SEMESTER_PAGE,
    GET_ALL_SEMESTERS,
    SEARCH_SEMESTER,
} from '../types'

const initialState = {
    semesters: [],
    searchSchool: '',
    searchCourse: '',
    searchLevel: '',
    searchLanguage: '',
    searchStatus: '',
    pages: null,
    page: null,
    count: 0,
}

const semesterReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SEMESTERS:
            return {
                ...state,
                semesters: payload.semesters,
                searchSchool: payload.searchSchool,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                searchStatus: payload.searchStatus,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case SEARCH_SEMESTER:
            return {
                ...state,
                searchSchool: payload.searchSchool,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                searchStatus: payload.searchStatus,
                page: 1,
            }
        case CHANGE_SEMESTER_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default semesterReducer
