import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { errorReducer } from './errorReducer'
import { messageReducer } from './messageReducer'
import { userLoginReducer, userUpdateProfileReducer } from './userReducers'
import loadingReducer from './loadingReducer'
import courseBaseReducer from './courseBaseReducer'
import schoolReducer from './schoolReducer'
import etablissementReducer from './etablissementReducer'
import programmeReducer from './programmeReducer'
import studentReducer from './studentReducer'
import applyReducer from './applyReducer'
import semesterReducer from './semesterReducer'
import associateReducer from './associateReducer'

export default combineReducers({
    form: formReducer,
    errors: errorReducer,
    messages: messageReducer,
    userLogin: userLoginReducer,
    userUpdateProfile: userUpdateProfileReducer,
    loading: loadingReducer,
    courseBase: courseBaseReducer,
    school: schoolReducer,
    etablissement: etablissementReducer,
    programme: programmeReducer,
    student: studentReducer,
    apply: applyReducer,
    semester: semesterReducer,
    associate: associateReducer,
})
