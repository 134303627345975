import {
    GET_ALL_COURSEBASE,
    GET_ALL_COURSELEVELS,
    GET_ONE_COURSEBASE,
    SHOW_ADD_COURSELEVEL,
} from '../types'

const initialState = {
    courseBases: [],
    courseBase: {},
    courseLevels: [],
    showAddCourseLevel: false,
}

const courseBaseReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_COURSEBASE:
            return {
                ...state,
                courseBases: payload,
            }
        case GET_ONE_COURSEBASE:
            return {
                ...state,
                courseBase: payload,
            }
        case GET_ALL_COURSELEVELS:
            return {
                ...state,
                courseLevels: payload,
            }
        case SHOW_ADD_COURSELEVEL:
            return {
                ...state,
                showAddCourseLevel: payload,
            }
        default:
            return state
    }
}

export default courseBaseReducer
