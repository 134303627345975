import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

import Form from 'react-bootstrap/Form'
import { Button, Col, Row } from 'react-bootstrap'
import {
    confirmApplyNote,
    createApplyNote,
    deleteApplyNote,
} from '../../store/actions/applyActions'
import moment from 'moment'
import { USER_ADMIN, USER_EMPLOYEE } from '../../constants/userRole'
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'
import uploadImg from '../../config/uploadImg'
import { controlLoading } from '../../store/actions/loadingActions'
import { getErrors } from '../../store/actions/errorActions'

function ApplyNote({ notes, applyId }) {
    const noteRef = useRef()
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const [upload, setUpload] = useState(null)
    const [preview, setPreview] = useState(null)

    const handleSubmit = async () => {
        if (!noteRef.current.value) {
            dispatch(getErrors({ msg: 'Please type some note' }))
            return
        }
        let uploadURL = ''
        if (upload) {
            const data = new FormData()
            data.append('file', upload)
            data.append('upload_preset', 'note_uploads')

            try {
                dispatch(controlLoading(true))
                let res = await uploadImg.post('', data)
                uploadURL = res.data.secure_url
                dispatch(controlLoading(false))
            } catch (e) {
                console.log(e)
                dispatch(controlLoading(false))
            }
        }

        const values = {
            applyId,
            note: noteRef.current.value,
            url: uploadURL,
        }
        dispatch(createApplyNote(values))
        noteRef.current.value = ''
        setUpload(null)
        URL.revokeObjectURL(preview)
        setPreview(null)
    }

    const handleUpload = async (e) => {
        const files = e.target.files

        if (!files || files.length === 0) {
            return false
        }

        const allowedTypes = [
            'image/jpeg',
            'image/png',
            'image/gif',
            'application/pdf',
        ]
        const fileType = files[0].type

        if (!allowedTypes.includes(fileType)) {
            dispatch(
                getErrors({
                    msg: 'Invalid file type. Please upload a PDF or an image.',
                })
            )
            return
        }
        setUpload(files[0])
        setPreview(URL.createObjectURL(files[0]))
    }

    useEffect(
        () => () => {
            if (preview) {
                URL.revokeObjectURL(preview)
            }
        },
        [preview]
    )

    const handleRemoveUpload = () => {
        setUpload(null)
        URL.revokeObjectURL(preview)
        setPreview(null)
    }

    const noteType = useMemo(() => {
        return userInfo?.role === USER_ADMIN || userInfo?.role === USER_EMPLOYEE
            ? 2
            : 1
    }, [userInfo])

    return (
        <Card style={{ width: '100%', marginTop: '20px' }}>
            <Card.Header>Note</Card.Header>

            {notes && notes.length > 0 && (
                <ListGroup className="list-group-flush">
                    {notes.map((note) => (
                        <ListGroup.Item key={note.id}>
                            {note?.url && (
                                <Row>
                                    <a href={`${note.url}`} target="_black">
                                        <Card
                                            style={{ width: '5rem' }}
                                            className="m-2"
                                        >
                                            {note.url
                                                .substr(
                                                    note.url.lastIndexOf('.') +
                                                        1
                                                )
                                                .toLowerCase() === 'pdf' ? (
                                                <Card.Img
                                                    variant="top"
                                                    src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                                                    width="150px"
                                                    style={{
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            ) : (
                                                <Card.Img
                                                    variant="top"
                                                    src={note.url}
                                                    width="150px"
                                                    style={{
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            )}
                                        </Card>
                                    </a>
                                </Row>
                            )}
                            <Row>
                                <Col style={{ whiteSpace: 'pre-line' }}>
                                    {note.note}
                                </Col>
                            </Row>

                            <Row
                                style={{ fontSize: '12px', color: '#999999' }}
                                className="mt-3"
                            >
                                <Col xs={4}>
                                    {note.user.firstName} {note.user.lastName}
                                </Col>
                                <Col xs={1}>
                                    {note.confirm ? (
                                        <CheckCircleFill
                                            size={18}
                                            color="green"
                                        />
                                    ) : (
                                        ''
                                    )}
                                </Col>
                                <Col xs={3}>
                                    {!note.confirm &&
                                        userInfo?.role === USER_ADMIN && (
                                            <Button
                                                size="sm"
                                                variant="danger"
                                                className="mr-3"
                                                onClick={() => {
                                                    const values = {
                                                        id: note.id,
                                                        applyId,
                                                    }
                                                    if (
                                                        window.confirm(
                                                            'Are you sure'
                                                        )
                                                    ) {
                                                        dispatch(
                                                            deleteApplyNote(
                                                                values
                                                            )
                                                        )
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    {note.noteType !== noteType &&
                                        !note.confirm && (
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={() => {
                                                    const values = {
                                                        id: note.id,
                                                        applyId,
                                                    }
                                                    dispatch(
                                                        confirmApplyNote(values)
                                                    )
                                                }}
                                            >
                                                Confirm
                                            </Button>
                                        )}
                                </Col>
                                <Col xs={4} style={{ textAlign: 'right' }}>
                                    {moment(note.createdAt).format('lll')}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            )}
            <Card.Body>
                {preview && upload ? (
                    <div
                        style={{
                            width: '200px',
                            padding: '20px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <div style={{ textAlign: 'right' }}>
                            <XCircleFill
                                size={20}
                                color="red"
                                onClick={handleRemoveUpload}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        {upload.type === 'application/pdf' ? (
                            <a href={`${preview}`} target="_black">
                                <Card.Img
                                    variant="top"
                                    src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                                    width="150px"
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            </a>
                        ) : (
                            <Card.Img
                                variant="top"
                                src={preview}
                                width="150px"
                                style={{
                                    objectFit: 'cover',
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div>
                        <Col xs={12} className="my-3">
                            <input
                                type="file"
                                id="files"
                                name="file"
                                placeholder="Upload an image"
                                className="custom-file-input"
                                onChange={handleUpload}
                            />
                            <label
                                htmlFor="files"
                                className="custom-file-label"
                            >
                                Choose file...
                            </label>
                        </Col>
                    </div>
                )}
                <Form.Control
                    as="textarea"
                    placeholder="Leave a note here"
                    style={{ height: '100px' }}
                    ref={noteRef}
                />

                <Button
                    size="sm"
                    className="mt-2"
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
            </Card.Body>
        </Card>
    )
}

export default ApplyNote
