import { Button, Col, Row } from 'react-bootstrap'
import { ArrowRight } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { DISPLAY_LEARN_MORE } from '../../constants/studentInput'
import {
    SchoolCourseInfo,
    SchoolCourseSemester,
} from '../base/SchoolCourseInfo'

const EtablissementCourseList = ({ schoolCourse }) => {
    return (
        <Row className="shadow my-5 rounded border">
            <Col xs={12} lg={4} className="header-picture">
                <Col>
                    <Col>
                        {schoolCourse.coursebase.logo && (
                            <img
                                src={schoolCourse.coursebase.logo}
                                alt="logo"
                                style={{
                                    objectFit: 'cover',
                                }}
                                className="img-fluid"
                            />
                        )}
                    </Col>
                    <Col className="text-center my-3">
                        <Link to={`/schoolprogram/${schoolCourse.id}`}>
                            {' '}
                            <Button
                                variant="primary"
                                style={{
                                    borderRadius: '10px',
                                    marginRight: 'auto',
                                }}
                            >
                                {DISPLAY_LEARN_MORE}{' '}
                                <ArrowRight size={18} className="mx-1" />
                            </Button>
                        </Link>
                    </Col>
                </Col>
            </Col>
            <Col xs={12} lg={8}>
                <Col className="py-3">
                    <h2>
                        <strong>{schoolCourse.coursebase.name}</strong>
                    </h2>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {schoolCourse.coursebase.info}
                    </p>
                    <SchoolCourseInfo schoolProgram={schoolCourse} />
                    <SchoolCourseSemester schoolProgram={schoolCourse} />
                </Col>
            </Col>
        </Row>
    )
}

export default EtablissementCourseList
