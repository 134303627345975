import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
    APPLY_STATUS_PAID,
    APPLY_STATUS_UNFINISHED,
    APPLY_STATUS_UNPAID,
} from '../../constants/apply'
import {
    DISPLAY_APPLY_FINISHED,
    DISPLAY_APPLY_PAID,
    DISPLAY_APPLY_UNFINISHED,
    DISPLAY_APPLY_UNPAID,
} from '../../constants/studentInput'
import { applyStatusUpdate } from '../../store/actions/applyActions'
import { FINISHED_POP_UP } from '../../store/types'

const buttonList = (status, dispatch, apply) => {
    switch (status) {
        case 1:
            return (
                <Button
                    variant="warning"
                    size="sm"
                    className="mr-3"
                    onClick={() => {
                        if (
                            window.confirm(
                                'Are you sure change status to REVIEWING?'
                            )
                        ) {
                            dispatch(
                                applyStatusUpdate({
                                    id: apply.id,
                                    applyCurrentStatus: 2,
                                })
                            )
                        }
                    }}
                >
                    Reviewing
                </Button>
            )
        case 2:
            return (
                <>
                    <Button
                        variant="warning"
                        size="sm"
                        className="mr-3"
                        onClick={() => {
                            if (
                                window.confirm(
                                    'Are you sure change status to NEW?'
                                )
                            ) {
                                dispatch(
                                    applyStatusUpdate({
                                        id: apply.id,
                                        applyCurrentStatus: 1,
                                    })
                                )
                            }
                        }}
                    >
                        New
                    </Button>
                    <Button
                        variant="danger"
                        size="sm"
                        className="mr-3"
                        onClick={() => {
                            if (
                                window.confirm(
                                    'Are you sure change status to MISSING?'
                                )
                            ) {
                                dispatch(
                                    applyStatusUpdate({
                                        id: apply.id,
                                        applyCurrentStatus: 3,
                                    })
                                )
                            }
                        }}
                    >
                        Missing
                    </Button>
                    {apply?.paid && (
                        <Button
                            variant="info"
                            size="sm"
                            className="mr-3"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        'Are you sure change status to PROCESSING?'
                                    )
                                ) {
                                    dispatch(
                                        applyStatusUpdate({
                                            id: apply.id,
                                            applyCurrentStatus: 4,
                                        })
                                    )
                                }
                            }}
                        >
                            Processing
                        </Button>
                    )}
                </>
            )
        case 3:
            return (
                <Button
                    variant="secondary"
                    size="sm"
                    className="mr-3"
                    onClick={() => {
                        if (
                            window.confirm(
                                'Are you sure change status to REVIEWING?'
                            )
                        ) {
                            dispatch(
                                applyStatusUpdate({
                                    id: apply.id,
                                    applyCurrentStatus: 2,
                                })
                            )
                        }
                    }}
                >
                    Reviewing
                </Button>
            )
        case 4:
            return (
                <>
                    <Button
                        variant="secondary"
                        size="sm"
                        className="mr-3"
                        onClick={() => {
                            if (
                                window.confirm(
                                    'Are you sure change status to REVIEWING?'
                                )
                            ) {
                                dispatch(
                                    applyStatusUpdate({
                                        id: apply.id,
                                        applyCurrentStatus: 2,
                                    })
                                )
                            }
                        }}
                    >
                        Reviewing
                    </Button>
                    <Button
                        variant="primary"
                        size="sm"
                        className="mr-3"
                        onClick={() => {
                            dispatch({
                                type: FINISHED_POP_UP,
                                payload: { show: true, id: apply.id },
                            })
                        }}
                    >
                        {DISPLAY_APPLY_FINISHED}
                    </Button>
                </>
            )
        case 5:
            return (
                <Button
                    variant="light"
                    size="sm"
                    className="mr-3"
                    onClick={() => {
                        if (
                            window.confirm(
                                'Are you sure change status to UNFINISHED?'
                            )
                        ) {
                            dispatch(
                                applyStatusUpdate({
                                    id: apply.id,
                                    finished: APPLY_STATUS_UNFINISHED,
                                })
                            )
                        }
                    }}
                >
                    {DISPLAY_APPLY_UNFINISHED}
                </Button>
            )
        default:
            return null
    }
}

const ApplyButtons = ({ apply }) => {
    const dispatch = useDispatch()

    return (
        <ButtonGroup aria-label="Basic">
            {apply?.paid ? (
                apply?.applyStatus < 4 ? (
                    <Button
                        variant="secondary"
                        size="sm"
                        className="mr-3"
                        onClick={() => {
                            if (
                                window.confirm(
                                    'Are you sure change status to UNPAID?'
                                )
                            ) {
                                dispatch(
                                    applyStatusUpdate({
                                        id: apply.id,
                                        paid: APPLY_STATUS_UNPAID,
                                    })
                                )
                            }
                        }}
                    >
                        {DISPLAY_APPLY_UNPAID}
                    </Button>
                ) : null
            ) : (
                <Button
                    variant="success"
                    size="sm"
                    className="mr-3"
                    onClick={() => {
                        if (
                            window.confirm(
                                'Are you sure change status to PAID?'
                            )
                        ) {
                            dispatch(
                                applyStatusUpdate({
                                    id: apply.id,
                                    paid: APPLY_STATUS_PAID,
                                })
                            )
                        }
                    }}
                >
                    {DISPLAY_APPLY_PAID}
                </Button>
            )}
            {buttonList(apply?.applyStatus, dispatch, apply)}
        </ButtonGroup>
    )
}

export default ApplyButtons
