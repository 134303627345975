import {
    EDIT_SCHOOL_COURSE,
    EDIT_SCHOOL_COURSE_SEMESTER,
    GET_ALL_SCHOOLS,
    GET_ONE_SCHOOL,
    GET_ONE_SCHOOL_PROGRAM,
    SHOW_ADD_SCHOOL_COURSE,
    SHOW_ADD_SCHOOL_COURSE_SEMESTER,
} from '../types'

const initialState = {
    schools: [],
    school: {},
    showAddCourse: false,
    schoolCourse: {},
    editType: '',
    showAddCourseSemester: false,
    schoolCourseSemester: {},
    schoolCourseId: '',
    schoolProgram: null,
}

const schoolReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SCHOOLS:
            return {
                ...state,
                schools: payload,
            }
        case GET_ONE_SCHOOL:
            return {
                ...state,
                school: payload,
            }
        case SHOW_ADD_SCHOOL_COURSE:
            return {
                ...state,
                showAddCourse: payload,
            }
        case EDIT_SCHOOL_COURSE:
            return {
                ...state,
                schoolCourse: payload.schoolCourse,
                editType: payload.editType,
            }
        case SHOW_ADD_SCHOOL_COURSE_SEMESTER:
            return {
                ...state,
                showAddCourseSemester: payload,
            }
        case EDIT_SCHOOL_COURSE_SEMESTER:
            return {
                ...state,
                schoolCourseSemester: payload.schoolCourseSemester,
                editType: payload.editType,
                schoolCourseId: payload.schoolCourseId,
            }
        case GET_ONE_SCHOOL_PROGRAM:
            return {
                ...state,
                schoolProgram: payload,
            }
        default:
            return state
    }
}

export default schoolReducer
