import { useState } from 'react'
import { Container, Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { ADD_NEW_TYPE, EDIT_TYPE } from '../../constants/editType'
import { renderInput } from '../inputForm'
import {
    createSchoolCourseSemester,
    updateSchoolCourseSemester,
} from '../../store/actions/schoolActions'
import { languageList } from '../../constants/language'
import moment from 'moment'

const FormSchoolCourseSemester = (props) => {
    const dispatch = useDispatch()

    const {
        handleSubmit,
        editType,
        schoolId,
        stateWaitingList,
        schoolCourseId,
    } = props

    const [waitingList, setWaitingList] = useState(() => stateWaitingList)

    const handleWaitingList = () => {
        setWaitingList(!waitingList)
    }

    const submitForm = (formValue) => {
        formValue['schoolCourseId'] = schoolCourseId
        formValue['waitingList'] = waitingList
        if (editType === EDIT_TYPE) {
            dispatch(updateSchoolCourseSemester(formValue, schoolId))
        } else if (editType === ADD_NEW_TYPE) {
            dispatch(createSchoolCourseSemester(formValue, schoolId))
        }
    }

    return (
        <Container>
            <form className="form-signin" onSubmit={handleSubmit(submitForm)}>
                <Col>
                    <h3 className="text-center">
                        School Program{' '}
                        {editType === EDIT_TYPE ? 'Edit' : 'Add New'}
                    </h3>
                </Col>

                <Col className="py-3">
                    <Row>
                        <Col>
                            <label htmlFor="coursebase">Language</label>
                            <Field
                                name="language"
                                className="form-control"
                                component="select"
                            >
                                {languageList.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Field
                        name="semester"
                        type="date"
                        label="Start Date"
                        max="20"
                        component={renderInput}
                    ></Field>
                </Col>
                <Col>
                    <label style={{ marginTop: '20px' }}>Waiting List</label>
                    <div>
                        <input
                            type="checkbox"
                            defaultChecked={waitingList}
                            onChange={handleWaitingList}
                        />
                    </div>
                </Col>
                <Col>
                    <Field
                        name="remark"
                        type="text"
                        label="Remark"
                        max="200"
                        component={renderInput}
                    ></Field>
                </Col>

                <Col className="py-2">
                    <Button
                        type="submit"
                        variant="info"
                        className="btn-block mr-3"
                    >
                        {editType === EDIT_TYPE ? 'Update' : 'Submit'}
                    </Button>
                </Col>
                <Col className="my-5"></Col>
            </form>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['semester', 'language']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (!moment(values['semester'], 'YYYY-MM-DD', true).isValid()) {
        errors['semester'] = 'Wrong date'
    }

    return errors
}

export default reduxForm({
    form: 'schoolCourseSemesterForm',
    validate,
    enableReinitialize: true,
})(FormSchoolCourseSemester)
