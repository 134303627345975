import { Card, Col } from 'react-bootstrap'
import { SHOW_LARGER_UPLOAD } from '../../store/types'
import { useDispatch, useSelector } from 'react-redux'
import DisplayLargeUpload from './DisplayLargeUpload'

const DisplayUpload = ({ upload }) => {
    const dispatch = useDispatch()
    const studentState = useSelector((state) => state.student)
    const { showLargeUpload, showUploadId } = studentState
    const pathIndex = upload.url.lastIndexOf('.')
    const fileType = upload.url.substr(pathIndex + 1).toLowerCase()
    return (
        <Card style={{ width: '10rem' }} className="m-2">
            {showLargeUpload && <DisplayLargeUpload img={showUploadId} />}
            {fileType === 'pdf' ? (
                <Card.Title>
                    <Col className="text-center">
                        <a href={`${upload.url}`} target="_black">
                            <img
                                src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                                alt="pdf"
                                width="100px"
                            />
                        </a>
                    </Col>
                </Card.Title>
            ) : (
                <Card.Img
                    variant="top"
                    src={upload.url}
                    width="150px"
                    style={{ objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() =>
                        dispatch({
                            type: SHOW_LARGER_UPLOAD,
                            payload: {
                                display: true,
                                url: upload.url,
                            },
                        })
                    }
                />
            )}
            <Card.Body>
                <Card.Title>{upload.type}</Card.Title>
            </Card.Body>
        </Card>
    )
}

export default DisplayUpload
