import { SHOW_LOADING } from '../types'

const initialState = {
    showLoading: false,
}

const loadingReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SHOW_LOADING:
            return {
                ...state,
                showLoading: payload,
            }
        default:
            return state
    }
}
export default loadingReducer
