import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Table } from 'react-bootstrap'
import history from '../history'
// import Paginate from '../components/Paginate'
// import BootstrapPagination from '../components/BootstrapPagination'
import NewReduxPaginate from '../components/NewReduxPaginate'
import Loader from '../components/Loader'
import { DISPLAY_TOTAL } from '../constants/schoolCourse'
import StudentSearch from '../components/student/StudentSearch'
import { getAllStudents } from '../store/actions/studentActions'
import StudentList from '../components/student/StudentList'
import { ADD_NEW_TYPE } from '../constants/editType'
import {
    DISPLAY_ADD_NEW_STUDNET,
    DISPLAY_EMAIL,
    DISPLAY_FIRST_NAME,
    DISPLAY_LAST_NAME,
    DISPLAY_STUDENT_ID,
    DISPLAY_USER,
} from '../constants/studentInput'
import { CHANGE_STUDENT_PAGE, GET_ONE_STUDENT } from '../store/types'

const Students = () => {
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const studentState = useSelector((state) => state.student)
    const { students, searchStudent, searchUser, users, pages, page, count } =
        studentState
    const pageNumber = page || 1
    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getAllStudents(searchStudent, searchUser, pageNumber))
        }
    }, [dispatch, userInfo, searchStudent, searchUser, pageNumber])

    const pageFunc = (value) => {
        dispatch({ type: CHANGE_STUDENT_PAGE, payload: value })
    }
    return students ? (
        <Container>
            <Col>
                <Button
                    variant="outline-info"
                    onClick={() => {
                        dispatch({ type: GET_ONE_STUDENT, payload: null })
                        history.push(`/editstudent/${ADD_NEW_TYPE}/0`)
                    }}
                >
                    {DISPLAY_ADD_NEW_STUDNET}
                </Button>
            </Col>
            <StudentSearch
                displaySearchStudent={searchStudent}
                displaySearchUser={searchUser}
                users={users}
                userInfo={userInfo}
            />
            <Col className="py-3">
                {DISPLAY_TOTAL}: {count}
            </Col>
            <Col>
                <Table responsive striped hover>
                    <thead>
                        <tr>
                            <th>{DISPLAY_STUDENT_ID}</th>
                            <th>{DISPLAY_FIRST_NAME}</th>
                            <th>{DISPLAY_LAST_NAME}</th>
                            <th>{DISPLAY_EMAIL}</th>
                            <th>{DISPLAY_USER}</th>
                            <th>Applications</th>
                            <th>Approved</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student) => (
                            <StudentList
                                student={student}
                                dispatch={dispatch}
                                key={student.id}
                                users={users}
                            />
                        ))}
                    </tbody>
                </Table>
            </Col>
            {/* <Paginate area="student" pages={pages} page={page} /> */}
            {/* <BootstrapPagination area="student" pages={pages} page={page} /> */}
            <NewReduxPaginate pages={pages} page={page} pageFunc={pageFunc} />
        </Container>
    ) : (
        <Loader />
    )
}

export default Students
