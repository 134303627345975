import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap'
import history from '../history'

const Layout = ({ children }) => {
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!userInfo) {
            history.replace('/login')
        }
    }, [userInfo])

    return userInfo ? (
        <Container fluid>
            <div>{children}</div>
        </Container>
    ) : (
        <div>Loading...</div>
    )
}

export default Layout
