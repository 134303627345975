import {
    DISPLAY_APPLY_DONE,
    DISPLAY_APPLY_PAID,
    DISPLAY_APPLY_PROCESS,
    DISPLAY_APPLY_UNPAID,
} from './studentInput'

export const APPLY_STATUS_PAID = 1
export const APPLY_STATUS_UNPAID = 2
export const APPLY_STATUS_FINISHED = 1
export const APPLY_STATUS_UNFINISHED = 2

export const searchOrderByList = [
    { key: 'Added Time', value: 'orderByTime' },
    { key: 'Start Date', value: 'orderByStartDate' },
    { key: 'School Name', value: 'orderBySchool' },
    { key: 'Course Name', value: 'orderByCourse' },
    { key: 'Student Name', value: 'orderByStudent' },
]

export const searchPaidList = [
    { key: DISPLAY_APPLY_PAID, value: 'Paid' },
    { key: DISPLAY_APPLY_UNPAID, value: 'Unpaid' },
]
export const searchFinishedList = [
    { key: DISPLAY_APPLY_DONE, value: 'Finished' },
    { key: DISPLAY_APPLY_PROCESS, value: 'Unfinished' },
]
