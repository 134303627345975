import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BUTTON_INFO, BUTTON_SELECT } from '../../constants/studentInput'
import { SELECTED_STUDENT, SHOW_ADD_STUDENT } from '../../store/types'

const ApplyStudentList = ({ student, dispatch }) => {
    return (
        <tr>
            <td>{student.studentId}</td>
            <td>{student.firstName}</td>
            <td>{student.lastName}</td>
            <td>{student.email}</td>
            <td>
                {student.user.firstName} {student.user.lastName}
            </td>
            <td>
                <Link to={`/student/${student.id}`}>
                    <Button variant="outline-info" size="sm" className="mr-3">
                        {BUTTON_INFO}
                    </Button>
                </Link>
                {student?.approved && (
                    <Button
                        size="sm"
                        variant="outline-primary"
                        className="mr-3"
                        onClick={() => {
                            dispatch({
                                type: SELECTED_STUDENT,
                                payload: student,
                            })
                            dispatch({
                                type: SHOW_ADD_STUDENT,
                                payload: false,
                            })
                        }}
                    >
                        {BUTTON_SELECT}
                    </Button>
                )}
            </td>
        </tr>
    )
}

export default ApplyStudentList
