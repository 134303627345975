import { Router, Route } from 'react-router-dom'
import './bootstrap.min.css'
import Footer from './components/Footer'
import Header from './components/Header'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import Alert from './components/Alert'
import LoadingSet from './components/LoadingSet'
import history from './history'
import Login from './pages/Login'
import Register from './pages/Register'
import AllUsers from './pages/AllUsers'
import CourseBase from './pages/CourseBase'
import EditCourseBase from './pages/EditCourseBase'
import CourseBaseInfo from './pages/CourseBaseInfo'
import School from './pages/School'
import EditSchool from './pages/EditSchool'
import AddCourseForSchool from './pages/AddCourseForSchool'
import SchoolProgram from './pages/SchoolProgram'
import Etablissements from './pages/Etablissements'
import Etablissement from './pages/Etablissement'
import Programmes from './pages/Programmes'
import Students from './pages/Students'
import EditStudent from './pages/EditStudent'
import StudentInfo from './pages/StudentInfo'
import StudentUpload from './pages/StudentUpload'
import EditApply from './pages/EditApply'
import Applications from './pages/Applications'
import Application from './pages/Application'
import UserEdit from './pages/UserEdit'
import AdminUserEdit from './pages/AdminUserEdit'
import Semesters from './pages/Semesters'
import ForgetPassword from './pages/ForgetPassword'
import ContactUs from './pages/ContactUs'
import VerifyEmail from './pages/VerifyEmail'
import AssociateRegister from './pages/AssociateRegister'
import Associates from './pages/Associates'
import VerifyAssociateEmail from './pages/VerifyAssociateEmail'
import AdminAssociate from './pages/AdminAssociate'
import AssociateControl from './pages/AssociateControl'
import StudentRegistrationList from './pages/StudentRegistrationList'

const options = {
    position: positions.MIDDLE,
    timeout: 5000,
    offset: '0px',
    transition: transitions.FADE,
}

function App() {
    return (
        <Router history={history}>
            <div className="main__page">
                <AlertProvider template={AlertTemplate} {...options}>
                    <Header />
                    <Alert />
                    <LoadingSet />
                    <main className="main__body">
                        <Route path="/" exact component={Etablissements} />
                        <Route path="/login" component={Login} />
                        <Route
                            path="/confirmemail/:token"
                            component={VerifyEmail}
                        />
                        <Route
                            path="/confirmassociateemail/:token"
                            component={VerifyAssociateEmail}
                        />
                        <Route
                            path="/forgetpassword"
                            component={ForgetPassword}
                        />
                        <Route path="/register" component={Register} />
                        <Route
                            path="/associateregister"
                            component={AssociateRegister}
                        />
                        <Route path="/users" exact component={AllUsers} />
                        <Route
                            path="/associates"
                            exact
                            component={Associates}
                        />
                        <Route
                            path="/associatecontrol"
                            exact
                            component={AssociateControl}
                        />
                        <Route
                            path="/associates/page/:pageNumber"
                            exact
                            component={Associates}
                        />
                        <Route
                            path="/adminassociate/:id"
                            exact
                            component={AdminAssociate}
                        />
                        <Route path="/profile" component={UserEdit} />
                        <Route
                            path="/adminuseredit/:id"
                            component={AdminUserEdit}
                        />
                        <Route
                            path="/users/page/:pageNumber"
                            exact
                            component={AllUsers}
                        />
                        <Route
                            path="/users/search/:keyword"
                            exact
                            component={AllUsers}
                        />
                        <Route
                            path="/users/page/:pageNumber/search/:keyword"
                            exact
                            component={AllUsers}
                        />
                        <Route path="/coursebase" component={CourseBase} />
                        <Route
                            path="/coursebaseinfo/:id"
                            component={CourseBaseInfo}
                        />
                        <Route
                            path="/editcoursebase/:type/:id"
                            component={EditCourseBase}
                        />
                        <Route path="/school" component={School} />
                        <Route
                            path="/editschool/:type/:id"
                            component={EditSchool}
                        />
                        <Route
                            path="/addcourseforschool/:id"
                            component={AddCourseForSchool}
                        />
                        <Route
                            path="/schoolprogram/:id"
                            component={SchoolProgram}
                        />
                        <Route
                            path="/etablissements"
                            exact
                            component={Etablissements}
                        />
                        <Route
                            path="/etablissements/page/:pageNumber"
                            exact
                            component={Etablissements}
                        />
                        <Route
                            path="/etablissement/:id"
                            component={Etablissement}
                        />
                        <Route
                            path="/programmes"
                            exact
                            component={Programmes}
                        />
                        <Route
                            path="/programmes/page/:pageNumber"
                            exact
                            component={Programmes}
                        />
                        <Route path="/students" exact component={Students} />
                        <Route
                            path="/students/page/:pageNumber"
                            exact
                            component={Students}
                        />
                        <Route
                            path="/editstudent/:type/:id"
                            component={EditStudent}
                        />
                        <Route path="/student/:id" component={StudentInfo} />
                        <Route
                            path="/studentupload/:id"
                            component={StudentUpload}
                        />
                        <Route
                            path="/editapply/:type/:id"
                            component={EditApply}
                        />
                        <Route path="/applications" component={Applications} />
                        <Route
                            path="/srl"
                            component={StudentRegistrationList}
                        />
                        <Route
                            path="/application/:id"
                            component={Application}
                        />
                        <Route path="/semesters" component={Semesters} />
                        <Route path="/contactus" component={ContactUs} />
                    </main>
                    <Footer />
                </AlertProvider>
            </div>
        </Router>
    )
}

export default App
