import {
    GET_ALL_APPLIES,
    GET_ONE_APPLY,
    GET_ONE_SEMESTER,
    SEARCH_APPLY,
    SHOW_ADD_STUDENT,
    SELECTED_STUDENT,
    CHANGE_APPLICATION_PAGE,
    SEARCH_ORDER_BY,
    FINISHED_POP_UP,
} from '../types'

const initialState = {
    applies: [],
    apply: {},
    semester: {},
    users: [],
    searchSchool: '',
    searchCourse: '',
    searchLevel: '',
    searchLanguage: '',
    searchUser: '',
    searchStudent: '',
    searchPaid: '',
    searchFinished: '',
    searchOrderBy: '',
    pages: null,
    page: null,
    count: 0,
    showAddStudent: false,
    selectedStudent: null,
    finishedPopUp: false,
    finishedId: null,
}

const applyReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_APPLIES:
            return {
                ...state,
                applies: payload.applies,
                users: payload.users,
                searchSchool: payload.searchSchool,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                searchUser: payload.searchUser,
                searchStudent: payload.searchStudent,
                searchPaid: payload.searchPaid,
                searchFinished: payload.searchFinished,
                searchOrderBy: payload.searchOrderBy,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case GET_ONE_APPLY:
            return {
                ...state,
                apply: payload,
            }
        case GET_ONE_SEMESTER:
            return {
                ...state,
                semester: payload,
            }
        case SEARCH_APPLY:
            return {
                ...state,
                searchSchool: payload.searchSchool,
                searchCourse: payload.searchCourse,
                searchLevel: payload.searchLevel,
                searchLanguage: payload.searchLanguage,
                searchUser: payload.searchUser,
                searchStudent: payload.searchStudent,
                searchPaid: payload.searchPaid,
                searchFinished: payload.searchFinished,
                page: 1,
            }
        case SHOW_ADD_STUDENT:
            return {
                ...state,
                showAddStudent: payload,
            }
        case SELECTED_STUDENT:
            return {
                ...state,
                selectedStudent: payload,
            }
        case CHANGE_APPLICATION_PAGE:
            return {
                ...state,
                page: payload,
            }
        case SEARCH_ORDER_BY:
            return {
                ...state,
                searchOrderBy: payload,
            }
        case FINISHED_POP_UP:
            return {
                ...state,
                finishedPopUp: payload.show,
                finishedId: payload.id,
            }
        default:
            return state
    }
}

export default applyReducer
