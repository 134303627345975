import { useState } from 'react'
import { Button, Col, Container, Form, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
    createCourseLevel,
    deleteCourseLevel,
} from '../../store/actions/courseBaseActions'

const FormCourseLevel = () => {
    const dispatch = useDispatch()
    const courseBaseState = useSelector((state) => state.courseBase)
    const { courseLevels } = courseBaseState
    const [level, setLevel] = useState('')
    const handleSubmite = (e) => {
        e.preventDefault()
        if (level) {
            const values = {
                level,
            }
            dispatch(createCourseLevel(values))
            setLevel('')
        }
    }
    return (
        <Container>
            <Col className="text-center">
                <h3>Program Levels</h3>
            </Col>
            <Col>
                <Form onSubmit={handleSubmite}>
                    <Form.Group controlId="formLevel">
                        <Form.Label>Program Level</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter level"
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Add
                    </Button>
                </Form>
            </Col>
            <Col className="py-3">
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th>Level</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {courseLevels &&
                            courseLevels.map((level) => (
                                <tr key={level.id}>
                                    <td>{level.level}</td>
                                    <td>
                                        {level?.coursebases?.length === 0 && (
                                            <Button
                                                size="sm"
                                                variant="outline-danger"
                                                onClick={() => {
                                                    if (
                                                        window.confirm(
                                                            'Are you sure?'
                                                        )
                                                    ) {
                                                        dispatch(
                                                            deleteCourseLevel(
                                                                level.id
                                                            )
                                                        )
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Col>
        </Container>
    )
}

export default FormCourseLevel
