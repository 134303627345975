import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Table, Col } from 'react-bootstrap'
import history from '../history'
// import Paginate from '../components/Paginate'
import BootstrapPagination from '../components/BootstrapPagination'
import UserSearch from '../components/users/UserSearch'
import UserList from '../components/users/UserList'
import { getAllUsers } from '../store/actions/userActions'

const AllUsers = ({ match }) => {
    const dispatch = useDispatch()
    const keyword = match.params.keyword || ''

    const pageNumber = match.params.pageNumber || 1

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo, users, pages, page, count } = userLogin

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            dispatch(getAllUsers(keyword, pageNumber))
        }
    }, [dispatch, userInfo, keyword, pageNumber])

    return (
        <Container fluid>
            <UserSearch displayKeyword={keyword} />

            <Col>
                <Col className="py-2">Total: {count}</Col>
                <Table responsive striped hover>
                    <thead>
                        <tr className="table-primary">
                            <th>First Name</th>
                            <th>Last Name</th>

                            <th>Email</th>
                            <th>Email Verify</th>
                            <th>Confirmed</th>
                            <th>Rule</th>
                            <th>Company</th>
                            <th>Last Login Time</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {users &&
                            users.map((user) => (
                                <UserList key={user.id} user={user} />
                            ))}
                    </tbody>
                </Table>
            </Col>
            <Col>
                {/* <Paginate
                    area="users"
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ''}
                /> */}
                <BootstrapPagination
                    area="users"
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ''}
                />
            </Col>
        </Container>
    )
}

export default AllUsers
