import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { EDIT_TYPE } from '../../constants/editType'
import { deleteSchoolCourse } from '../../store/actions/schoolActions'
import { EDIT_SCHOOL_COURSE, SHOW_ADD_SCHOOL_COURSE } from '../../store/types'
import Description from '../base/Description'
import SchoolCourseSemesterList from './SchoolCourseSemesterList'

const SchoolCourseList = ({ schoolCourse, schoolId }) => {
    const dispatch = useDispatch()
    return (
        <ListGroup.Item variant="light">
            <Row className="my-3">
                <Col>
                    <Col>Program Name: {schoolCourse?.coursebase?.name}</Col>
                    <Col> Program Price: {schoolCourse?.coursePrice}</Col>
                    <Col>Apply Fee: ${schoolCourse?.applyFee}</Col>
                    <Col>
                        Security Deposit: ${schoolCourse?.securityDeposit}
                    </Col>
                    <Col>
                        Commission Type:{' '}
                        {schoolCourse?.commissionType === 1
                            ? 'Percentage'
                            : 'Exact amount'}
                    </Col>
                    <Col>
                        Commission Tax:{' '}
                        {schoolCourse?.commissionTax === 1
                            ? 'Plus GST QST'
                            : 'Included Tax'}
                    </Col>
                    <Col>
                        Commission Level 1:{' '}
                        {schoolCourse?.commissionType === 2 ? '$' : ''}
                        {schoolCourse?.commission}
                        {schoolCourse?.commissionType === 1 ? '%' : ''}
                    </Col>
                    <Col>
                        Commission Level 2:{' '}
                        {schoolCourse?.commissionType === 2 ? '$' : ''}
                        {schoolCourse?.commission2}
                        {schoolCourse?.commissionType === 1 ? '%' : ''}
                    </Col>
                    <Col>
                        Commission Level 3:{' '}
                        {schoolCourse?.commissionType === 2 ? '$' : ''}
                        {schoolCourse?.commission3}
                        {schoolCourse?.commissionType === 1 ? '%' : ''}
                    </Col>
                    <Col>
                        Commission Level 4:{' '}
                        {schoolCourse?.commissionType === 2 ? '$' : ''}
                        {schoolCourse?.commission4}
                        {schoolCourse?.commissionType === 1 ? '%' : ''}
                    </Col>
                    <Col>
                        Commission Level 5:{' '}
                        {schoolCourse?.commissionType === 2 ? '$' : ''}
                        {schoolCourse?.commission5}
                        {schoolCourse?.commissionType === 1 ? '%' : ''}
                    </Col>
                    <Col>
                        Post-Graduation Work Permit:{' '}
                        {schoolCourse?.workPermit ? 'Yes' : 'No'}
                    </Col>
                </Col>
                <Col>
                    <Col>
                        <Link to={`/schoolprogram/${schoolCourse.id}`}>
                            <Button variant="outline-info" size="sm">
                                Info
                            </Button>
                        </Link>
                        <Button
                            variant="outline-warning"
                            size="sm"
                            className="mx-3"
                            onClick={() => {
                                dispatch({
                                    type: SHOW_ADD_SCHOOL_COURSE,
                                    payload: true,
                                })
                                dispatch({
                                    type: EDIT_SCHOOL_COURSE,
                                    payload: {
                                        schoolCourse: schoolCourse,
                                        editType: EDIT_TYPE,
                                    },
                                })
                            }}
                        >
                            Edit
                        </Button>

                        {schoolCourse.schoolcoursesemesters &&
                            schoolCourse.schoolcoursesemesters.length === 0 && (
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => {
                                        if (window.confirm('Are you sure?')) {
                                            dispatch(
                                                deleteSchoolCourse(
                                                    schoolCourse.id,
                                                    schoolId
                                                )
                                            )
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            )}
                    </Col>
                </Col>
            </Row>
            <Row className="p-3">
                <Card style={{ width: '100%' }}>
                    <Card.Header>
                        <Col>Apply Condition</Col>
                    </Card.Header>
                    <Card.Body>
                        <Col>
                            <Description
                                description={schoolCourse?.applyCondition}
                            />
                        </Col>
                    </Card.Body>
                </Card>
            </Row>
            <SchoolCourseSemesterList
                schoolCourseSemester={schoolCourse?.schoolcoursesemesters}
                schoolCourseId={schoolCourse.id}
                schoolId={schoolId}
            />
        </ListGroup.Item>
    )
}

export default SchoolCourseList
