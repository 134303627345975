import { Col, Row } from 'react-bootstrap'

const Header = ({ baseInfo }) => {
    return (
        <Col>
            <Col>
                <h1 className="mb-3">
                    <strong>{baseInfo.name}</strong>
                </h1>
            </Col>
            <Row>
                <Col xs={12} lg={6}>
                    <p style={{ whiteSpace: 'pre-line' }}>{baseInfo.info}</p>
                </Col>
                <Col xs={12} lg={6} className="header-picture">
                    {baseInfo.logo && (
                        <img
                            src={baseInfo.logo}
                            alt="logo"
                            style={{
                                objectFit: 'cover',
                            }}
                            className="img-fluid "
                        />
                    )}
                </Col>
            </Row>
        </Col>
    )
}

export default Header
