import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'react-bootstrap'
import {
    // englishSearchButtonSm,
    frenchSearchButtonSm,
} from '../../constants/searchButton'
import history from '../../history'

const UserSearch = ({ displayKeyword }) => {
    const [keyword, setKeyword] = useState('')

    const submitHandler = (e) => {
        e.preventDefault()

        if (keyword.trim()) {
            history.push(`/users/search/${keyword}`)
        } else {
            history.push('/users')
        }
    }

    useEffect(() => {
        setKeyword(displayKeyword)
    }, [displayKeyword])

    return (
        <Col className="d-flex justify-content-center my-3">
            <Form onSubmit={submitHandler} inline>
                <Row>
                    <Col xs={8}>
                        <Form.Control
                            type="text"
                            name="keyword"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Search Users..."
                        ></Form.Control>
                    </Col>
                    <Col xs={4}>{frenchSearchButtonSm}</Col>
                </Row>
            </Form>
        </Col>
    )
}

export default UserSearch
