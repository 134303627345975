import { GET_ONE_STUDENT, SHOW_ADD_STUDENT } from '../../store/types'
import CloseIcon from '../CloseIcon'
import { useDispatch } from 'react-redux'
import ApplyStudents from './ApplyStudents'
import { ADD_NEW_TYPE } from '../../constants/editType'
import history from '../../history'
import { DISPLAY_ADD_NEW_STUDNET } from '../../constants/studentInput'
import { Button, Col } from 'react-bootstrap'

const SelectStudent = () => {
    const dispatch = useDispatch()
    const closeShowUp = () => {
        dispatch({
            type: SHOW_ADD_STUDENT,
            payload: false,
        })
    }
    return (
        <div className="popup__main">
            <div className="popup__show">
                <CloseIcon closeFn={closeShowUp} />
                <Col>
                    <Button
                        variant="outline-info"
                        onClick={() => {
                            dispatch({ type: GET_ONE_STUDENT, payload: null })
                            history.push(`/editstudent/${ADD_NEW_TYPE}/0`)
                        }}
                    >
                        {DISPLAY_ADD_NEW_STUDNET}
                    </Button>
                </Col>
                <ApplyStudents />
            </div>
        </div>
    )
}

export default SelectStudent
