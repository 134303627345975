import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    Button,
    ListGroup,
    Row,
    Table,
    Col,
    ListGroupItem,
} from 'react-bootstrap'
import {
    Clock,
    Reception4,
    Cash,
    Wallet,
    Calendar3,
    FileWord,
    JournalBookmark,
    Award,
} from 'react-bootstrap-icons'
import {
    APPLY_FEE,
    COURSE_PRICE,
    COURSE_DURATION,
    BUTTON_APPLY,
    COURSE_LEVEL,
    COMMISSION,
} from '../../constants/schoolCourse'
import { GET_ONE_APPLY, SELECTED_STUDENT } from '../../store/types'
import history from '../../history'
import { useDispatch } from 'react-redux'
import { ADD_NEW_TYPE } from '../../constants/editType'
import { STATUS_OPEN } from '../../constants/semester'
import { AGENT } from '../../constants/userRole'

export const SchoolCourseInfo = ({ schoolProgram }) => {
    const userLogin = useSelector((state) => state.userLogin)

    const { userInfo } = userLogin
    const [comm, setComm] = useState('')

    useEffect(() => {
        if (userInfo) {
            switch (userInfo?.associate?.level) {
                case 1:
                    setComm(
                        schoolProgram.commission
                            ? `${
                                  schoolProgram.commissionType === 2 ? '$' : ''
                              }${schoolProgram.commission} ${
                                  schoolProgram.commissionType === 1 ? '%' : ''
                              } [${
                                  schoolProgram.commissionTax === 1
                                      ? 'Plus GST QST'
                                      : 'Tax included'
                              }]`
                            : ''
                    )

                    break
                case 2:
                    // setComm(schoolProgram.commission2)
                    setComm(
                        schoolProgram.commission2
                            ? `${
                                  schoolProgram.commissionType === 2 ? '$' : ''
                              }${schoolProgram.commission2} ${
                                  schoolProgram.commissionType === 1 ? '%' : ''
                              } [${
                                  schoolProgram.commissionTax === 1
                                      ? 'Plus GST QST'
                                      : 'Tax included'
                              }]`
                            : ''
                    )
                    break
                case 3:
                    // setComm(schoolProgram.commission3)
                    setComm(
                        schoolProgram.commission3
                            ? `${
                                  schoolProgram.commissionType === 2 ? '$' : ''
                              }${schoolProgram.commission3} ${
                                  schoolProgram.commissionType === 1 ? '%' : ''
                              } [${
                                  schoolProgram.commissionTax === 1
                                      ? 'Plus GST QST'
                                      : 'Tax included'
                              }]`
                            : ''
                    )
                    break
                case 4:
                    // setComm(schoolProgram.commission4)
                    setComm(
                        schoolProgram.commission4
                            ? `${
                                  schoolProgram.commissionType === 2 ? '$' : ''
                              }${schoolProgram.commission4} ${
                                  schoolProgram.commissionType === 1 ? '%' : ''
                              } [${
                                  schoolProgram.commissionTax === 1
                                      ? 'Plus GST QST'
                                      : 'Tax included'
                              }]`
                            : ''
                    )
                    break
                case 5:
                    // setComm(schoolProgram.commission5)
                    setComm(
                        schoolProgram.commission5
                            ? `${
                                  schoolProgram.commissionType === 2 ? '$' : ''
                              }${schoolProgram.commission5} ${
                                  schoolProgram.commissionType === 1 ? '%' : ''
                              } [${
                                  schoolProgram.commissionTax === 1
                                      ? 'Plus GST QST'
                                      : 'Tax included'
                              }]`
                            : ''
                    )
                    break
                default:
                    setComm('')
            }
        }
    }, [userInfo, schoolProgram])

    return (
        <Table>
            <tbody>
                <tr>
                    <td>
                        <Reception4 /> {COURSE_LEVEL}
                    </td>
                    <td>{schoolProgram.coursebase.courselevel.level}</td>
                </tr>
                <tr>
                    <td>
                        <Cash /> {COURSE_PRICE}
                    </td>
                    <td>{schoolProgram.coursePrice}</td>
                </tr>
                <tr>
                    <td>
                        <Wallet /> {APPLY_FEE}
                    </td>
                    <td>${schoolProgram.applyFee}</td>
                </tr>
                {schoolProgram?.securityDeposit ? (
                    <tr>
                        <td>
                            <Cash /> Security Deposit
                        </td>
                        <td>${schoolProgram?.securityDeposit}</td>
                    </tr>
                ) : null}
                {userInfo &&
                    (userInfo.role !== AGENT || userInfo.commissionAccess) && (
                        <tr>
                            <td>
                                <Award /> {COMMISSION}
                            </td>
                            <td>{comm}</td>
                        </tr>
                    )}
                <tr>
                    <td>
                        <Clock /> {COURSE_DURATION}
                    </td>
                    <td>{schoolProgram.courseDuration}</td>
                </tr>
                <tr>
                    <td>
                        <Award /> Post-Graduation Work Permit
                    </td>
                    <td>{schoolProgram?.workPermit ? 'Yes' : 'No'}</td>
                </tr>
            </tbody>
        </Table>
    )
}

export const SchoolCourseSemester = ({ schoolProgram }) => {
    const dispatch = useDispatch()
    return (
        <ListGroup className="list-group-flush">
            {schoolProgram.schoolcoursesemesters &&
                schoolProgram.schoolcoursesemesters
                    .filter((aa) => aa.status === STATUS_OPEN)
                    .map((semester) => (
                        <ListGroupItem key={semester.id}>
                            <Row>
                                <Col>
                                    <div>
                                        <Calendar3 /> {semester.semester}{' '}
                                        {semester?.waitingList &&
                                            '(Waiting List)'}
                                    </div>
                                    <div>
                                        <FileWord /> {semester.language}
                                    </div>
                                    <div>
                                        <JournalBookmark /> {semester.remark}
                                    </div>
                                </Col>
                                <Col>
                                    <Button
                                        variant="success"
                                        className="float-right"
                                        size="sm"
                                        style={{
                                            borderRadius: '15px',
                                            width: '100px',
                                        }}
                                        onClick={() => {
                                            dispatch({
                                                type: GET_ONE_APPLY,
                                                payload: null,
                                            })
                                            dispatch({
                                                type: SELECTED_STUDENT,
                                                payload: null,
                                            })
                                            history.push(
                                                `/editapply/${ADD_NEW_TYPE}/${semester.id}`
                                            )
                                        }}
                                    >
                                        {BUTTON_APPLY}
                                    </Button>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    ))}
        </ListGroup>
    )
}
